import React, { Component, Fragment } from "react";
import Loader from "../../components/loader";
import Header from "../../components/header";
import "./loading.css";

class Loading extends Component {
  render() {
    return (
      <Fragment>
        <Header
          whiteVersion={true}
          arrow_left={true}
          logo={true}
          close={true}
        />
        <div className="home topproposition loadingComponent">
          <div className="dark-section confidentialite-page text-center">
            <Loader />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Loading;
