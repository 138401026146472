import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./header.scss";
import AddPropositionModal from "../addpropositionmodal";
import LogoNM from "../../assets/images/NM_BLANC.png";
import LogoVM from "../../assets/images/VM_BLANC.png";
import LogoMMWhiteNM from "../../assets/images/logo.svg";
import Home from "../../assets/images/light_home.png";
import Search from "../../assets/images/search.png";
import { connect } from "react-redux";
import Proposal from "../../assets/images/light_new_proposal.svg";
import GeoLoc from "../../assets/images/geoloc.png";
import User from "../../assets/images/light_user.png";
import LogoMM_N from "../../assets/images/moi_maire_logo_white.png";
import LogoMM_V from "../../assets/images/moi_maire_logo_white_var.png";

class Header extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      user: null
    };
  }
  componentDidUpdate(newProps) {
    if (this.props.user !== newProps.user) {
      this.setState({ user: this.props.user });
    }
  }
  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    return !this.props.location.pathname.startsWith("/auth/") ? (
      <div>
        <AddPropositionModal
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
        />
        <div className="header">
          <Link to={{ pathname: "/" }} className="header-link-a">
            <img
              className="hide-on-desktop header-logo"
              src={LogoMMWhiteNM}
              alt=""
            />
            <img
              className="hide-on-mobile header-logo"
              src={
                this.props.user
                  ? this.props.user.town
                    ? this.props.user.town.code &&
                      this.props.user.town.code.startsWith("83")
                      ? LogoMM_V
                      : LogoMM_N
                    : LogoMM_N
                  : LogoMM_N
              }
              alt=""
            />
          </Link>
          <div className="hide-on-desktop avec-header-logo">
            <span>avec </span>
            <img
              src={
                this.props.user &&
                this.props.user.town &&
                this.props.user.town.code &&
                this.props.user.town.code.startsWith("83")
                  ? LogoVM
                  : LogoNM
              }
              alt=""
            />
          </div>
          <div className="hide-on-mobile">
            <div className="mainnavdesktop">
              <Link to={{ pathname: "/" }}>
                <div>
                  <img src={Home} alt="" />
                  <span>Aujourd’hui</span>
                </div>
              </Link>
              <Link to={{ pathname: "/propositions" }}>
                <div>
                  <img src={Search} alt="" />
                  <span>Parcourir</span>
                </div>
              </Link>
              <Link to={{}} onClick={this.openModal}>
                <div>
                  <img src={Proposal} alt="" />
                  <span className="green">Proposer</span>
                </div>
              </Link>
              <Link to={{ pathname: "/ville" }}>
                <div>
                  <img src={GeoLoc} alt="" />
                  <span>Ma ville</span>
                </div>
              </Link>
              <Link to={{ pathname: "/profile" }}>
                <div>
                  <img src={User} alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div />
    );
  }
}
const mapStateToProps = ({ topic, news, users }) => {
  const { user } = users;
  return { topic, news, user };
};

export default withRouter(connect(mapStateToProps, {})(Header));
