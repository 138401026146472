import {
  CANDIDATS_LIST,
  CANDIDATS_LIST_SUCCESS,
  CANDIDATS_LIST_ERROR
} from "../../constants/actionTypes";

export const listCandidats = comp => ({
  type: CANDIDATS_LIST,
  payload: comp
});
export const listCandidatsSuccess = user => ({
  type: CANDIDATS_LIST_SUCCESS,
  payload: user
});
export const listCandidatsError = user => ({
  type: CANDIDATS_LIST_ERROR,
  payload: user
});
