//API
import {
  loginUserSuccess,
  loginUserError,
  loginUserSuccessFB,
  loginUserErrorFB,
  registerUserSuccess
} from "./actions";
import { apiUrl } from "../../constants/defaultValues";
import axios from "axios";

export const loginUserBasic = payload => async dispatch => {
  const { username, password } = payload;
  await axios
    .post(`${apiUrl}/access/basic/login`, {
      username: username,
      password: password
    })
    .then(function(response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      dispatch(loginUserSuccess({ ...response.data }));
      return response;
    })
    .catch(function(error) {
      dispatch(loginUserSuccess({ ...error.response.data }));
    });
};

export const forgetPassUserBasic = payload => async dispatch => {
  await axios
    .post(`${apiUrl}/access/basic/forgot`, {
      email: payload
    })
    .then(function(response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      dispatch(loginUserSuccess({ ...response }));
      return response;
    })
    .catch(function(error) {
      dispatch(loginUserSuccess({ ...error.response }));
    });
};

export const resetPassUserBasic = payload => async dispatch => {
  await axios
    .post(`${apiUrl}/access/basic/reset`, {
      token: payload.token,
      password: payload.password
    })
    .then(function(response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      dispatch(loginUserSuccess({ ...response }));
      return response;
    })
    .catch(function(error) {
      dispatch(loginUserSuccess({ ...error.response }));
    });
};

export const loginUserFromFacebook = payload => async dispatch => {
  axios
    .get(`${apiUrl}/access/facebook/registered`, {
      headers: { "Facebook-User-Token": payload }
    })
    .then(function(response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if (response.data.registered) {
        axios
          .post(
            `${apiUrl}/access/facebook/login`,
            {},
            { headers: { "Facebook-User-Token": payload } }
          )
          .then(function(response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            dispatch(loginUserSuccess({ ...response.data }));
            return response;
          })
          .catch(function(error) {
            dispatch(loginUserErrorFB({ ...error.response.data }));
          });
      } else {
        dispatch(loginUserErrorFB({ ...response.data }));
      }
    })
    .catch(function(error) {
      dispatch(loginUserErrorFB({ ...error.response.data }));
    });
};

export const registerUserFromFacebook = payload => async dispatch => {
  let tokenFB = payload.token;
  delete payload.token;
  await axios
    .post(`${apiUrl}/access/facebook/register`, payload, {
      headers: { "Facebook-User-Token": tokenFB }
    })
    .then(function(response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      dispatch(loginUserSuccessFB({ ...response.data }));
      localStorage.setItem("currentUser", JSON.stringify(response.data));
      return response;
    })
    .catch(function(error) {
      dispatch(loginUserErrorFB({ ...error.response.data }));
    });
};

export const registerUserBasic = payload => async dispatch => {
  await axios
    .post(`${apiUrl}/access/basic/register`, payload, {})
    .then(function(response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      dispatch(registerUserSuccess({ ...response.data }));
      localStorage.setItem("currentUser", JSON.stringify(response));
      return response;
    })
    .catch(function(error) {
      dispatch(loginUserError({ ...error.response.data }));
    });
};
