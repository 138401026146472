import {
  CANDIDATS_LIST,
  CANDIDATS_LIST_SUCCESS,
  CANDIDATS_LIST_ERROR
} from "../../constants/actionTypes";

const INIT_STATE = {
  candidats: null,
  loading: false,
  reset: false,
  comp: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CANDIDATS_LIST:
      return { ...state, loading: true, reset: false, comp: action.payload };
    case CANDIDATS_LIST_SUCCESS:
      //notify.success('Login Success');
      return {
        ...state,
        loading: false,
        candidats: action.payload,
        reset: false
      };
    case CANDIDATS_LIST_ERROR:
      //notify.success('Login Success');
      return {
        ...state,
        loading: false,
        candidats: action.payload,
        reset: false
      };
    default:
      return { ...state };
  }
};
