import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import React, { Component, Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Error from "../../features/error";
import { Provider } from "react-redux";
import { configureStore } from "../../redux/store";
import { PrivateRoute } from "../../routing/PrivateRoute";
import { AuthenticatedRoute } from "../../routing/AuthenticatedRoute";
import { routes } from "../../routing/routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { codeGa } from "../../constants/defaultValues";
import ReactGA from "react-ga";
import "./app.scss";
import ReactPixel from "react-facebook-pixel";
import * as Sentry from "@sentry/browser";

class App extends Component {
  render() {
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false // enable logs
    };
    if (process.env.REACT_APP_FACEBOOK_PIXEL) {
      ReactPixel.init(
        process.env.REACT_APP_FACEBOOK_PIXEL,
        advancedMatching,
        options
      );
      ReactPixel.pageView();
    }
    if (process.env.REACT_APP_SENTRY_URL !== "sentry_app_url") {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL
      });
    }
    ReactGA.initialize(codeGa);
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
      <div className="app">
        <Fragment>
          <ToastContainer
            position="top-center"
            autoClose={2500}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <Provider store={configureStore()}>
            <BrowserRouter>
              <Switch>
                {// Les routes sont à définir dans le fichier src/routing/route.jsx
                routes.map(route => {
                  return route.private ? (
                    <PrivateRoute
                      key={route.path}
                      component={route.component}
                      path={route.path}
                      exact
                      user={"props.user"}
                      {...route}
                    />
                  ) : route.auth ? (
                    <AuthenticatedRoute
                      key={route.path}
                      component={route.component}
                      path={route.path}
                      user={"props.user"}
                      {...route}
                    />
                  ) : (
                    <Route
                      key={route.path}
                      render={props => (
                        <route.component {...props} {...route} />
                      )}
                      path={route.path}
                      exact={route.exact}
                    />
                  );
                })}
                {/* Si aucune route trouvé on affiche le composant NotFound */}
                <Route component={Error} />
              </Switch>
            </BrowserRouter>
          </Provider>
        </Fragment>
      </div>
    );
  }
}

export default App;
