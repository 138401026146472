import {
	NEWS_ONE,
	NEWS_LIST,
	NEWS_LIST_SUCCESS,
	NEWS_LIST_ERROR
} from "../../constants/actionTypes";

export const getNews = (news, history) => ({
	type: NEWS_ONE,
	payload: { news, history }
});

export const listNews = (news, history) => ({
	type: NEWS_LIST,
	payload: { news, history }
});

export const listNewsSuccess = news => ({
	type: NEWS_LIST_SUCCESS,
	payload: news
});

export const listNewsError = news => ({
	type: NEWS_LIST_ERROR,
	payload: news
});
