import {
  PROPOSAL_ONE,
  PROPOSAL_ONE_SUCCESS,
  PROPOSAL_ONE_ERROR,
  PROPOSAL_LIST,
  PROPOSAL_LIST_SUCCESS,
  PROPOSAL_LIST_ERROR,
  PROPOSAL_LIST_RESET,
  VOTE_PROPOSAL_SUCCESS
} from "../../constants/actionTypes";

const INIT_STATE = {
  proposal: null,
  proposals: null,
  reset: false,
  vote: null,
  comp: null,
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROPOSAL_ONE:
      return { ...state, loading: true, proposal: null, vote: null };
    case PROPOSAL_ONE_SUCCESS:
      //notify.success('Login Success');
      return {
        ...state,
        loading: false,
        proposal: action.payload,
        reset: false,
        vote: null
      };
    case VOTE_PROPOSAL_SUCCESS:
      //notify.success('Login Success');
      return { ...state, loading: false, vote: action.payload };
    case PROPOSAL_ONE_ERROR:
      //notify.success('Login Success');
      return { ...state, loading: false, proposal: action.payload, vote: null };
    case PROPOSAL_LIST:
      return { ...state, loading: true, comp: action.payload, vote: null };
    case PROPOSAL_LIST_SUCCESS:
      //notify.success('Login Success');
      return {
        ...state,
        loading: false,
        proposals: action.payload,
        reset: false,
        vote: null
      };
    case PROPOSAL_LIST_ERROR:
      //notify.success('Login Success');
      return {
        ...state,
        loading: false,
        proposals: action.payload,
        vote: null
      };
    case PROPOSAL_LIST_RESET:
      return {
        ...state,
        proposals: null,
        reset: true,
        comp: action.payload,
        vote: null
      };
    default:
      return { ...state };
  }
};
