import React from "react";
import "./textInput.scss";

const TextInput = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <div className="text-input form-group">
      <label>{props.label}</label>
      <span className="imagePlaceholder">{props.imagePlaceholder}</span>
      <input
        type={props.type}
        {...props}
        name={props.name}
        placeholder={[props.placeholder]}
        className={props.errorClass ? props.errorClass + " round" : "round"}
        onChange={props.handleChange}
        value={props.value}
        {...field}
      />
      <p className="hint">{props.hint}</p>
    </div>
  );
};
export default TextInput;
