import {
	TOWN_ONE,
	TOWN_ONE_SUCCESS,
	TOWN_ONE_ERROR,
	TOWN_LIST,
	TOWN_LIST_SUCCESS,
	TOWN_LIST_ERROR
} from "../../constants/actionTypes";

export const getTown = (town, history) => ({
	type: TOWN_ONE,
	payload: { town, history }
});
export const getTownSuccess = town => ({
	type: TOWN_ONE_SUCCESS,
	payload: town
});
export const getTownError = town => ({
	type: TOWN_ONE_ERROR,
	payload: town
});

export const listTown = (town, history) => ({
	type: TOWN_LIST,
	payload: { town, history }
});
export const listTownSuccess = town => ({
	type: TOWN_LIST_SUCCESS,
	payload: town
});
export const listTownError = town => ({
	type: TOWN_LIST_ERROR,
	payload: town
});
