import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';
import MySlider from "../../components/slider";
import ClickScroll from "../../components/clickscroll";
import Loader from "../../components/loader";
import TextInput from "../../components/form/textarea";
import AddPropositionModal from "../../components/addpropositionmodal";
import SubjectsPropositions from "../../components/subjectspropositions";
import Add from "../../assets/images/light_new_proposal.svg";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from "../../components/button";
import 'react-tabs/style/react-tabs.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewsLine from "../../assets/images/news_line.svg";
import Prev from "../../assets/images/slider_left.png";
import Next from "../../assets/images/slider_right.png";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {getNewsAsync} from "../../redux/actions";

function CenterNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="arrow-next-center"
      style={{ ...style}}
      onClick={onClick}
    >
      <img src={Next} alt="" />
    </div>
  );
}

function CenterPrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="arrow-prev-center"
      style={{ ...style}}
      onClick={onClick}
    >
      <img src={Prev} alt="" />
    </div>
  );
}

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="arrow-next"
      style={{ ...style}}
      onClick={onClick}
    >
      <img src={Next} alt="" />
    </div>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="arrow-prev"
      style={{ ...style}}
      onClick={onClick}
    >
      <img src={Prev} alt="" />
    </div>
  );
}
// Component for /home
class Front extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      placeholder: "Écrivez ici votre proposition",
      modalIsOpen: false,
      title: "",
      user: null,
      news: null,
      tabIndex: 0,
      tabIndex2: 0,
      totalItems: 10,
      tabIndex3: 0,
      matches: window.matchMedia("(min-width: 768px)").matches,
      tabIndex4: 0,
      paramsMostVotedAll : {
         sort: {
            votes: "desc"
         },
         filter: {
            date: "all",
            topics: [],
            towns: []
         },
         limit:10
      },
      paramsMostVotedWeek : {
         sort: {
            votes: "desc"
         },
         filter: {
            date: "week",
            topics: [],
            towns: []
         },
         limit:10
      },
      paramsMostVoted : {
         sort: {
            votes: "desc"
         },
         filter: {
            date: "today",
            topics: [],
            towns: []
         },
         limit:10
      }

    };
  }

  init (textBtn) {
    var observe;
    if (window.attachEvent) {
      observe = function (element, event, handler) {
        if (element ) {
          element.attachEvent('on'+event, handler);
        }
      };
    }
    else {
      observe = function (element, event, handler) {
        if (element ) {
          element.addEventListener(event, handler, false);

        }
      };
    }
    
    var text = document.getElementById(textBtn);
    if(text) {
      function resize () {
          text.style.height = 'auto';
          var x = text.scrollHeight + 20;
          text.style.height = x+'px';
      }
      /* 0-timeout to get the already changed text */
      function delayedResize () {
          window.setTimeout(resize, 0);
      }
      observe(text, 'change',  resize);
      observe(text, 'cut',     delayedResize);
      observe(text, 'paste',   delayedResize);
      observe(text, 'drop',    delayedResize);
      observe(text, 'keydown', delayedResize);

      resize();
    }
  }

  componentDidMount() {
    this.init('new-proposal-footer');
  }

  handleTotal = totalItems => {
    this.setState({ totalItems });
  };

  openModal = () => {
    this.setState({modalIsOpen: true});
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
  }


  handleTitle = (e) => {
    this.setState({title: e.target.value})
  }

  handleFocus = (e) => {
    this.setState({placeholder: ""})
  }

  handleBlur = (e) => {
    this.setState({placeholder: "Écrivez ici votre proposition"})
  }

  componentDidUpdate(newProps) {
    this.init('new-proposal-footer');
    if(this.props.user !== newProps.user) {
      this.setState({user:this.props.user}, () => {
        if(this.props.user.town && this.props.user.town.code && this.props.user.town.code.startsWith("83")) {
          this.props.getNewsAsync("partner/municipales/articles/varmatin/municipales2020");
        } else {
          this.props.getNewsAsync("partner/municipales/articles/nicematin/municipales2020");
        }
      });
    }
    if(this.props.news !== newProps.news && this.props.news && this.props.news.news) {
      this.setState({news : this.props.news.news.articles});
    }
  }

  timeSince = (date) => {
    let intervals = [
      { label: 'année', seconds: 31536000 },
      { label: 'mois', seconds: 2592000 },
      { label: 'jour', seconds: 86400 },
      { label: 'heure', seconds: 3600 },
      { label: 'minute', seconds: 60 },
      { label: 'seconde', seconds: 0 }
    ];
    date = date.replace(/ /g,"T");
    date = new Date(date+"Z");
    const seconds = Math.abs(Math.floor((Date.now() - date.getTime()) / 1000));
    const interval = intervals.find(i => { return i.seconds < seconds});
    const count = interval ? Math.floor(seconds / interval.seconds) : 1;
    return `${count} ${interval ? interval.label : "seconde"}${count !== 1 ? 's' : ''}`;
  }

  render() {
    const handler = e => this.setState({matches: e.matches});
    window.matchMedia("(min-width: 768px)").addListener(handler);
    var settingsTopPropositionSlider = {
      dots: false,
      infinite: false,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      className: "center",
      speed: 750,
      swipeToSlide: true,
      centerPadding: "5px",
      touchThreshold: 12,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: "unslick"
        }
      ]
    };

    var settingsTodaySlider = {
      dots: false,
      infinite: true,
      arrows: true,
      centerPadding: "5px",
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: -1,
      className: "center",
      speed: 750,
      swipeToSlide: true,
      touchThreshold: 12,
      nextArrow: <CenterNextArrow />,
      prevArrow: <CenterPrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            initialSlide: 0,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            initialSlide: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            arrows: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            initialSlide: 0,
            arrows: false,
            adaptiveHeight: true,
            slidesToScroll: 1
          }
        }
      ]
    };
    return ( <div className="home">
      <Helmet>
        <title>{"MOI, MAIRE • Plateforme participative • Municipales 2020"}</title>
        <meta name="description" content="Mettez-vous à la place du maire de votre commune et faites vos propositions pour améliorer la vie citoyenne dans le cadre des Municipales 2020 avec Nice-Matin" />
        <meta
          property="og:title"
          content="MOI, MAIRE • Plateforme participative • Municipales 2020"
        />
        <meta
          property="og:description"
          content="Mettez-vous à la place du maire de votre commune et faites vos propositions pour améliorer la vie citoyenne dans le cadre des Municipales 2020 avec Nice-Matin"
        />
        <meta
          property="og:image"
          content=""
        />
        {/*<meta property="og:url" content="http://euro-travel-example.com/index.htm"></meta>*/}
      </Helmet>
      <AddPropositionModal modalIsOpen={this.state.modalIsOpen} title={this.state.title} closeModal={this.closeModal}/>
      <div className="dark-section today-section">
        <TextInput id="new-proposal-footer" type="text" handleBlur={this.handleBlur} handleFocus={this.handleFocus} placeholder={this.state.placeholder} value={this.state.title} handleChange={this.handleTitle} closing={this.openModal} imagePlaceholder={Add} rows="1" name="username" className="button round proposition bg-white text-primary" field="" form={{touched:true}} />
        {this.state.totalItems > 0 ? <h3 className="header-section-mono">Aujourd'hui</h3> : ""}
        {this.props.user ? this.state.totalItems > 0 ? <MySlider resetting={true} totalItems={this.handleTotal} bigCard={true} comp={99} filter={true} getproposals={true} dynamique={true} settings={settingsTodaySlider} params={{
            sort: {
              created_at: "desc"
            },
            filter: {
              voter: this.props.user ? this.props.user.id : 0,
              voted: 0
            },
            limit:10
        }}/> : "" : <Loader />}
      </div>
      <div className="dark-section top-section">
        <h3 className="header-section">Top propositions</h3>
        <div className="hide-on-mobile">
          <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
            <TabList>
              <Tab>AUJOURD’HUI</Tab>
              <Tab>CETTE SEMAINE</Tab>
              <Tab>DEPUIS LE DÉBUT</Tab>
            </TabList>
              <TabPanel>
                  <MySlider comp={2} getproposals={true} getusers={false} settings={settingsTopPropositionSlider} params={this.state.paramsMostVoted} />
              </TabPanel>
              <TabPanel>
                  <MySlider comp={3} resetting={true} getproposals={true} getusers={false} settings={settingsTopPropositionSlider} params={this.state.paramsMostVotedWeek} />
              </TabPanel>
              <TabPanel>
                  <MySlider comp={4} resetting={true} getproposals={true} getusers={false} settings={settingsTopPropositionSlider} params={this.state.paramsMostVotedAll} />
              </TabPanel>
          </Tabs>
        </div>
        <div className="hide-on-desktop">
          <Tabs selectedIndex={this.state.tabIndex2} onSelect={tabIndex => this.setState({ tabIndex2: tabIndex })}>
            <TabList>
              <Tab>AUJOURD’HUI</Tab>
              <Tab>CETTE SEMAINE</Tab>
              <Tab>DEPUIS LE DÉBUT</Tab>
            </TabList>
              <TabPanel>
                <ClickScroll comp={5} getproposals={true} getusers={false} callOnClick={false} params={this.state.paramsMostVoted}  />
              </TabPanel>
              <TabPanel>
                <ClickScroll comp={6}  getproposals={true} getusers={false} callOnClick={false} params={this.state.paramsMostVotedWeek}  />
              </TabPanel>
              <TabPanel>
                <ClickScroll comp={7} getproposals={true} getusers={false} callOnClick={false} params={this.state.paramsMostVotedAll}  />
              </TabPanel>
          </Tabs>
        </div>
        <Link to={{pathname:"/top-propositions"}}><Button color="white" background="transparent" text="Voir le classement complet" type="round" className="mt-20"/></Link>
    </div>

    <div className="dark-section top-section">
      <h3 className="header-section">Top candidats</h3>
      {this.state.matches ? 
      <div className="hide-on-mobile">
        <Tabs selectedIndex={this.state.tabIndex3} onSelect={tabIndex => this.setState({ tabIndex3: tabIndex })}>
          <TabList>
            <Tab>AUJOURD’HUI</Tab>
            <Tab>CETTE SEMAINE</Tab>
            <Tab>DEPUIS LE DÉBUT</Tab>
          </TabList>

          <TabPanel>
              <MySlider comp={8} getusers={true} getproposals={false} callOnClick={false} settings={settingsTopPropositionSlider} params={this.state.paramsMostVoted}/>
          </TabPanel>
          <TabPanel>
              <MySlider comp={9} resetting={true} getusers={true} getproposals={false} callOnClick={false} settings={settingsTopPropositionSlider} params={this.state.paramsMostVotedWeek}/>
          </TabPanel>
          <TabPanel>
              <MySlider comp={10} resetting={true} getusers={true} getproposals={false} callOnClick={false} settings={settingsTopPropositionSlider} params={this.state.paramsMostVotedAll}/>
          </TabPanel>
        </Tabs>
      </div>
      :
      <div className="hide-on-desktop">
        <Tabs selectedIndex={this.state.tabIndex4} onSelect={tabIndex => this.setState({ tabIndex4: tabIndex })}>
          <TabList>
            <Tab>AUJOURD’HUI</Tab>
            <Tab>CETTE SEMAINE</Tab>
            <Tab>DEPUIS LE DÉBUT</Tab>
          </TabList>
          
          <TabPanel>
              <ClickScroll comp={11} getusers={true} getproposals={false} callOnClick={false} params={this.state.paramsMostVoted}  />
          </TabPanel>
          <TabPanel>
              <ClickScroll comp={12} getusers={true} getproposals={false} callOnClick={false} params={this.state.paramsMostVotedWeek}  />
          </TabPanel>
          <TabPanel>
              <ClickScroll comp={13} getusers={true} getproposals={false} callOnClick={false} params={this.state.paramsMostVotedAll}  />
          </TabPanel>
        </Tabs>
      </div>
      }
      <Link to={{pathname:"/top-candidats"}}><Button color="white" background="transparent" text="Voir le classement complet" type="round" className="mt-20"/></Link>
    </div>

      <div className="news white-section">
        <h3 className="header-section">À la une de l'actualité sur {this.props.user && this.props.user.town ? this.props.user.town.code && this.props.user.town.code.startsWith("83") ? "Var-Matin" : "Nice-Matin" : "Nice-Matin"}</h3>
        <div className="all-news">
          {this.state.news ? this.state.news.map((article, i) => {
            if(i===0) {
              return( <a key={i} href={article.url} target="_blank" rel="noopener noreferrer"><div className="article-news first-news">
                <div className="article-content">
                  <img src={article.image} alt="" />
                  <h4>{article.title}</h4>
                </div>
                <div className="news-bottom">
                  <span className="tag">#politique</span>
                  <span className="time">Il y a {this.timeSince(article.publication_date)}</span>
                </div>
                <img className="line" src={NewsLine} alt="" />
              </div> </a>)
            } else {
              return(<a key={i} href={article.url} target="_blank" rel="noopener noreferrer" ><div className="article-news">
                <div className="article-content">
                  <img src={article.image} alt="" />
                  <h4>{article.title}</h4>
                </div>
                <div className="news-bottom">
                  <span className="tag">#politique</span>
                  <span className="time">Il y a {this.timeSince(article.publication_date)}</span>
                </div>
                <img className="line" src={NewsLine} alt="" />
              </div></a>)
            }
          }) : ""}

          </div>
        {this.props.user && this.props.user.town ? this.props.user.town.code && this.props.user.town.code.startsWith("83") ?
          <a href="https://www.varmatin.com/sujet/municipales2020" target="_blank" rel="noopener noreferrer"><Button color="secondary" background="white" text="Le reste de l'actu avec Var-Matin" type="round"/></a>
          :
          <a href="https://www.nicematin.com/sujet/municipales2020" target="_blank" rel="noopener noreferrer"><Button color="secondary" background="white" text="Le reste de l'actu avec Nice-Matin" type="round"/></a>
        : <a href="https://www.nicematin.com/sujet/municipales2020" target="_blank" rel="noopener noreferrer"><Button color="secondary" background="white" text="Le reste de l'actu avec Nice-Matin" type="round"/></a>}
        </div>
        <SubjectsPropositions />

    </div>
    );
  }
}

const mapStateToProps = ({ topic, news, users }) => {
  const {user} = users
	return { topic, news, user };
};

export default withRouter(connect(
	mapStateToProps,
  {
		getNewsAsync
	}
)(Front));
