import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Search from "../../assets/images/Search_black.svg";
import { components } from "react-select";
import SubjectsPropositions from "../../components/subjectspropositions";
import "react-tabs/style/react-tabs.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select, { Option } from "rc-select";
import { connect } from "react-redux";
import { getTopicsAsync } from "../../redux/actions";
import InfiniteScroll from "../../components/infinitescroll";
import AsyncPaginate from "react-select-async-paginate";
import { animateScroll as scroll } from "react-scroll";
import { apiUrl } from "../../constants/defaultValues";
import { Helmet } from "react-helmet";
import hashGenerator from "../../helpers/hashGenerate";

// Component for /home
class Proposition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      changedValue: "",
      filter: false,
      townUser: {},
      resetting: false,
      totalItems: 0,
      town: "",
      params: {
        sort: { created_at: "desc" },
        filter: {
          date: "",
          towns: [],
          topics: this.props.match.params.action
            ? [this.props.match.params.action]
            : []
        }
      },
      selectedTopic: []
    };
  }
  componentDidMount() {
    scroll.scrollToTop({ duration: 250, delay: 0 });
    if (this.props.match.params && this.props.match.params.action) {
      this.setState({ selectedTopic: this.props.match.params.action }, () => {
        this.setState({ filter: true }, () => {
          this.setState(
            prevState => ({
              params: {
                ...prevState.params,
                filter: {
                  ...prevState.filter,
                  towns: this.state.params.filter.towns,
                  date: this.state.params.filter.date,
                  topics: [this.state.selectedTopic]
                }
              }
            }),
            () => {}
          );
        });
      });
    }
  }

  componentDidUpdate(newProps) {
    if (
      JSON.stringify(this.props.match.params) !==
      JSON.stringify(newProps.match.params)
    ) {
      if (this.props.match.params.action) {
        this.setState({ selectedTopic: this.props.match.params.action }, () => {
          this.setState({ resetting: false }, () => {
            this.setState({ filter: true }, () => {
              this.setState(
                prevState => ({
                  params: {
                    ...prevState.params,
                    filter: {
                      ...prevState.filter,
                      towns: [],
                      date: "",
                      topics: [this.state.selectedTopic]
                    }
                  }
                }),
                () => {}
              );
            });
          });
        });
      } else {
        this.setState({ selectedTopic: [] }, () => {
          this.setState({ resetting: true }, () => {
            this.setState({ filter: false }, () => {
              this.setState(
                prevState => ({
                  params: {
                    ...prevState.params,
                    filter: {
                      ...prevState.filter,
                      towns: this.state.params.filter.towns,
                      date: this.state.params.filter.date,
                      topics: [this.state.selectedTopic]
                    }
                  }
                }),
                () => {}
              );
            });
          });
        });
      }
    }
    if (
      newProps.users !== this.props.users &&
      this.props.users.user &&
      this.state.filter === false
    ) {
      this.setState({
        town:
          this.props.users &&
          this.props.users.user &&
          this.props.users.user.town &&
          this.props.users.user.town.name
      });
      this.setState({
        townUser: {
          value:
            this.props.users &&
            this.props.users.user &&
            this.props.users.user.town &&
            this.props.users.user.town.id,
          label:
            this.props.users &&
            this.props.users.user &&
            this.props.users.user.town &&
            this.props.users.user.town.name
        }
      });
    }
  }

  async loadOptions(search, loadedOptions, { page }) {
    const response = await fetch(
      `${apiUrl}/towns/?query=${search}&page=${page}`
    );
    const responseJSON = await response.json();
    const data = [];
    responseJSON.data.map(choice => {
      data.push({ value: choice.id, label: choice.name });
      return choice;
    });
    return {
      options: data,
      hasMore: responseJSON.pagination.page < responseJSON.pagination.nb_pages,
      additional: {
        page: page + 1
      }
    };
  }
  setTown = value => {
    this.setState({ resetting: true });
    this.setState({ filter: true });
    this.setState({ townUser: value });
    this.setState({ town: value.label });
    this.setState(
      prevState => ({
        params: {
          ...prevState.params,
          filter: {
            ...prevState.filter,
            topics: this.state.params.filter.topics,
            date: this.state.params.filter.date,
            towns: [value.value]
          }
        }
      }),
      () => {}
    );
  };
  setTopic = value => {
    if (value === "") {
      this.setState({ filter: true }, () => {
        this.setState(
          prevState => ({
            params: {
              ...prevState.params,
              filter: {
                ...prevState.filter,
                towns: this.state.params.filter.towns,
                date: this.state.params.filter.date,
                topics: []
              }
            }
          }),
          () => {}
        );
      });
    } else {
      this.setState({ filter: true });
      this.setState(
        prevState => ({
          params: {
            ...prevState.params,
            filter: {
              ...prevState.filter,
              towns: this.state.params.filter.towns,
              date: this.state.params.filter.date,
              topics: [value]
            }
          }
        }),
        () => {}
      );
    }
  };
  sorting = value => {
    this.setState({ resetting: true });
    if (value === "votes") {
      this.setState(
        prevState => ({
          params: {
            ...prevState.params,
            filter: {
              ...prevState.filter,
              topics: this.state.params.filter.topics,
              towns: this.state.params.filter.towns,
              date: "all"
            }
          }
        }),
        () => {}
      );
      this.setState(
        prevState => ({
          params: {
            ...prevState.params,
            sort: { ...prevState.sort, [value]: "desc" }
          }
        }),
        () => {}
      );
    } else {
      this.setState(
        prevState => ({
          params: {
            ...prevState.params,
            sort: { ...prevState.sort, [value]: "desc" }
          }
        }),
        () => {}
      );
    }
  };
  handleTotal = totalItems => {
    this.setState({ totalItems });
  };
  render() {
    const CaretDownIcon = () => {
      return <img src={Search} alt="" />;
    };

    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <CaretDownIcon />
        </components.DropdownIndicator>
      );
    };
    const customStyles = {
      indicatorSeparator: (provided, state) => {
        const opacity = 0;

        return { ...provided, opacity };
      }
    };
    var selects = document.getElementsByClassName(
      "rc-select-selection-search-input"
    );
    if (selects[0]) {
      selects[0].setAttribute("readonly", true);
    }
    if (selects[1]) {
      selects[1].setAttribute("readonly", true);
    }
    return (
      <div className="home propositions">
        <Helmet>
          <title>
            {"Propositions Var et Alpes-Maritimes • Municipales 2020"}
          </title>
          <meta
            name="description"
            content="Consultez l'ensemble des propositions sur les communes du Var et des Alpes-Maritimes dans le cadre des élections Municipales 2020 avec la rédaction de Nice-Matin"
          />
          <meta
            property="og:title"
            content="Propositions Var et Alpes-Maritimes • Municipales 2020"
          />
          <meta
            property="og:description"
            content="Consultez l'ensemble des propositions sur les communes du Var et des Alpes-Maritimes dans le cadre des élections Municipales 2020 avec la rédaction de Nice-Matin"
          />
          <meta property="og:image" content="" />
          {/*<meta property="og:url" content="http://euro-travel-example.com/index.htm"></meta>*/}
        </Helmet>
        <div className="white-section">
          <h3 className="header-section">
            {this.state.totalItems}{" "}
            {this.state.totalItems > 1 ? "propositions" : "proposition"}{" "}
            {this.state.params.filter.towns.length > 0
              ? "à " + this.state.townUser.label
              : ""}
          </h3>

          <div className="filter">
            <div className="town-choice">
              <AsyncPaginate
                loadOptions={this.loadOptions}
                components={{ DropdownIndicator }}
                onChange={this.setTown}
                placeholder="Consultez dans une autre ville"
                additional={{
                  page: 1
                }}
                className="my-select"
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#00102B",
                    neutral0: "#e5e5e5",
                    neutral50: "#00102B",
                    primary25: "hotpink"
                  }
                })}
              />
            </div>
            <div className="right">
              <Select
                animation="slide-up"
                value={
                  this.state.params
                    ? this.state.params.filter
                      ? this.state.params.filter.topics
                        ? this.state.params.filter.topics[0]
                          ? Number(this.state.params.filter.topics[0])
                            ? Number(this.state.params.filter.topics[0])
                            : ""
                          : ""
                        : ""
                      : ""
                    : ""
                }
                showSearch={false}
                onChange={this.setTopic}
              >
                {this.props.topic && this.props.topic.topics ? (
                  <Option value="">Toutes thématiques</Option>
                ) : (
                  ""
                )}
                {this.props.topic && this.props.topic.topics
                  ? Object.values(this.props.topic.topics).map((topic, i) => {
                      return (
                        <Option key={i} value={topic.id}>
                          {topic.name}
                        </Option>
                      );
                    })
                  : ""}
              </Select>
              <Select
                allowClear
                placeholder="Trier"
                animation="slide-up"
                showSearch={false}
                onChange={this.sorting}
              >
                <Option value="created_at">Date de création</Option>
                <Option value="shares">Plus partagées</Option>
                <Option value="votes">Plus aimées</Option>
              </Select>
            </div>
          </div>

          <div className="row scroller">
            <InfiniteScroll
              comp={hashGenerator()}
              filter={this.state.filter}
              resetting={this.state.resetting}
              totalItems={this.handleTotal}
              getproposals={true}
              getusers={false}
              callOnClick={false}
              params={this.state.params}
            />
          </div>
        </div>

        <SubjectsPropositions />
      </div>
    );
  }
}

const mapStateToProps = ({ topic, users }) => {
  return { topic, users };
};

export default withRouter(
  connect(mapStateToProps, {
    getTopicsAsync
  })(Proposition)
);
