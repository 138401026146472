import {
	LOGIN_USER,
	LOGIN_USER_SUCCESS,
	LOGIN_USER_ERROR,
	LOGOUT_USER,
	REGISTER_USER,
	LOGIN_USER_FB,
	LOGIN_USER_SUCCESS_FB,
	LOGIN_USER_ERROR_FB,
	REGISTER_USER_SUCCESS
} from "../../constants/actionTypes";

export const loginUser = (user, history) => ({
	type: LOGIN_USER,
	payload: { user, history }
});
export const loginUserSuccess = user => ({
	type: LOGIN_USER_SUCCESS,
	payload: user
});
export const loginUserError = user => ({
	type: LOGIN_USER_ERROR,
	payload: user
});

export const loginUserFB = (user, history) => ({
	type: LOGIN_USER_FB,
	payload: { user, history }
});
export const loginUserSuccessFB = user => ({
	type: LOGIN_USER_SUCCESS_FB,
	payload: user
});
export const loginUserErrorFB = user => ({
	type: LOGIN_USER_ERROR_FB,
	payload: user
});

export const registerUser = (user, history) => ({
	type: REGISTER_USER,
	payload: { user, history }
});
export const registerUserSuccess = user => ({
	type: REGISTER_USER_SUCCESS,
	payload: user
});

export const logoutUser = history => ({
	type: LOGOUT_USER
});
