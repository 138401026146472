import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Nav from "../../../components/nav";
import { connect } from "react-redux";
import TextInput from "../../../components/form/textInput";
import { forgetPassUserBasic } from "../../../redux/actions";
import { animateScroll as scroll } from "react-scroll";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ComposantErreur = props => {
  return (
    <div className="error-message">
      <span>{props.children}</span>
    </div>
  );
};

// Component for /Geolocalisation
class Forgot extends Component {
  constructor() {
    super();
    this.state = {
      form: null,
      isSubmitting: false,
      successMsg: false,
      errorMsg: false
    };
  }

  componentDidMount() {
    scroll.scrollToTop({ duration: 250, delay: 0 });
  }

  handleFormSubmit = async (values, actions) => {
    await this.props.forgetPassUserBasic(values.username);
  };

  componentDidUpdate(newProps) {
    if (this.props.user !== newProps.user) {
      if (
        (this.props.user && this.props.user.status === 200) ||
        this.props.user.status === 204
      ) {
        this.setState({ successMsg: true });
      } else {
        this.setState({ errorMsg: true });
      }
    }
  }

  userSchema = Yup.object().shape({
    username: Yup.string()
      .email("Email Invalide")
      .required("Champs obligatoire")
  });

  render() {
    return (
      <div className="connect-form forgot">
        <Nav whiteVersion={true} arrow_left={true} logo={true} close={true} />
        <h1>Réinitialiser votre mot de passe</h1>
        <div className="form">
          <Formik
            onSubmit={this.handleFormSubmit}
            initialValues={{ username: "", password: "" }}
            validationSchema={this.userSchema}
            validateOnChange={true}
          >
            {({ handleSubmit, isSubmitting, actions, errors }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  type="text"
                  placeholder="Votre adresse email *"
                  name="username"
                  className="round"
                  errorClass={errors.username ? "errors" : ""}
                  label="Adresse email"
                  component={TextInput}
                />
                <ErrorMessage name="username" component={ComposantErreur} />
                {this.state.successMsg ? (
                  <div className="success-message">
                    Un e-mail de réinitialisation vous a été envoyé
                  </div>
                ) : (
                  ""
                )}
                {this.state.errorMsg ? (
                  <div className="error">Un erreur est survenue</div>
                ) : (
                  ""
                )}
                <button
                  type="submit"
                  className="button round text-primary bg-green round btn btn-4 btn-4c icon-arrow-right"
                  disabled={isSubmitting || this.state.isSubmitting}
                >
                  Envoyer
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    forgetPassUserBasic
  })(Forgot)
);
