import React from "react";
import FacebookLogin from "react-facebook-login";
import "./buttonFacebook.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loginUserFromFacebook } from "../../redux/actions";
import { FACEBOOK_APP_ID } from "../../constants/defaultValues";

class buttonFacebook extends React.Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      token: ""
    };
  }

  responseFacebook = response => {
    this.setState({ token: response.accessToken });
    this.setState({ firstName: response.first_name });
    this.setState({ lastName: response.last_name });
    this.setState({ email: response.email });
    this.props.loginUserFromFacebook(response.accessToken);
  };

  componentDidUpdate(newProps) {
    if (
      this.props.user &&
      this.props.user !== newProps.user &&
      this.props.user.token
    ) {
      this.props.history.push({
        pathname: "/"
      });
    } else if (this.props.user !== newProps.user) {
      this.props.history.push({
        pathname: "/auth/register",
        state: {
          type: "facebook",
          token: this.state.token,
          firstNameFB: this.state.firstName,
          lastNameFB: this.state.lastName,
          emailFB: this.state.email
        }
      });
    }
  }
  render() {
    return (
      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        fields="name,first_name,last_name,email,picture"
        icon="fa-facebook"
        textButton="Connexion avec Facebook"
        callback={this.responseFacebook}
      />
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    loginUserFromFacebook
  })(buttonFacebook)
);
