import {
  	TOWN_ONE,
  	TOWN_ONE_SUCCESS,
  	TOWN_ONE_ERROR,
  	TOWN_LIST,
  	TOWN_LIST_SUCCESS,
  	TOWN_LIST_ERROR
} from "../../constants/actionTypes";

const INIT_STATE = {
    town: "",
    towns: [],
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOWN_ONE:
            return { ...state, loading: true };
        case TOWN_ONE_SUCCESS:
            //notify.success('Login Success');
            return { ...state, loading: false, town: action.payload };
        case TOWN_ONE_ERROR:
            //notify.success('Login Success');
            return { ...state, loading: false, town: action.payload };
        case TOWN_LIST:
            return { ...state, loading: true };
        case TOWN_LIST_SUCCESS:
            //notify.success('Login Success');
            return { ...state, loading: false, towns: action.payload };
        case TOWN_LIST_ERROR:
            //notify.success('Login Success');
            return { ...state, loading: false, towns: action.payload };
        default: return { ...state };
    }
}
