import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Geolocalisation from "./Geolocalisation";
import SubjectsChoice from "./SubjectsChoice";
import CustomLocation from "./CustomLocation";
import Home from "../../home/Lazy";
import Auth from "../../auth/Lazy";
import InscriptionForm from "./InscriptionForm";
import InscriptionCompleted from "./InscriptionCompleted";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { animateScroll as scroll } from "react-scroll";
import { registerUserFromFacebook } from "../../../redux/actions";
import { Helmet } from "react-helmet";

// Component for /register
class Register extends Component {
  constructor() {
    super();
    this.state = {
      profile: null,
      town: null,
      topics: null,
      token: null,
      firstNameFB: "",
      lastNameFB: "",
      emailFB: "",
      type: "basic"
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.token) {
      this.setState({ token: this.props.location.state.token });
      this.setState({ firstNameFB: this.props.location.state.firstNameFB });
      this.setState({ lastNameFB: this.props.location.state.lastNameFB });
      this.setState({ emailFB: this.props.location.state.emailFB });
      this.setState({ type: "facebook" });
    }
    scroll.scrollToTop({ duration: 200, delay: 0 });
  }

  componentDidUpdate(newProps) {
    if (this.props.location.state !== newProps.location.state) {
      var state = newProps.location.state;
      if (state) {
        if (state.profile) {
          this.handleProfile(state.profile);
        }
        if (state.town) {
          this.handleTown(state.town);
        }
        if (state.topics) {
          this.handleTopics(state.topics);
        }
      }
    }
    if (
      this.props.user &&
      this.props.user.token &&
      newProps.user !== this.props.user
    ) {
      this.props.history.push({
        pathname: "/finished"
      });
    } else if (this.props.user && newProps.user !== this.props.user) {
      this.props.history.push("/auth/register/form", { inscription: "true" });
    }
  }

  handleProfile = profile => {
    this.setState({ profile });
  };

  handleTown = town => {
    this.setState({ town });
  };

  handleTopics = chosenTopics => {
    let user_topics = chosenTopics.reduce(function(a, e, i) {
      if (e === true) a.push(i + 1);
      return a;
    }, []);
    this.setState({ topics: user_topics }, () => {
      if (this.state.type === "facebook") {
        let formState = this.state;

        let inscriptionCall = {
          username: formState.emailFB,
          profile: {
            firstName: formState.firstNameFB,
            lastName: formState.lastNameFB
          },
          subscription: {
            newsletter: false,
            offers: false
          },
          topics: formState.topics,
          town: formState.town,
          token: formState.token
        };
        this.props.registerUserFromFacebook(inscriptionCall);
      }
    });
  };

  render() {
    return (
      <BrowserRouter>
        <Helmet>
          <title>
            {"Devenez maire de votre commune et améliorez la vie citoyenne"}
          </title>
          <meta
            name="description"
            content={
              "Inscrivez-vous pour améliorer la vie citoyenne de votre commune. Grâce à MOI, MAIRE, les Municipales 2020 deviennent participatives !"
            }
          />
          <meta
            property="og:title"
            content={
              "Devenez maire de votre commune et améliorez la vie citoyenne"
            }
          />
          <meta
            property="og:description"
            content={
              "Inscrivez-vous pour améliorer la vie citoyenne de votre commune. Grâce à MOI, MAIRE, les Municipales 2020 deviennent participatives !"
            }
          />
          <meta property="og:image" content="" />
          {/*<meta property="og:url" content="http://euro-travel-example.com/index.htm"></meta>*/}
        </Helmet>
        <Switch>
          <Route
            exact
            path={`/finished`}
            render={() => <InscriptionCompleted />}
          />
          <Route
            exact
            path={`/auth/register/form`}
            render={() => (
              <InscriptionForm
                state={this.state}
                userProfile={this.handleProfile}
              />
            )}
          />
          <Route
            exact
            path={`/auth/register/subjects`}
            render={() => (
              <SubjectsChoice
                state={this.state}
                userTopic={this.handleTopics}
              />
            )}
          />
          <Route
            exact
            path={`/auth/register/location`}
            render={() => (
              <CustomLocation state={this.state} userTown={this.handleTown} />
            )}
          />
          <Route
            exact
            path={`/auth/register`}
            render={() => (
              <Geolocalisation state={this.state} userTown={this.handleTown} />
            )}
          />

          <Route exact path={`/auth/login`} render={() => <Auth />} />
          <Route exact path={`/`} component={Home} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    registerUserFromFacebook
  })(Register)
);
