import {
	TOPICS_LIST,
	TOPICS_LIST_SUCCESS,
	TOPICS_LIST_ERROR
} from "../../constants/actionTypes";

export const listTopics = (topic, history) => ({
	type: TOPICS_LIST,
	payload: { topic, history }
});
export const listTopicsSuccess = topic => ({
	type: TOPICS_LIST_SUCCESS,
	payload: topic
});
export const listTopicsError = topic => ({
	type: TOPICS_LIST_ERROR,
	payload: topic
});
