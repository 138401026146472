import {
  USER_ONE,
  USER_ONE_SUCCESS,
  USER_ONE_ERROR,
  USER_LIST,
  USER_LIST_SUCCESS,
  USER_LIST_RESET,
  USER_LIST_ERROR
} from "../../constants/actionTypes";

export const getUser = user => ({
  type: USER_ONE,
  payload: { user }
});
export const getUserSuccess = user => ({
  type: USER_ONE_SUCCESS,
  payload: user
});
export const getUserError = user => ({
  type: USER_ONE_ERROR,
  payload: user
});

export const listUser = comp => ({
  type: USER_LIST,
  payload: comp
});
export const listUserSuccess = user => ({
  type: USER_LIST_SUCCESS,
  payload: user
});
export const listUserError = user => ({
  type: USER_LIST_ERROR,
  payload: user
});
export const listUsersReset = comp => ({
  type: USER_LIST_RESET,
  payload: comp
});
