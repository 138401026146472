import React, { Component } from "react";

import "./tag.scss";

class Tag extends Component {
  render() {
    return (
      <div ref={(node) => {
      if (node) {
        node.style.setProperty("color", this.props.background , "important");
      }
    }} className={`button ${ this.props.type } text-${ this.props.color } bg-${ this.props.background } ${ this.props.type } ${ this.props.className }`} style={{borderColor:  this.props.background }}   onClick={() => this.props.checked(this.props.index)}>
        {this.props.text}
      </div>
    );
  }
}

export default Tag;
