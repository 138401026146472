export function authHeader() {
    // return authorization header with jwt token
    let state = JSON.parse(localStorage.getItem('state'));

    if (state && state.authUser && state.authUser.user && state.authUser.user.token) {
        return { 'Authorization': 'Bearer ' + state.authUser.user.token };
    } else {
        return {};
    }
}

export function authHeaderData() {
    // return authorization header with jwt token
    let state = JSON.parse(localStorage.getItem('state'));

    if (state && state.authUser && state.authUser.user && state.authUser.user.token) {
        return { 'Authorization': 'Bearer ' + state.authUser.user.token, 'Content-Type': 'multipart/form-data' };
    } else {
        return {};
    }
}

export function authHeaderNiceMatin() {
    // return authorization header with jwt token
    let state = JSON.parse(localStorage.getItem('state'));

    if (state && state.nicematin && state.nicematin.news && state.nicematin.news.token) {
        return { 'NM-Access-Token': state.nicematin.news.token };
    } else {
        return {};
    }
}
