import {
  	TOPICS_LIST,
  	TOPICS_LIST_SUCCESS,
  	TOPICS_LIST_ERROR,
} from "../../constants/actionTypes";

const INIT_STATE = {
    topics: [],
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOPICS_LIST:
            return { ...state, loading: true };
        case TOPICS_LIST_SUCCESS:
            //notify.success('Login Success');
            return { ...state, loading: true, topics: action.payload };
        case TOPICS_LIST_ERROR:
            //notify.success('Login Success');
            return { ...state, loading: false, topics: action.payload };
        default: return { ...state };
    }
}
