import React, { Component, Fragment } from "react";
import {Link, withRouter} from "react-router-dom";
import "./mainnav.scss";
import AddPropositionModal from "../addpropositionmodal";
import Home from "../../assets/images/menu/light_home.svg";
import Search from "../../assets/images/menu/search.svg";
import Proposal from "../../assets/images/menu/light_new_proposal.svg";
import GeoLoc from "../../assets/images/menu/geoloc.svg";
import User from "../../assets/images/menu/light_user.svg";

class MainNav extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false
    };

  }

  openModal = () => {
    this.setState({modalIsOpen: true});
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  render() {
    return (
      <Fragment>
        <AddPropositionModal modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal}/>
        <div className="mainnav">
          <Link to={{pathname: '/'}}><div><img src={Home} alt="" /><span>Aujourd’hui</span></div></Link>
          <Link to={{pathname: '/propositions'}}><div><img src={Search} alt="" /><span>Parcourir</span></div></Link>
          <Link to={{}} onClick={this.openModal}><div><img src={Proposal} alt="" /><span>Proposer</span></div></Link>
          <Link to={{pathname: '/ville'}}><div><img src={GeoLoc} alt="" /><span>Ma ville</span></div></Link>
          <Link to={{pathname: '/profile'}}><div><img src={User} alt="" /><span>Profil</span></div></Link>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(MainNav)
