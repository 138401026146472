import React from 'react'
import Loadable from 'react-loadable';
import AppLoader from '../../components/loading';

const LazyAuth = Loadable({
  loader: () => import(/* webpackChunkName: 'auth' */ './index'),
  loading: () => <AppLoader title='home' />
})

export default LazyAuth;
