import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import "./profilemodal.scss";
import Modal from "react-modal";
import Avatar from "../../assets/images/avatar.png";
import TextInput from "../../components/form/textInput";
import TextArea from "../../components/form/textarea";
import { connect } from "react-redux";
import Close from "../../assets/images/close.svg";
import Header from "../../components/header";
import CheckboxInput from "../../components/form/checkboxInput";
import Button from "../../components/button";
import Plus from "../../assets/images/plus.svg";
import Tag from "../../components/tag";
import {
  logoutUser,
  getTopicsAsync,
  updateUserAsync,
  uploadMedia
} from "../../redux/actions";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AsyncPaginate from "react-select-async-paginate";
import { apiUrl } from "../../constants/defaultValues";

const ComposantErreur = props => {
  return (
    <div className="error-message">
      <span>{props.children}</span>
    </div>
  );
};
class ProfileModal extends Component {
  constructor() {
    super();
    this.state = {
      value: "",
      user: null,
      selectedFile: null,
      form: null,
      isSubmitting: false,
      topics: [],
      choices: new Array(17).fill(false),
      modalIsOpenSubject: false,
      chosenTopics: [],
      preview: null,
      userToUpdate: {},
      checkbox1: true,
      checkbox2: false,
      errorImage: ""
    };
  }

  handleChange = e => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
  };

  userSchemaStart = Yup.object().shape({
    firstname: Yup.string().required("Champs obligatoire"),
    lastname: Yup.string().required("Champs obligatoire"),
    username: Yup.string()
      .email("Email Invalide")
      .required("Champs obligatoire")
  });

  userSchemaStartFB = Yup.object().shape({
    firstname: Yup.string().required("Champs obligatoire"),
    lastname: Yup.string().required("Champs obligatoire"),
    username: Yup.string()
      .email("Email Invalide")
      .required("Champs obligatoire")
  });

  handleLogout = () => {
    this.props.logoutUser(this.props.history);
    this.props.history.push({
      pathname: "/auth/login"
    });
  };

  componentDidMount() {
    this.setState({ user: this.props.user });
    this.setState({
      checkbox1:
        this.props.user &&
        this.props.user.subscription &&
        this.props.user.subscription.newsletter
          ? true
          : false
    });
    this.setState({
      checkbox2:
        this.props.user &&
        this.props.user.subscription &&
        this.props.user.subscription.offers
          ? true
          : false
    });
    let town = {
      value: this.props.user.town && this.props.user.town.id,
      label: this.props.user.town && this.props.user.town.name
    };
    this.setState({ value: town });
  }
  componentDidUpdate(newProps) {
    if (this.props.user !== newProps.user) {
      if (this.props.user.data && this.props.user.data.code === 400) {
        this.state.form.setFieldError(
          "username",
          this.props.user.data.errors.username[0]
        );
      } else {
        this.setState({ user: this.props.user }, () => {
          let town = {
            value: this.state.user.town && this.state.user.town.id,
            label: this.state.user.town && this.state.user.town.name
          };
          this.setState({ value: town });
          let choosed =
            this.state.user.topics && this.state.user.topics.map(a => a.id);
          this.setState({ chosenTopics: choosed });
          this.setState({ chosenTopics: choosed });
          this.props.closeModal();
        });
      }
    }
    if (newProps.topic !== this.props.topic) {
      this.setState({ topics: Object.values(this.props.topic.topics) });
      this.setState({
        choices: new Array(Object.values(this.props.topic.topics).length).fill(
          false
        )
      });
    }

    if (newProps.addproposal !== this.props.addproposal) {
      if (
        newProps.addproposal.addedMedia !== this.props.addproposal.addedMedia
      ) {
        if (
          this.props.addproposal.addedMedia &&
          this.props.addproposal.addedMedia.id
        ) {
          this.setState(
            prevState => ({
              userToUpdate: {
                ...prevState.userToUpdate,
                profile: {
                  ...prevState.userToUpdate.profile,
                  image: this.props.addproposal.addedMedia.id
                }
              }
            }),
            () => {
              this.props.updateUserAsync(this.state.userToUpdate);
            }
          );
        }
      }
    }
  }

  setValue = value => {
    this.setState({ value });
  };

  deleteTopic = id => {
    let currentUser = this.state.user;
    let userTopics = this.state.user.topics;
    const newUserTopics = userTopics.filter(function(person) {
      return person.id !== id;
    });
    currentUser.topics = newUserTopics;
    this.setState({ user: currentUser });

    let userTopicsChoosed = this.state.chosenTopics;
    const newuserTopicsChoosed = userTopicsChoosed.filter(function(person) {
      return person !== id;
    });
    this.setState({ chosenTopics: newuserTopicsChoosed });
  };

  handleTopics = topic => {
    if (
      this.state.chosenTopics &&
      this.state.chosenTopics.indexOf(topic) > -1
    ) {
      let filteredPerson = this.state.chosenTopics.filter(
        like => like !== topic
      );
      this.setState({
        chosenTopics: [...filteredPerson]
      });
    } else {
      let filteredPerson = this.state.chosenTopics.filter(
        like => like !== topic
      );
      this.setState({
        chosenTopics: [...filteredPerson, topic]
      });
    }
    var result = this.state.topics.filter(obj => {
      return obj.id === topic;
    });
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        topics: [...this.state.user.topics, result[0]]
      }
    }));
  };

  validateNewTopics = () => {
    /*this.setState({
      topics: this.state.chosenTopics
    });*/
    this.setState({ modalIsOpenSubject: false });
  };

  openModal = () => {
    this.setState({ modalIsOpenSubject: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpenSubject: false });
    this.setState({ errorImage: "" });
  };

  async loadOptions(search, loadedOptions, { page }) {
    const response = await fetch(
      `${apiUrl}/towns/?query=${search}&page=${page}`
    );
    const responseJSON = await response.json();
    const data = [];
    responseJSON.data.map(choice => {
      data.push({ value: choice.id, label: choice.name });
      return choice;
    });
    return {
      options: data,
      hasMore: responseJSON.pagination.page < responseJSON.pagination.nb_pages,
      additional: {
        page: page + 1
      }
    };
  }

  readURL = input => {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = e => {
        this.setState({ preview: e.target.result });
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  fileChangedHandler = event => {
    if (event.target.files[0].size > 3 * 1024 * 1024) {
      this.setState({ errorImage: "L'image est trop volumineuse (Max 3MB)" });
    } else {
      this.setState({ errorImage: "" });
      this.readURL(event.target);
      this.setState({ selectedFile: { file: event.target.files[0] } });
    }
  };

  handleFormSubmit = (values, actions) => {
    this.setState({ form: actions }, () => {
      let userUpdate = {
        username: values.username,
        profile: {
          first_name: values.firstname,
          last_name: values.lastname,
          biography: values.bio,
          image:
            this.state.user &&
            this.state.user.profile &&
            this.state.user.profile.image &&
            this.state.user.profile.image.id
        },
        subscription: {
          newsletter: this.state.checkbox1,
          offers: this.state.checkbox2
        },
        town: this.state.value.value,
        topics: this.state.chosenTopics
          ? this.state.chosenTopics
          : this.state.user.topics
      };
      if (this.state.user.auth_provider === "password") {
        userUpdate.password = values.password;
      }
      if (this.state.selectedFile) {
        this.setState({ userToUpdate: userUpdate }, () => {
          this.props.uploadMedia(this.state.selectedFile);
        });
      } else {
        this.props.updateUserAsync(userUpdate);
      }
    });
  };

  render() {
    const customStyles = {
      indicatorSeparator: (provided, state) => {
        const opacity = 0;
        return { ...provided, opacity };
      }
    };

    return (
      <div className="modalProfile">
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.props.closeModal}
          contentLabel=""
          className="modalProfile white-section"
        >
          <div className="hide-on-desktop header-modal">
            <Header />
          </div>
          <div className="hide-on-mobile">
            <img
              onClick={this.props.closeModal}
              src={Close}
              className="close-modal"
              alt=""
            />
          </div>

          <div className="change-image hide-on-desktop">
            <span>Changer</span>
            <img
              src={
                this.state.preview
                  ? this.state.preview
                  : this.state.user &&
                    this.state.user.profile &&
                    this.state.user.profile.image
                  ? this.state.user.profile.image.link
                  : Avatar
              }
              className="avatar"
              alt=""
            />
            <input
              type="file"
              accept="image/*"
              className="upload-input"
              onChange={this.fileChangedHandler}
            />
          </div>
          {this.state.errorImage ? (
            <p className="hide-on-desktop error-message">
              {this.state.errorImage}
            </p>
          ) : (
            <Fragment />
          )}
          <h2>Mon profil</h2>
          <div className="change-image hide-on-mobile">
            <span>Changer</span>
            <img
              src={
                this.state.preview
                  ? this.state.preview
                  : this.state.user &&
                    this.state.user.profile &&
                    this.state.user.profile.image
                  ? this.state.user.profile.image.link
                  : Avatar
              }
              className="avatar"
              alt=""
            />
            <input
              type="file"
              accept="image/*"
              className="upload-input"
              onChange={this.fileChangedHandler}
            />
          </div>
          {this.state.errorImage ? (
            <p className="hide-on-mobile error-message">
              {this.state.errorImage}
            </p>
          ) : (
            <Fragment />
          )}
          <div className="form">
            <Formik
              onSubmit={this.handleFormSubmit}
              initialValues={{
                username: this.state.user && this.state.user.username,
                firstname:
                  this.state.user && this.state.user.profile
                    ? this.state.user.profile.first_name
                    : "",
                lastname:
                  this.state.user && this.state.user.profile
                    ? this.state.user.profile.last_name
                    : "",
                bio:
                  this.state.user &&
                  this.state.user.profile &&
                  this.state.user.profile.biography
                    ? this.state.user.profile.biography
                    : ""
              }}
              validationSchema={
                this.state.user && this.state.user.auth_provider === "password"
                  ? this.userSchemaStart
                  : this.userSchemaStartFB
              }
              validateOnChange={true}
            >
              {({ handleSubmit, errors, isSubmitting, actions }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    type="text"
                    placeholder=""
                    name="firstname"
                    className="round"
                    label="Nom"
                    hint=""
                    component={TextInput}
                  />
                  <ErrorMessage name="firstname" component={ComposantErreur} />
                  <Field
                    type="text"
                    placeholder=""
                    name="lastname"
                    className="round"
                    label="Prénom"
                    hint=""
                    component={TextInput}
                  />
                  <ErrorMessage name="lastname" component={ComposantErreur} />
                  <Field
                    type="text"
                    placeholder=""
                    name="bio"
                    className="round"
                    label="Bio"
                    hint=""
                    component={TextArea}
                  />
                  <ErrorMessage name="bio" component={ComposantErreur} />

                  <div className="town-choice">
                    <label className="label-ville">Ville ou résidence</label>
                    <AsyncPaginate
                      defaultValue={this.loadOptions[1]}
                      value={this.state.value}
                      loadOptions={this.loadOptions}
                      onChange={this.setValue}
                      placeholder="Choisissez votre ville"
                      additional={{
                        page: 1
                      }}
                      className="my-select"
                      styles={customStyles}
                      theme={theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "transparent",
                          neutral0: "transparent",
                          primary25: "hotpink",
                          neutral80: "transparent"
                        }
                      })}
                    />
                  </div>

                  <p className="slider-title">Mes sujets favoris</p>
                  <div className="fav-subjects">
                    {this.state.user && this.state.user.topics
                      ? this.state.user.topics.map((topic, index) => {
                          return (
                            <Tag
                              index={index}
                              color="primary"
                              background="#212529"
                              text={[
                                topic.name,
                                " ",
                                <img
                                  onClick={() => {
                                    this.deleteTopic(topic.id);
                                  }}
                                  src={Close}
                                  className="close-tag"
                                  alt=""
                                />
                              ]}
                              type="round"
                              className={"tag checked"}
                              checked={() => {}}
                            />
                          );
                        })
                      : ""}
                    <div onClick={this.openModal}>
                      <img src={Plus} alt="" />
                    </div>
                  </div>
                  <p className="slider-title">Mes identifiants</p>

                  <Field
                    type="text"
                    placeholder="Votre adresse email *"
                    name="username"
                    className="round"
                    label="Email"
                    hint=""
                    component={TextInput}
                  />
                  <ErrorMessage name="username" component={ComposantErreur} />
                  {this.state.user &&
                  this.state.user.auth_provider === "password" ? (
                    <Fragment>
                      <Field
                        type="password"
                        placeholder="Mot de passe *"
                        name="password"
                        className="round"
                        label=""
                        hint=""
                        component={TextInput}
                      />
                      <ErrorMessage
                        name="password"
                        component={ComposantErreur}
                      />
                    </Fragment>
                  ) : (
                    <Fragment />
                  )}
                  <p className="slider-title">Mes Abonnements</p>

                  <div className="text-left">
                    <CheckboxInput
                      name="checkbox1"
                      label="J'accepte de recevoir la newsletter hebdomadaire de Moi, Maire envoyée par Nice-Matin"
                      value={this.state.checkbox1}
                      handleChange={this.handleChange}
                    />
                    <CheckboxInput
                      name="checkbox2"
                      label="J’accepte de recevoir les offres commerciales de Nice-Matin"
                      value={this.state.checkbox2}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <button
                    type="submit"
                    className="button round text-primary bg-green round btn btn-4 btn-4c icon-arrow-right"
                  >
                    Mettre à jour
                  </button>
                </form>
              )}
            </Formik>

            <button
              onClick={this.handleLogout}
              type="button"
              className="button round text-white bg-dark round btn btn-4 btn-4c icon-arrow-right"
            >
              Déconnexion
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.modalIsOpenSubject}
          onRequestClose={this.closeModal}
          contentLabel=""
          className="modalNewProposition white-section"
        >
          <img
            onClick={this.closeModal}
            src={Close}
            className="close-modal"
            alt=""
          />
          <div className="padded-multilines">
            <h2>Quelles sont les priorités pour vous et votre ville ? </h2>
          </div>
          <div className="form">
            <div className="fav-subjects">
              {this.state.topics &&
                this.state.topics.map(topic => {
                  return (
                    <Tag
                      key={topic.id}
                      index={topic.id}
                      color="primary"
                      background={
                        this.state.chosenTopics &&
                        this.state.chosenTopics.indexOf(topic.id) > -1
                          ? topic.color
                          : "#212529"
                      }
                      text={topic.name}
                      type="round"
                      className={
                        this.state.chosenTopics &&
                        this.state.chosenTopics.indexOf(topic.id) > -1
                          ? "tag checked"
                          : "tag"
                      }
                      checked={this.handleTopics}
                    />
                  );
                })}
            </div>
          </div>

          <Button
            click={this.validateNewTopics}
            color="black"
            background="green"
            text="Valider"
            type="round"
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ users, topic, addproposal }) => {
  const { user, loading } = users;
  return { user, loading, topic, addproposal };
};

export default withRouter(
  connect(mapStateToProps, {
    logoutUser,
    getTopicsAsync,
    updateUserAsync,
    uploadMedia
  })(ProfileModal)
);
