import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MySlider from "../../components/slider";
import ClickScroll from "../../components/clickscroll";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SubjectsPropositions from "../../components/subjectspropositions";
import "react-tabs/style/react-tabs.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import hashGenerator from "../../helpers/hashGenerate";
import { Helmet } from "react-helmet";
import { animateScroll as scroll } from "react-scroll";

// Component for /home
class TopCandidats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia("(min-width: 768px)").matches,
      tabIndex: 0,
      tabIndex2: 0,
      disabled: true
    };
  }
  componentDidMount() {
    scroll.scrollToTop({ duration: 250, delay: 0 });
  }

  render() {
    var settingsTopPropositionSlider = {
      dots: false,
      arrows: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      className: "center",
      centerPadding: "5px",
      speed: 750,
      swipeToSlide: true,
      touchThreshold: 12,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: "unslick"
        }
      ]
    };
    const paramsTopWeek = {
      sort: {
        score: "desc"
      },
      filter: {
        date: "week",
        topics: [],
        towns: []
      }
    };
    const paramsTopAll = {
      sort: {
        score: "desc"
      },
      filter: {
        date: "all",
        topics: [],
        towns: []
      }
    };
    const paramsMostActif = {
      sort: {
        proposals: "desc"
      },
      filter: {
        topics: [],
        towns: []
      }
    };
    const paramsMostVoters = {
      sort: {
        votes: "desc"
      },
      filter: {
        date: "all",
        topics: [],
        towns: []
      }
    };

    const handler = e => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);

    return (
      <div className="home topproposition topcandidats">
        <Helmet>
          <title>{"Les meilleurs candidats pour les muncipales 2020"}</title>
          <meta
            name="description"
            content={
              "Consultez les meilleurs candidats du Var et des Alpes-Maritimes dans le cadre des Municipales 2020 avec Nice-Matin"
            }
          />
          <meta
            property="og:title"
            content={"Les meilleurs candidats pour les muncipales 2020"}
          />
          <meta
            property="og:description"
            content={
              "Consultez les meilleurs candidats du Var et des Alpes-Maritimes dans le cadre des Municipales 2020 avec Nice-Matin"
            }
          />
          <meta property="og:image" content="" />
          {/*<meta property="og:url" content="http://euro-travel-example.com/index.htm"></meta>*/}
        </Helmet>
        <div className="dark-section top-propositions-section">
          <h3 className="header-section">Top candidats</h3>
          <div className="hide-on-mobile">
            <Tabs
              selectedIndex={this.state.tabIndex}
              onSelect={tabIndex => this.setState({ tabIndex })}
            >
              <TabList>
                <Tab>CETTE SEMAINE</Tab>
                <Tab>DEPUIS LE DÉBUT</Tab>
              </TabList>
              <p className="slider-title">Classement général</p>
              <TabPanel>
                <MySlider
                  comp={
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15)
                  }
                  resetting={true}
                  getusers={true}
                  getproposals={false}
                  callOnClick={false}
                  settings={settingsTopPropositionSlider}
                  params={paramsTopWeek}
                />
              </TabPanel>
              <TabPanel>
                <MySlider
                  comp={
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15)
                  }
                  resetting={true}
                  getusers={true}
                  getproposals={false}
                  callOnClick={false}
                  settings={settingsTopPropositionSlider}
                  params={paramsTopAll}
                />
              </TabPanel>
            </Tabs>
          </div>
          <div className="hide-on-desktop">
            <Tabs
              selectedIndex={this.state.tabIndex2}
              onSelect={tabIndex => this.setState({ tabIndex2: tabIndex })}
            >
              <TabList>
                <Tab>CETTE SEMAINE</Tab>
                <Tab>DEPUIS LE DÉBUT</Tab>
              </TabList>
              <p className="slider-title">Classement général</p>
              <TabPanel>
                <ClickScroll
                  comp={hashGenerator()}
                  getproposals={false}
                  getusers={true}
                  callOnClick={true}
                  params={paramsTopWeek}
                />
              </TabPanel>
              <TabPanel>
                <ClickScroll
                  comp={hashGenerator()}
                  getproposals={false}
                  getusers={true}
                  callOnClick={true}
                  params={paramsTopAll}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <div className="white-section pad-top">
          <div className="best-propositions-section">
            <p className="slider-title">Les plus actifs</p>
            {this.state.matches ? (
              <MySlider
                comp={55}
                getusers={true}
                resetting={true}
                getproposals={false}
                callOnClick={false}
                settings={settingsTopPropositionSlider}
                params={paramsMostActif}
              />
            ) : (
              <div className="hide-on-desktop">
                <ClickScroll
                  comp={hashGenerator()}
                  getproposals={false}
                  getusers={true}
                  callOnClick={true}
                  params={paramsMostActif}
                />
              </div>
            )}
          </div>
          <div className="shared-propositions-section">
            <p className="slider-title">Les meilleurs votants</p>

            {this.state.matches ? (
              <MySlider
                comp={
                  Math.random()
                    .toString(36)
                    .substring(2, 15) +
                  Math.random()
                    .toString(36)
                    .substring(2, 15)
                }
                resetting={true}
                getusers={true}
                getproposals={false}
                callOnClick={false}
                settings={settingsTopPropositionSlider}
                params={paramsMostVoters}
              />
            ) : (
              <div className="hide-on-desktop">
                <ClickScroll
                  comp={hashGenerator()}
                  getproposals={false}
                  getusers={true}
                  callOnClick={true}
                  params={paramsMostVoters}
                />
              </div>
            )}
          </div>
        </div>
        <SubjectsPropositions />
      </div>
    );
  }
}

export default withRouter(TopCandidats);
