import { combineReducers } from "redux";
import settings from "./settings/reducer";
import authUser from "./auth/reducer";
import topic from "./topic/reducer";
import town from "./town/reducer";
import proposals from "./proposals/reducer";
import users from "./users/reducer";
import news from "./news/reducer";
import addproposal from "./addproposal/reducer";
import candidats from "./candidats/reducer";

const reducers = combineReducers({
  settings,
  authUser,
  town,
  topic,
  users,
  proposals,
  addproposal,
  news,
  candidats
});

export default reducers;
