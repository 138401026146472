import React, { Component, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import Nav from "../../../components/nav";
import TextInput from "../../../components/form/textInput";
import CheckboxInput from "../../../components/form/checkboxInput";
import {
  registerUserBasic,
  registerUserFromFacebook
} from "../../../redux/actions";
import { connect } from "react-redux";
import { animateScroll as scroll } from "react-scroll";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ComposantErreur = props => {
  return (
    <div className="error-message">
      <span>{props.children}</span>
    </div>
  );
};

// Component for /Geolocalisation
class InscriptionForm extends Component {
  constructor() {
    super();
    this.state = {
      form: null,
      checkbox1: true,
      checkbox2: false,
      checkbox3: false,
      isSubmitting: false
    };
  }

  handleChange = e => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value });
  };

  handleFormSubmit = async (values, actions) => {
    this.setState({ form: actions }, async () => {
      if (!this.state.checkbox3) {
        this.state.form.setFieldError(
          "general",
          "Merci d'accepter les conditions générales d'utilisation du dispositif"
        );
      } else {
        this.props.userProfile(values);
        let formState = this.props.state;
        if (formState.type === "basic") {
          let inscriptionCall = {
            username: values.email,
            password: values.password,
            profile: {
              firstName: values.firstname,
              lastName: values.lastname
            },
            subscription: {
              newsletter: this.state.checkbox1,
              offers: this.state.checkbox2
            },
            topics: formState.topics,
            town: formState.town
          };
          this.props.registerUserBasic(inscriptionCall);
        } else {
          let inscriptionCall = {
            username: formState.emailFB,
            profile: {
              firstName: formState.firstNameFB,
              lastName: formState.lastNameFB
            },
            subscription: {
              newsletter: false,
              offers: false
            },
            topics: formState.topics,
            town: formState.town,
            token: formState.token
          };
          await this.props.registerUserFromFacebook(inscriptionCall);
        }
      }
    });
  };

  userSchema = Yup.object().shape({
    firstname: Yup.string().required(" "),
    lastname: Yup.string().required(" "),
    email: Yup.string()
      .email("Email Invalide")
      .required(" "),
    password: Yup.string()
      .required(" ")
      .min(8, "8 caractères minimum"),
    confirmpassword: Yup.string()
      .required(" ")
      .oneOf(
        [Yup.ref("password"), null],
        "Les mots de passe saisis ne sont pas identiques."
      )
  });

  userSchemaFB = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required(" ")
  });

  componentDidMount() {
    if (
      !(this.props.location.state && this.props.location.state.inscription) &&
      !this.props.state.token &&
      !this.props.state.town
    ) {
      this.props.history.push("/auth/login", { response: false });
    }
    scroll.scrollToTop({ duration: 200, delay: 0 });
  }
  componentDidUpdate(newProps) {
    if (
      this.props.user &&
      this.props.user.token &&
      newProps.user !== this.props.user
    ) {
      this.props.history.push({
        pathname: "/finished"
      });
    } else if (this.props.user && newProps.user !== this.props.user) {
      if (this.props.state.type === "basic") {
        this.state.form.setFieldError(
          "email",
          this.props.user &&
            this.props.user.errors &&
            this.props.user.errors.username &&
            this.props.user.errors.username[0]
        );
      }
    }
  }

  render() {
    return (
      <div className="inscription-form">
        <Nav whiteVersion={true} arrow_left={true} logo={true} close={true} />
        <h1>Votre profil</h1>
        <div className="form">
          <Formik
            onSubmit={this.handleFormSubmit}
            initialValues={{
              firstname: "",
              lastname: "",
              email: "",
              password: ""
            }}
            validationSchema={
              this.props.state.type === "basic"
                ? this.userSchema
                : this.userSchemaFB
            }
            validateOnChange={true}
          >
            {({ handleSubmit, isSubmitting, errors, actions }) => (
              <form onSubmit={handleSubmit}>
                {this.props.state.type === "basic" ? (
                  <Fragment>
                    <Field
                      type="text"
                      placeholder="Votre nom"
                      name="firstname"
                      className="round"
                      label=""
                      errorClass={errors.firstname ? "errors" : ""}
                      hint="Sera visible par les autres candidats / votants"
                      component={TextInput}
                    />
                    <ErrorMessage
                      name="firstname"
                      component={ComposantErreur}
                    />
                    <Field
                      type="text"
                      placeholder="Votre prénom"
                      name="lastname"
                      className="round"
                      label=""
                      errorClass={errors.lastname ? "errors" : ""}
                      hint="Sera visible par les autres candidats / votants"
                      component={TextInput}
                    />
                    <ErrorMessage name="lastname" component={ComposantErreur} />
                  </Fragment>
                ) : (
                  <Fragment></Fragment>
                )}
                <Field
                  type="text"
                  placeholder="Votre adresse email *"
                  name="email"
                  className="round"
                  label=""
                  hint="Votre adresse email"
                  errorClass={errors.email ? "errors" : ""}
                  component={TextInput}
                />
                <ErrorMessage name="email" component={ComposantErreur} />

                {this.props.state.type === "basic" ? (
                  <Fragment>
                    <Field
                      type="password"
                      placeholder="Mot de passe *"
                      name="password"
                      errorClass={errors.password ? "errors" : ""}
                      className="round hidden"
                      label=""
                      hint="8 caractères minimum"
                      component={TextInput}
                    />
                    <ErrorMessage name="password" component={ComposantErreur} />
                    <Field
                      type="password"
                      placeholder="Confirmer le mot de passe *"
                      name="confirmpassword"
                      className="round hidden"
                      label=""
                      errorClass={errors.confirmpassword ? "errors" : ""}
                      hint=""
                      component={TextInput}
                    />
                    <ErrorMessage
                      name="confirmpassword"
                      component={ComposantErreur}
                    />
                  </Fragment>
                ) : (
                  <Fragment></Fragment>
                )}
                <div className="text-left">
                  <CheckboxInput
                    name="checkbox1"
                    label="J'accepte de recevoir la newsletter hebdomadaire de Moi, Maire envoyée par Nice-Matin"
                    value={this.state.checkbox1}
                    handleChange={this.handleChange}
                  />
                  <CheckboxInput
                    name="checkbox2"
                    label="J’accepte de recevoir les offres commerciales de Nice-Matin"
                    value={this.state.checkbox2}
                    handleChange={this.handleChange}
                  />
                  <CheckboxInput
                    name="checkbox3"
                    label="J’accepte les conditions générales d'utilisation du dispositif"
                    value={this.state.checkbox3}
                    handleChange={this.handleChange}
                  />
                </div>
                <Link
                  target="_blank"
                  to={{ pathname: "/confidentialite" }}
                  className="confidentialite"
                >
                  Consulter les conditions générales d'utilisation du dispositif
                </Link>
                {Object.keys(errors).length === 0 &&
                errors.constructor === Object ? (
                  <Fragment />
                ) : (
                  <div style={{ color: "red" }}>
                    Veuillez renseigner toutes les informations
                  </div>
                )}
                <div style={{ color: "red" }}>{errors.general}</div>
                <button
                  type="submit"
                  className="button round text-primary bg-green round  btn btn-4 btn-4c icon-arrow-right"
                  disabled={isSubmitting || this.state.isSubmitting}
                >
                  Je valide
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    registerUserBasic,
    registerUserFromFacebook
  })(InscriptionForm)
);
