import React, { Component } from "react";
import Button from "../../../components/button";
import { Link, withRouter } from "react-router-dom";
import Nav from "../../../components/nav";
import AddPropositionModal from "../../../components/addpropositionmodal";
// Component for /Geolocalisation
class InscriptionCompleted extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    return (
      <div className="inscription-completed">
        <Nav arrow_left={true} logo={true} close={true} />
        <AddPropositionModal
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
        />
        <div className="inscription-completed-content">
          <div>
            <h1>Félicitations !</h1>
            <p>
              Vous pouvez maintenant déposer des propositions pour votre ville
              et voter pour vos préférées
            </p>
          </div>
          <div>
            <h3>Par quoi voulez-vous commencer ?</h3>

            <Link onClick={this.openModal}>
              <Button
                color="primary"
                background="green"
                text="Je dépose ma première proposition"
                type="round"
              />
            </Link>
            <Link to={{ pathname: "/" }}>
              <Button
                color="white"
                background="transparent"
                text="Je découvre les propositions"
                type="round"
              />
            </Link>
          </div>
        </div>
        <Link to={{ pathname: "/" }} className="invitation">
          Les municipales c’est important ! Invitez vos amis à découvrir “Moi,
          Maire”
        </Link>
      </div>
    );
  }
}

export default withRouter(InscriptionCompleted);
