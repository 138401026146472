import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as store from "../redux/store";
export const PrivateRoute = ({ component: Component, noHeader, ...rest }) => {
  let isAuth =
    store.loadState() &&
    store.loadState().authUser &&
    store.loadState().authUser.user &&
    store.loadState().authUser.user.token;
  return (
    <Route
      {...rest}
      render={props =>
        isAuth ? (
          <Component {...props} {...rest} noHeader={noHeader} />
        ) : (
          <Redirect
            to={{
              pathname:
                rest.routeProps && rest.routeProps.auth_redirect_path
                  ? rest.routeProps.auth_redirect_path
                  : "/auth/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};
