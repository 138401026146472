import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import "./slider.scss";
import Card from "../card";
import Loader from "../loader";
import Candidat from "../candidat";
import Slider from "react-slick";
import { animateScroll as scroll } from "react-scroll";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  getProposalsAsync,
  getUsersAsync,
  listProposalReset,
  listUsersReset
} from "../../redux/actions";
import { connect } from "react-redux";
import { TinderLikeCard } from "react-stack-cards";

class MySlider extends Component {
  constructor() {
    super();
    this.state = {
      users: [],
      proposals: [],
      page: 1,
      loading: true,
      comp: 0,
      matches: window.matchMedia("(min-width: 768px)").matches,
      changes: 0,
      swiping: 0,
      settings: {},
      start: true,
      loadMore: true
    };
    this.Tinder = null;
  }

  componentDidMount() {
    this.setState({ settings: this.props.settings });
    this.setState({ comp: this.props.comp }, () => {
      if (this.props.getproposals) {
        if (this.props.resetting) {
          this.props.getProposalsAsync(
            this.props.params,
            this.state.page,
            this.state.comp,
            false,
            10
          );
        } else {
          this.props.listProposalReset(this.props.comp);
        }
      }
      if (this.props.getusers) {
        if (this.props.resetting) {
          this.props.getUsersAsync(
            this.props.params,
            this.state.page,
            this.state.comp
          );
        } else {
          this.props.listUsersReset(this.props.comp);
        }
      }
    });
  }

  componentDidUpdate(newProps) {
    if (this.Tinder && this.state.start) {
      var boxes = document.getElementsByClassName("iii");
      if (boxes[2]) {
        // Select first box and style it with red background
        var boxHeight = boxes[2].offsetHeight + 100;
        var tinder = document.getElementsByClassName("tinder");
        var tinderHeight = tinder[0];
        tinderHeight.style.height = boxHeight + "px";
        this.setState({ start: false });
      }
    }
    if (this.props.getproposals) {
      if (this.props.proposals !== newProps.proposals) {
        if (
          newProps.proposals.loading !== this.props.proposals.loading &&
          this.state.changes < 2
        ) {
          if (
            this.state.comp === this.props.proposals.comp ||
            (this.state.comp === this.props.proposals.proposals &&
              this.props.proposals.proposals.comp)
          ) {
            this.setState({ loading: this.props.proposals.loading });
          }
          this.setState({ changes: 0 });
        } else if (this.state.changes < 2) {
          this.setState({ loading: this.props.proposals.loading });
          this.setState({ changes: 0 });
        }
      }
    }
    if (this.props.getusers) {
      if (this.props.users !== newProps.users) {
        if (
          newProps.users.loading !== this.props.users.loading &&
          this.state.changes < 2
        ) {
          if (
            this.state.comp === this.props.users.comp ||
            (this.props.users.users && this.props.users.users.comp)
          ) {
            this.setState({ loading: this.props.users.loading });
            this.setState({ changes: 0 });
          }
        }
      }
    }
    if (this.props.getproposals) {
      if (this.props.proposals !== newProps.proposals) {
        if (newProps.proposals.reset !== this.props.proposals.reset) {
          if (this.props.proposals.reset) {
            this.props.getProposalsAsync(
              this.props.params,
              this.state.page,
              this.state.comp,
              false,
              10
            );
          }
        }
      }
    }
    if (this.props.getusers) {
      if (this.props.users !== newProps.users) {
        if (newProps.users.reset !== this.props.users.reset) {
          if (this.props.users.reset) {
            this.props.getUsersAsync(
              this.props.params,
              this.state.page,
              this.state.comp
            );
          }
        }
      }
    }

    if (this.props.proposals !== newProps.proposals) {
      if (this.props.proposals.proposals !== newProps.proposals.proposals) {
        if (
          this.props.getproposals &&
          this.props.proposals &&
          this.props.proposals.proposals &&
          this.props.proposals.proposals.proposals
        ) {
          if (this.state.comp === this.props.proposals.proposals.comp) {
            if (this.props.filter) {
              let allProposals = [
                [],
                this.props.proposals &&
                  this.props.proposals.proposals &&
                  this.props.proposals.proposals.proposals
              ];
              let comb = allProposals.reduce((a, b) => [...a, ...b], []);
              this.setState({ proposals: comb }, () => {
                this.setState({
                  loadMore:
                    this.props.proposals.proposals &&
                    this.props.proposals.proposals.hasMore
                });
              });
            } else {
              let allProposals = [
                this.state.proposals,
                this.props.proposals &&
                  this.props.proposals.proposals &&
                  this.props.proposals.proposals.proposals
              ];
              let comb = allProposals.reduce((a, b) => [...a, ...b], []);
              this.setState({ proposals: comb }, () => {
                this.setState({
                  loadMore:
                    this.props.proposals.proposals &&
                    this.props.proposals.proposals.hasMore
                });
              });
            }
            if (this.props.totalItems) {
              this.props.totalItems(this.props.proposals.proposals.totalItems);
            }
          }
        }
      }
    }
    if (this.props.users !== newProps.users) {
      if (this.props.users.users !== newProps.users.users) {
        if (
          this.props.getusers &&
          this.props.users &&
          this.props.users.users &&
          this.props.users.users.users
        ) {
          if (this.state.comp === this.props.users.users.comp) {
            if (
              JSON.stringify(this.state.users) !==
              JSON.stringify(this.props.users.users.users)
            ) {
              let allProposals = [
                this.state.users,
                this.props.users &&
                  this.props.users.users &&
                  this.props.users.users.users
              ];
              let comb = allProposals.reduce((a, b) => [...a, ...b], []);
              this.setState({ users: comb }, () => {
                this.setState({
                  loadMore:
                    this.props.users.users && this.props.users.users.hasMore
                });
              });
            }
          }
        }
      }
    }
  }

  addMoreProposals = (current, next) => {
    if (!this.props.dynamique) {
      this.setState({ changes: this.state.changes + 1 }, () => {
        if (this.state.loadMore && this.state.changes > 3 && current > 3) {
          if (this.props.getproposals) {
            this.setState({ page: this.state.page + 1 }, () => {
              this.props.getProposalsAsync(
                this.props.params,
                this.state.page,
                this.state.comp,
                10
              );
            });
          }
          if (this.props.getusers) {
            this.setState({ page: this.state.page + 1 }, () => {
              this.props.getUsersAsync(
                this.props.params,
                this.state.page,
                this.state.comp
              );
            });
          }
        }
      });
    }
  };

  next = () => {
    if (this.slider) {
      this.slider.slickNext();
    }
  };
  previous = () => {
    if (this.slider) {
      this.slider.slickPrev();
    }
  };

  onTinderSwipe = () => {
    if (this.Tinder) {
      var boxes = document.getElementsByClassName("iii");
      // Select first box and style it with red background
      var boxHeight = boxes[1].offsetHeight + 100;

      var tinder = document.getElementsByClassName("tinder");
      var tinderHeight = tinder[0];
      tinderHeight.style.height = boxHeight + "px";
      /*setTimeout(
        function() {
          if (boxes[2]) {
            if (this.state.swiping === 8) {
              boxes[1].parentElement.style.zIndex = this.state.swiping + 5;
            } else {
              boxes[2].parentElement.style.zIndex = this.state.swiping + 5;
            }
          }
        }.bind(this),
        100
      );*/
      this.setState({ swiping: this.state.swiping + 1 }, () => {
        if (
          this.state.swiping > 9 ||
          this.state.swiping === this.state.proposals.length
        ) {
          scroll.scrollToTop({ duration: 250, delay: 0 });
          this.props.totalItems(0);
        }
      });
      this.Tinder.swipe();
    }
  };

  render() {
    const proposals = this.state.proposals
      ? this.state.proposals.map((proposal, i) => {
          return (
            <div key={i}>
              <Card
                click={this.props.click}
                type={this.props.bigCard ? "" : "small"}
                swipe={this.onTinderSwipe}
                next={this.next}
                previous={this.previous}
                blur={
                  this.state.proposals.length < 2
                    ? { filter: "none", opacity: "1" }
                    : {}
                }
                index={i + 1}
                total={this.state.proposals.length}
                proposal={proposal}
              />
            </div>
          );
        })
      : "";
    let users = this.state.users
      ? this.state.users.map((user, i) => {
          return (
            <div key={i}>
              <Candidat user={user} index={i + 1} />
            </div>
          );
        })
      : "";

    // Slider display bug (slick slider) when number of elements < slides to show with infinite loop = true
    var infiniteProposal = proposals.length > 3 ? true : false;
    var infiniteUser = users.length > 3 ? true : false;
    var showProposal = proposals.length === 4 ? 3 : 4;
    users = users.length === 4 ? (users = [...users, ...users]) : users;
    showProposal = this.props.modal ? 1 : showProposal;
    const handler = e => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
    return (
      <div>
        {this.state.loading ? <Loader /> : ""}
        {proposals !== [] && proposals.length > 0 && (
          <div>
            {this.props.bigCard && !this.state.matches ? (
              <Fragment>
                <TinderLikeCard
                  images={proposals}
                  direction="swipeCornerDownRight"
                  width="auto"
                  height="240"
                  duration={400}
                  ref={node => (this.Tinder = node)}
                  className="tinder"
                >
                  {proposals.map((i, index) => (
                    <div key={index} className="iii">{i}</div>
                  ))}
                </TinderLikeCard>
              </Fragment>
            ) : (
              <Slider
                ref={c => (this.slider = c)}
                key={this.state.comp}
                {...{
                  ...this.props.settings,
                  afterChange: this.addMoreProposals,
                  slidesToShow: showProposal,
                  infinite: infiniteProposal
                }}
              >
                {proposals}
              </Slider>
            )}
          </div>
        )}
        {users !== [] && users.length > 0 && (
          <div>
            <Slider
              {...{
                ...this.props.settings,
                afterChange: this.addMoreProposals,
                infinite: infiniteUser
              }}
            >
              {users}
            </Slider>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ proposals, users }) => {
  return { proposals, users };
};

export default withRouter(
  connect(mapStateToProps, {
    getProposalsAsync,
    listProposalReset,
    listUsersReset,
    getUsersAsync
  })(MySlider)
);
