import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./candidat.scss";
import Avatar from "../../assets/images/avatar.png";

class Candidat extends Component {
  handleOnMouseDown(e) {
    this.setState({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY
    });
    e.preventDefault(); // stops weird link dragging effect
  }

  handleOnClick(e) {
    e.stopPropagation();
    if (
      this.state.clientXonMouseDown !== e.clientX ||
      this.state.clientYonMouseDown !== e.clientY
    ) {
      // prevent link click if the element was dragged
      e.preventDefault();
    } else {
      this.setState({ modalIsOpen: !this.state.modalIsOpen }, () => {});
    }
  }

  render() {
    let user = this.props.user;
    let candidat = this.props.candidat;
    let id = user ? user.id : 3;
    return user ? (
      <Link
        className="candidat-link"
        to={{ pathname: "/candidat/" + id }}
        onMouseDown={e => this.handleOnMouseDown(e)}
        onClick={e => this.handleOnClick(e)}
      >
        <div className="candidat">
          <div className="candidat-header">
            <span>
              {this.props.index}
              <span className="sub-header">
                {this.props.index > 1 ? "eme" : "er"}
              </span>
            </span>
          </div>
          <div className="candidat-details">
            <img
              src={
                user && user.profile && user.profile.image
                  ? user.profile.image.link
                  : Avatar
              }
              alt=""
            />
            <div className="details">
              <h2 className="title">
                {user && user.profile ? user.profile.full_name : "Moi maire"}
              </h2>
              <p className="description">
                <span className="number">
                  {user && user.metrics ? user.metrics.score : "0"}
                </span>{" "}
                {user && user.metrics
                  ? user.metrics.score > 1
                    ? "points civiques"
                    : "point civique"
                  : "point civique"}
              </p>
            </div>
          </div>
        </div>
      </Link>
    ) : (
      <div
        className="candidat-link"
        onMouseDown={e => this.handleOnMouseDown(e)}
        onClick={e => this.handleOnClick(e)}
      >
        <div className="candidat">
          <div className="candidat-header">
            <span>{candidat.label}</span>
          </div>
          <div className="candidat-details ville-candidat">
            <img
              src={
                candidat && candidat.image
                  ? candidat.image
                  : candidat.remote_image_url
                  ? candidat.remote_image_url
                  : Avatar
              }
              alt=""
            />
            <div className="details">
              <h2 className="title">
                {candidat
                  ? candidat.first_name + " " + candidat.last_name
                  : "Moi maire"}
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Candidat);
