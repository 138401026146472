import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import "./infinitescroll.scss";
import {
  getProposalsAsync,
  getUsersAsync,
  getCandidatsAsync
} from "../../redux/actions";
import { connect } from "react-redux";
import InfiniteScrollExtend from "./InfiniteScrollExtend";
import Card from "../card";
import Loader from "../loader";
import Candidat from "../candidat";

class InfiniteScroll extends Component {
  constructor() {
    super();
    this.state = {
      proposals: [],
      users: [],
      candidats: [],
      loadMore: true,
      comp: 0,
      loading: true,
      page: 0
    };
  }
  componentDidMount() {
    this.props.getCandidatsAsync(this.props.params, 1, this.state.comp, true);
  }
  componentDidUpdate(newProps) {
    if (this.props.getproposals) {
      if (this.props.proposals !== newProps.proposals) {
        if (newProps.proposals.loading !== this.props.proposals.loading) {
          this.setState({ loading: this.props.proposals.loading });
        }
      }
    }
    if (this.props.getusers) {
      if (this.props.users !== newProps.users) {
        if (newProps.users.loading !== this.props.users.loading) {
          this.setState({ loading: this.props.users.loading });
        }
      }
    }
    if (this.props.getcandidats) {
      if (this.props.candidats !== newProps.candidats) {
        if (newProps.candidats.loading !== this.props.candidats.loading) {
          this.setState({ loading: this.props.candidats.loading });
        }
      }
    }
    if (this.props.resetting !== newProps.resetting && this.props.resetting) {
      this.setState(
        { proposals: [], page: this.state.page === 0 ? -1 : 0 },
        () => {
          let proposals = this.props.proposals;
          if (this.props.proposals !== newProps.proposals) {
            if (
              this.props.proposals.proposals !== newProps.proposals.proposals
            ) {
              if (
                this.props.getproposals &&
                this.props.proposals &&
                this.props.proposals.proposals &&
                this.props.proposals.proposals.proposals
              ) {
                if (this.state.comp === this.props.proposals.proposals.comp) {
                  if (this.props.resetting) {
                    this.setState(
                      {
                        proposals:
                          this.props.proposals &&
                          this.props.proposals.proposals &&
                          this.props.proposals.proposals.proposals
                      },
                      () => {
                        if (this.props.totalItems) {
                          this.props.totalItems(
                            this.props.proposals.proposals.totalItems
                          );
                        }
                        this.setState({
                          loadMore:
                            this.props.proposals.proposals &&
                            this.props.proposals.proposals.hasMore
                        });
                      }
                    );
                  } else {
                    let allProposals = [
                      this.state.proposals,
                      this.props.proposals &&
                        this.props.proposals.proposals &&
                        this.props.proposals.proposals.proposals
                    ];
                    let comb = allProposals.reduce((a, b) => [...a, ...b], []);
                    this.setState({ proposals: comb }, () => {
                      if (this.props.totalItems) {
                        this.props.totalItems(
                          this.props.proposals.proposals.totalItems
                        );
                      }
                      this.setState({
                        loadMore:
                          this.props.proposals.proposals &&
                          proposals.proposals.hasMore
                      });
                    });
                  }
                }
              }
            }
          }
        }
      );
    } else {
      let proposals = this.props.proposals;
      if (this.props.params !== newProps.params) {
        this.setState({ page: this.state.page === 0 ? -1 : 0 });
        if (this.props.getproposals) {
          this.props.getProposalsAsync(
            this.props.params,
            1,
            this.state.comp,
            true
          );
        } else if (this.props.getusers) {
          this.props.getUsersAsync(this.props.params, 1, this.state.comp, true);
        } else if (this.props.getcandidats) {
          this.setState({ candidats: [] });
          this.props.getCandidatsAsync(
            this.props.params,
            1,
            this.state.comp,
            true
          );
        }
      } else if (
        this.props.params === newProps.params &&
        this.props.params.page > 0 &&
        this.state.page === -1
      ) {
        this.setState({ page: this.state.page === 0 ? -1 : 0 });
        if (this.props.getproposals) {
          this.props.getProposalsAsync(
            this.props.params,
            1,
            this.state.comp,
            true
          );
        }
      }

      if (this.props.proposals !== newProps.proposals) {
        if (this.props.proposals.proposals !== newProps.proposals.proposals) {
          if (
            this.props.getproposals &&
            this.props.proposals &&
            this.props.proposals.proposals &&
            this.props.proposals.proposals.proposals
          ) {
            if (this.state.comp === this.props.proposals.proposals.comp) {
              if (this.props.filter && this.props.params.page < 2) {
                this.setState(
                  {
                    proposals:
                      this.props.proposals &&
                      this.props.proposals.proposals &&
                      this.props.proposals.proposals.proposals
                  },
                  () => {
                    if (this.props.totalItems) {
                      this.props.totalItems(
                        this.props.proposals.proposals.totalItems
                      );
                    }
                    this.setState({
                      loadMore:
                        this.props.proposals.proposals &&
                        this.props.proposals.proposals.hasMore
                    });
                  }
                );
              } else {
                if (
                  JSON.stringify(this.state.proposals) !==
                  JSON.stringify(this.props.proposals.proposals.proposals)
                ) {
                  let allProposals = [
                    this.state.proposals,
                    this.props.proposals &&
                      this.props.proposals.proposals &&
                      this.props.proposals.proposals.proposals
                  ];
                  let comb = allProposals.reduce((a, b) => [...a, ...b], []);
                  this.setState({ proposals: comb }, () => {
                    if (this.props.totalItems) {
                      this.props.totalItems(
                        this.props.proposals.proposals.totalItems
                      );
                    }
                    this.setState({
                      loadMore:
                        this.props.proposals.proposals &&
                        proposals.proposals.hasMore
                    });
                  });
                }
              }
            }
          }
        }
      }
    }

    if (this.props.users !== newProps.users) {
      if (this.props.users.users !== newProps.users.users) {
        if (
          this.props.getusers &&
          this.props.users &&
          this.props.users.users &&
          this.props.users.users.users
        ) {
          if (this.state.comp === this.props.users.users.comp) {
            if (this.props.filter) {
              this.setState(
                {
                  users:
                    this.props.users &&
                    this.props.users.users &&
                    this.props.users.users.users
                },
                () => {
                  this.setState({
                    loadMore:
                      this.props.users.users && this.props.users.users.hasMore
                  });
                }
              );
            } else {
              let allProposals = [
                this.state.users,
                this.props.users &&
                  this.props.users.users &&
                  this.props.users.users.users
              ];
              let comb = allProposals.reduce((a, b) => [...a, ...b], []);
              this.setState({ users: comb }, () => {
                this.setState({
                  loadMore:
                    this.props.users.users && this.props.users.users.hasMore
                });
              });
            }
          }
        }
      }
    }

    if (this.props.candidats !== newProps.candidats) {
      if (this.props.candidats.candidats !== newProps.candidats.candidats) {
        if (
          this.props.getcandidats &&
          this.props.candidats &&
          this.props.candidats.candidats &&
          this.props.candidats.candidats.candidats
        ) {
          if (this.state.comp === this.props.candidats.candidats.comp) {
            if (
              JSON.stringify(this.state.candidats) !==
              JSON.stringify(this.props.candidats.candidats.candidats)
            ) {
              let allProposals = [
                this.state.candidats,
                this.props.candidats &&
                  this.props.candidats.candidats &&
                  this.props.candidats.candidats.candidats
              ];
              let comb = allProposals.reduce((a, b) => [...a, ...b], []);
              this.setState({ candidats: comb }, () => {
                this.setState({
                  loadMore:
                    this.props.candidats.candidats &&
                    this.props.candidats.candidats.hasMore
                });
              });
            }
          }
        }
      }
    }
  }

  addMoreProposals = page => {
    if (this.state.loadMore) {
      if (this.props.getproposals) {
        this.props.getProposalsAsync(
          this.props.params,
          page,
          this.state.comp,
          true
        );
      } else if (this.props.getusers) {
        this.props.getUsersAsync(
          this.props.params,
          page,
          this.state.comp,
          true
        );
      } else if (this.props.getcandidats) {
        this.props.getCandidatsAsync(
          this.props.params,
          page,
          this.state.comp,
          true
        );
      }
    }
  };

  render() {
    return (
      <Fragment>
        {this.props.getproposals ? (
          <InfiniteScrollExtend
            pageStart={this.state.page}
            loadMore={page => {
              this.addMoreProposals(page);
              // stop page count until some outside force (componentDidUpdate)
              this.setState({ loadMore: false });
            }}
            hasMore={this.state.loadMore}
            loader={<Loader key={0} black={true} />}
          >
            {this.state.proposals.map((proposal, i) => {
              return (
                <div className="col-md-4" key={i}>
                  <Card
                    type={this.props.bigCard ? "" : "small"}
                    proposal={proposal}
                  />
                </div>
              );
            })}
            {(this.props.proposals &&
              this.props.proposals.proposals &&
              this.props.proposals.proposals.totalItems >
                this.state.proposals.length) ||
            (!this.props.proposals.proposals && this.state.loading) ? (
              <Loader key={0} black={true} />
            ) : (
              ""
            )}
          </InfiniteScrollExtend>
        ) : (
          ""
        )}

        {this.props.getusers ? (
          <InfiniteScrollExtend
            pageStart={this.state.page}
            loadMore={page => {
              this.addMoreProposals(page);
              // stop page count until some outside force (componentDidUpdate)
              this.setState({ loadMore: false });
            }}
            hasMore={this.state.loadMore}
            loader={<Loader key={0} black={true} />}
          >
            {this.state.users
              ? this.state.users.map((user, i) => {
                  return (
                    <div key={i} className="col-md-4">
                      <Candidat user={user} index={i + 1} />
                    </div>
                  );
                })
              : ""}
            {this.state.loading ? <Loader black={true} /> : ""}
          </InfiniteScrollExtend>
        ) : (
          ""
        )}

        {this.props.getcandidats ? (
          <InfiniteScrollExtend
            pageStart={this.state.page}
            loadMore={page => {
              this.addMoreProposals(page);
              // stop page count until some outside force (componentDidUpdate)
              this.setState({ loadMore: false });
            }}
            hasMore={this.state.loadMore}
            loader={<Loader key={0} black={true} />}
          >
            {this.state.candidats
              ? this.state.candidats.map((user, i) => {
                  return (
                    <div key={i} className="col-md-3">
                      <Candidat candidat={user} index={i + 1} />
                    </div>
                  );
                })
              : ""}
            {this.state.loading ? <Loader black={true} /> : ""}
          </InfiniteScrollExtend>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ proposals, users, candidats }) => {
  return { proposals, users, candidats };
};

export default withRouter(
  connect(mapStateToProps, {
    getProposalsAsync,
    getUsersAsync,
    getCandidatsAsync
  })(InfiniteScroll)
);
