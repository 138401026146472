import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Nav from "../../../components/nav";
import { connect } from "react-redux";
import TextInput from "../../../components/form/textInput";
import { resetPassUserBasic } from "../../../redux/actions";
import { animateScroll as scroll } from "react-scroll";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ComposantErreur = props => {
  return (
    <div className="error-message">
      <span>{props.children}</span>
    </div>
  );
};

// Component for /Geolocalisation
class Forgot extends Component {
  constructor() {
    super();
    this.state = {
      form: null,
      token: null,
      isSubmitting: false,
      successMsg: false,
      errorMsg: false
    };
  }

  componentDidMount() {
    scroll.scrollToTop({ duration: 250, delay: 0 });
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("token");
    this.setState({ token });
  }

  handleFormSubmit = async (values, actions) => {
    await this.props.resetPassUserBasic({
      password: values.password,
      token: this.state.token
    });
  };

  componentDidUpdate(newProps) {
    if (this.props.user !== newProps.user) {
      if (
        (this.props.user && this.props.user.status === 200) ||
        this.props.user.status === 204
      ) {
        this.setState({ successMsg: true });
      } else {
        this.setState({ errorMsg: true });
      }
    }
  }

  userSchema = Yup.object().shape({
    password: Yup.string()
      .required(" ")
      .min(8, "8 caractères minimum"),
    confirmpassword: Yup.string()
      .required(" ")
      .oneOf(
        [Yup.ref("password"), null],
        "Les mots de passe saisis ne sont pas identiques."
      )
  });

  render() {
    return (
      <div className="connect-form forgot">
        <Nav whiteVersion={true} arrow_left={true} logo={true} close={true} />
        <h1>Réinitialiser votre mot de passe</h1>
        <div className="form">
          <Formik
            onSubmit={this.handleFormSubmit}
            initialValues={{ username: "", password: "" }}
            validationSchema={this.userSchema}
            validateOnChange={true}
          >
            {({ handleSubmit, isSubmitting, actions, errors }) => (
              <form onSubmit={handleSubmit}>
                <Fragment>
                  <Field
                    type="password"
                    placeholder="Mot de passe *"
                    name="password"
                    errorClass={errors.password ? "errors" : ""}
                    className="round hidden"
                    label=""
                    hint="8 caractères minimum"
                    component={TextInput}
                  />
                  <ErrorMessage name="password" component={ComposantErreur} />
                  <Field
                    type="password"
                    placeholder="Confirmer le mot de passe *"
                    name="confirmpassword"
                    className="round hidden"
                    label=""
                    errorClass={errors.confirmpassword ? "errors" : ""}
                    hint=""
                    component={TextInput}
                  />
                  <ErrorMessage
                    name="confirmpassword"
                    component={ComposantErreur}
                  />
                </Fragment>
                {this.state.successMsg ? (
                  <div className="success-message">
                    Votre mot de passe à été changé avec succès
                  </div>
                ) : (
                  ""
                )}

                {this.state.errorMsg ? (
                  <div className="error">Un erreur est survenue</div>
                ) : (
                  ""
                )}
                <button
                  type="submit"
                  className="button round text-primary bg-green round btn btn-4 btn-4c icon-arrow-right"
                  disabled={isSubmitting || this.state.isSubmitting}
                >
                  Envoyer
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    resetPassUserBasic
  })(Forgot)
);
