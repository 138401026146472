import {
	PROPOSAL_ONE,
	PROPOSAL_ONE_SUCCESS,
	PROPOSAL_ONE_ERROR,
	PROPOSAL_LIST,
	PROPOSAL_LIST_SUCCESS,
	PROPOSAL_LIST_RESET,
	PROPOSAL_LIST_ERROR,
	VOTE_PROPOSAL_SUCCESS
} from "../../constants/actionTypes";

export const getProposal = (proposal) => ({
	type: PROPOSAL_ONE,
	payload: { proposal }
});
export const getProposalSuccess = proposal => ({
	type: PROPOSAL_ONE_SUCCESS,
	payload: proposal
});
export const getProposalError = proposal => ({
	type: PROPOSAL_ONE_ERROR,
	payload: proposal
});

export const getVoteSuccess = vote => ({
	type: VOTE_PROPOSAL_SUCCESS,
	payload: vote
});

export const listProposal = comp => ({
	type: PROPOSAL_LIST,
	payload: comp
});
export const listProposalSuccess = proposal => ({
	type: PROPOSAL_LIST_SUCCESS,
	payload: proposal
});
export const listProposalError = proposal => ({
	type: PROPOSAL_LIST_ERROR,
	payload: proposal
});
export const listProposalReset = proposal => ({
	type: PROPOSAL_LIST_RESET,
	payload: proposal
});
