import React, { Component, Fragment } from "react";
import Nav from "../../../components/nav";
import { Link } from "react-router-dom";
import Logo from "../../../components/logo";
import Button from "../../../components/button";
import ButtonFacebook from "../../../components/buttonFacebook";
import { withRouter } from "react-router-dom";

// Component for /login
class Login extends Component {
  render() {
    return (
      <Fragment>
        <div className="login">
          <Nav arrow_left={false} logo={false} close={true} />
          <Logo className="col-sm-12" />
          <div className="buttons">
            <Link to={{ pathname: "/auth/register", state: { type: "basic" } }}>
              <Button
                color="primary"
                background="green"
                text="Je m’inscris"
                type="round"
              />
            </Link>
            <p>Ou</p>
            <Link to="/auth/connect">
              <Button
                color="white"
                background="transparent"
                text="Je me connecte"
                type="round"
              />
            </Link>
            <ButtonFacebook />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Login);
