import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import "./subjectspropositions.scss";
import Tag from "../tag";
import Button from "../button";
import TextInput from "../form/textarea";
import AddPropositionModal from "../addpropositionmodal";
import Add from "../../assets/images/light_new_proposal.svg";
import { connect } from "react-redux";
import { getTopicsAsync, getUserAsync } from "../../redux/actions";
import { animateScroll as scroll } from "react-scroll";
import LogoMM_N from "../../assets/images/NM_BLANC.png";
import LogoMM_V from "../../assets/images/VM_BLANC.png";

class SubjectsPropositions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      modalIsOpen: false,
      topics: [],
      placeholder: "Écrivez ici votre proposition"
    };
  }

  init(textBtn) {
    var observe;
    if (window.attachEvent) {
      observe = function(element, event, handler) {
        if (element) {
          element.attachEvent("on" + event, handler);
        }
      };
    } else {
      observe = function(element, event, handler) {
        if (element) {
          element.addEventListener(event, handler, false);
        }
      };
    }
    var text = document.getElementById(textBtn);
    if (text) {
      function resize() {
        text.style.height = "auto";
        var x = text.scrollHeight + 20;
        text.style.height = x + "px";
      }
      /* 0-timeout to get the already changed text */
      function delayedResize() {
        window.setTimeout(resize, 0);
      }
      observe(text, "change", resize);
      observe(text, "cut", delayedResize);
      observe(text, "paste", delayedResize);
      observe(text, "drop", delayedResize);
      observe(text, "keydown", delayedResize);

      resize();
    }
  }
  componentDidMount() {
    this.props.getTopicsAsync();
    this.props.getUserAsync();

    this.init("new-proposal");
  }

  componentDidUpdate(newProps) {
    this.init("new-proposal");
    if (newProps.topic !== this.props.topic) {
      this.setState({ topics: Object.values(this.props.topic.topics) });
    }
  }
  handleOnClick = topic => {};

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleTitle = e => {
    this.setState({ title: e.target.value });
  };

  handleFocus = e => {
    this.setState({ placeholder: "" });
  };

  handleBlur = e => {
    this.setState({ placeholder: "Écrivez ici votre proposition" });
  };

  render() {
    return (
      <div className="dark-section footer-subjects">
        <AddPropositionModal
          modalIsOpen={this.state.modalIsOpen}
          title={this.state.title}
          closeModal={this.closeModal}
        />
        <TextInput
          id="new-proposal"
          type="text"
          handleBlur={this.handleBlur}
          handleFocus={this.handleFocus}
          placeholder={this.state.placeholder}
          handleChange={this.handleTitle}
          value={this.state.title}
          closing={this.openModal}
          imagePlaceholder={Add}
          rows="1"
          name="username"
          className="button round proposition bg-white text-primary"
          field=""
          form={{ touched: true }}
        />
        <div className="subjects-choice">
          <h2 className="header-section">Les propositions par thématique</h2>
          <div className="subjects">
            {this.state.topics.map(topic => {
              return (
                <Link
                  key={topic.id}
                  to={{ pathname: "/propositions/" + topic.id }}
                  onClick={() => {
                    scroll.scrollToTop({ duration: 380, delay: 0 });
                  }}
                >
                  <Tag
                    index={topic.id}
                    color="white"
                    background="#fff"
                    text={topic.name}
                    checked={() => {
                      this.handleOnClick(topic.id);
                    }}
                    type="round"
                  />
                </Link>
              );
            })}
          </div>
          <Link to={{ pathname: "/propositions" }}>
            <Button
              color="black"
              background="green"
              text="Voir toutes les propositions"
              type="round"
            />
          </Link>
        </div>
        <div className="copyright">
          <Link to={{ pathname: "/confidentialite" }}>
            Conditions générales d'utilisation
          </Link>

          <a
            href={"//www.nicematin.com/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="header-logo" src={LogoMM_N} alt="" />
          </a>

          <a
            href={"//www.varmatin.com/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="header-logo" src={LogoMM_V} alt="" />
          </a>

          <a
            href="https://www.cosavostra.com/?utm_source=moi-maire&utm_medium=website&utm_campaign=municipales"
            rel="noopener noreferrer"
            target="_blank"
          >
            Made with{" "}
            <span role="img" aria-label="love">
              ❤️{" "}
            </span>
            by CosaVostra
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ topic, users }) => {
  const { user } = users;
  return { topic, user };
};

export default withRouter(
  connect(mapStateToProps, {
    getUserAsync,
    getTopicsAsync
  })(SubjectsPropositions)
);
