import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./card.scss";
import Thumb from "../../assets/images/thumb.svg";
import ThumbDown from "../../assets/images/thumbdown.svg";
import Pass from "../../assets/images/pass.svg";
import Share from "../../assets/images/share.svg";
import PropositionModal from "../propositionmodal";
import { connect } from "react-redux";
import { voteProposalAsync, dislikeProposalAsync } from "../../redux/actions";
import isAuthenticated from "../../helpers/isAuthenticated";
import { toast } from "react-toastify";

class Card extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      clientXonMouseDown: null,
      clientYonMouseDown: null,
      vote: 0,
      dislike: 0
    };
  }

  toastId = null;
  customToastId = "vote";

  notify = (message, type) => {
    if (!toast.isActive(this.toastId)) {
      this.toastId = 0;
    }
    if (type === "success") {
      toast.success(message, {
        toastId: this.customToastId,
        className: "toast_success"
      });
    }
    if (type === "error") {
      toast.error(message, {
        toastId: this.customToastId,
        className: "toast_success"
      });
    }
  };

  componentDidMount() {
    this.setState({
      vote: this.props.proposal
        ? this.props.proposal.metrics
          ? this.props.proposal.metrics.votes
          : 0
        : 0
    });
    this.setState({
      dislike: this.props.proposal
        ? this.props.proposal.metrics
          ? this.props.proposal.metrics.dislikes
          : 0
        : 0
    });
  }
  componentDidUpdate(newProps) {
    if (
      newProps.proposals !== this.props.proposals &&
      this.props.proposals.vote
    ) {
      if (
        this.props.proposals &&
        this.props.proposals.vote &&
        Number(this.props.proposal.id) === Number(this.props.proposals.vote.id)
      ) {
        this.notify("Merci, votre vote a bien été pris en compte !", "success");
        this.setState({ vote: this.props.proposals.vote.metrics.votes });
        this.setState({ dislike: this.props.proposals.vote.metrics.dislikes });
      } else {
        if (
          this.props.proposals.vote &&
          this.props.proposals.vote.code === 400
        ) {
          this.notify("Vous avez déjà voté pour cette proposition", "error");
        }
      }
    }
  }
  openModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
    if (this.props.click) {
      this.props.click();
    }
  };

  handleOnMouseDown(e) {
    this.setState({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY
    });
    e.preventDefault(); // stops weird link dragging effect
  }

  handleOnClick(e) {
    e.stopPropagation();
    if (
      this.state.clientXonMouseDown !== e.clientX ||
      this.state.clientYonMouseDown !== e.clientY
    ) {
      // prevent link click if the element was dragged
      e.preventDefault();
    } else {
      this.setState({ modalIsOpen: !this.state.modalIsOpen }, () => {});
    }
  }

  handleDislike(e, proposalID) {
    e.stopPropagation();
    if (isAuthenticated()) {
      this.setState({ modalIsOpen: false });
      this.props.dislikeProposalAsync(proposalID);
      if (this.props.swipe) {
        this.props.swipe();
      }
      if (this.props.next) {
        this.props.next();
      }
    } else {
      this.props.history.push({
        pathname: "/auth/login"
      });
    }
  }

  handleVote(e, proposalID) {
    e.stopPropagation();
    if (isAuthenticated()) {
      this.setState({ modalIsOpen: false });
      this.props.voteProposalAsync(proposalID);
      if (this.props.swipe) {
        this.props.swipe();
      }
      if (this.props.next) {
        this.props.next();
      }
    } else {
      this.props.history.push({
        pathname: "/auth/login"
      });
    }
  }

  handlePass(e, proposalID) {
    e.stopPropagation();
    if (isAuthenticated()) {
      if (this.props.swipe) {
        this.props.swipe();
      }
      if (this.props.next) {
        this.props.next();
      }
    } else {
      this.props.history.push({
        pathname: "/auth/login"
      });
    }
  }
  render() {
    let proposal = this.props.proposal;
    let shareURL =
      window.location.protocol +
      "//" +
      window.location.hostname +
      "/proposals/" +
      proposal.id;
    return (
      <div>
        {proposal ? (
          <div
            onMouseDown={e => this.handleOnMouseDown(e)}
            onClick={e => this.handleOnClick(e)}
            style={this.props.blur}
            className={
              this.props.type !== "small"
                ? "card-proposition"
                : "card-proposition card-small-proposition "
            }
          >
            <div className="card-proposition-header">
              <p
                className="card-topic"
                style={{
                  color:
                    proposal.topic && proposal.topic.color
                      ? proposal.topic.color
                      : "#FAA600"
                }}
              >
                <span
                  style={{
                    background:
                      proposal.topic && proposal.topic.color
                        ? proposal.topic.color
                        : "#FAA600"
                  }}
                ></span>
                {proposal.topic ? proposal.topic.name : ""}
              </p>
              {this.props.type !== "small" ? (
                <span
                  style={{
                    background:
                      proposal.topic && proposal.topic.color
                        ? proposal.topic.color
                        : "#FAA600"
                  }}
                >
                  {this.props.index}/{this.props.total}
                </span>
              ) : (
                ""
              )}
            </div>
            {this.props.type === "small" ? (
              <div className="vote">
                <div
                  onClick={e => {
                    this.handleVote(e, proposal.id);
                  }}
                >
                  <span
                    className="span-bg"
                    style={{
                      background:
                        proposal.topic && proposal.topic.color
                          ? proposal.topic.color
                          : "#FAA600"
                    }}
                  ></span>
                  <img src={Thumb} alt="" />{" "}
                </div>
                <span
                  onClick={e => {
                    this.handleVote(e, proposal.id);
                  }}
                >
                  {proposal.metrics ? this.state.vote : 0}
                </span>
                <div
                  className="space-thumb"
                  onClick={e => {
                    this.handleDislike(e, proposal.id);
                  }}
                >
                  <span
                    className="span-bg"
                    style={{
                      background:
                        proposal.topic && proposal.topic.color
                          ? proposal.topic.color
                          : "#FAA600"
                    }}
                  ></span>
                  <img src={ThumbDown} alt="" />{" "}
                </div>
                <span
                  onClick={e => {
                    this.handleDislike(e, proposal.id);
                  }}
                >
                  {proposal.metrics ? this.state.dislike : 0}
                </span>
              </div>
            ) : (
              ""
            )}
            <div className="padded-multilines">
              <h2
                className="title"
                style={{
                  background:
                    proposal.topic && proposal.topic.color
                      ? proposal.topic.color
                      : "#FAA600"
                }}
              >
                {proposal.title}
              </h2>
            </div>
            {this.props.type !== "small" ? (
              <p className="description">
                {proposal.description
                  ? proposal.description.substring(0, 80) + "..."
                  : ""}
              </p>
            ) : (
              ""
            )}
            {this.props.type !== "small" ? (
              <div className="vote">
                <div
                  onClick={e => {
                    this.handleVote(e, proposal.id);
                  }}
                >
                  <span
                    className="span-bg"
                    style={{
                      background:
                        proposal.topic && proposal.topic.color
                          ? proposal.topic.color
                          : "#FAA600"
                    }}
                  ></span>
                  <img src={Thumb} alt="" />{" "}
                </div>
                <span
                  onClick={e => {
                    this.handleVote(e, proposal.id);
                  }}
                >
                  {proposal.metrics ? this.state.vote : 0}
                </span>
                <div
                  className="space-thumb"
                  onClick={e => {
                    this.handleDislike(e, proposal.id);
                  }}
                >
                  <span
                    className="span-bg"
                    style={{
                      background:
                        proposal.topic && proposal.topic.color
                          ? proposal.topic.color
                          : "#FAA600"
                    }}
                  ></span>
                  <img src={ThumbDown} alt="" />{" "}
                </div>
                <span
                  onClick={e => {
                    this.handleDislike(e, proposal.id);
                  }}
                >
                  {proposal.metrics ? this.state.dislike : 0}
                </span>
              </div>
            ) : (
              ""
            )}
            <div className="share">
              <p className="author">
                proposition de{" "}
                <span className="name">
                  {" "}
                  {proposal && proposal.user && proposal.user.profile
                    ? proposal.user.profile.full_name
                    : "Moi maire"}
                </span>
                {proposal && proposal.town ? " pour la ville de " : ""}
                <span className="name">
                  {" "}
                  {proposal && proposal.town ? proposal.town.name : ""}
                </span>
              </p>
              {this.props.type === "small" ? (
                <a
                  className="share-btn"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    let width = 626;
                    let height = 305;
                    var leftPosition, topPosition;
                    leftPosition = window.screen.width / 2 - (width / 2 + 10);
                    topPosition = window.screen.height / 2 - (height / 2 + 50);
                    var windowFeatures =
                      "status=no,height=" +
                      height +
                      ",width=" +
                      width +
                      ",resizable=no,left=" +
                      leftPosition +
                      ",top=" +
                      topPosition +
                      ",screenX=" +
                      leftPosition +
                      ",screenY=" +
                      topPosition +
                      ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
                    window.open(
                      "http://www.facebook.com/sharer.php?u=" +
                        encodeURIComponent(shareURL),
                      "sharer",
                      windowFeatures
                    );
                    return false;
                  }}
                  href="#/"
                >
                  <img src={Share} alt="" />
                </a>
              ) : (
                ""
              )}
            </div>
            {this.props.type !== "small" ? (
              <div className="pass">
                <img
                  src={Pass}
                  alt=""
                  onClick={e => {
                    this.handlePass(e, proposal.id);
                  }}
                />
                <p
                  className="ignore"
                  onClick={e => {
                    this.handlePass(e, proposal.id);
                  }}
                >
                  Passer
                </p>
              </div>
            ) : (
              ""
            )}
            <PropositionModal
              proposalID={this.props.proposal && this.props.proposal.id}
              proposal={this.props.proposal}
              modalIsOpen={this.state.modalIsOpen}
              openModal={this.openModal}
              closeModal={this.closeModal}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ proposals }) => {
  return { proposals };
};

export default withRouter(
  connect(mapStateToProps, {
    voteProposalAsync,
    dislikeProposalAsync
  })(Card)
);
