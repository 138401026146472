import ResetPassword from "../features/auth/reset";
import LazyHome from "../features/home";
import LazyProfile from "../features/profile";
import LazyConfidentialite from "../features/confidentialite";
import InscriptionCompleted from "../features/auth/register/InscriptionCompleted";

export const routes = [
  {
    path: "/profile",
    private: true,
    component: LazyProfile,
    exact: true
  },
  {
    path: "/confidentialite",
    private: false,
    auth: false,
    component: LazyConfidentialite,
    exact: true
  },
  {
    path: "/finished",
    private: false,
    auth: false,
    component: InscriptionCompleted,
    exact: true
  },
  {
    path: "/reset",
    private: false,
    auth: false,
    component: ResetPassword,
    exact: true
  },
  {
    path: "/",
    private: false,
    component: LazyHome,
    exact: false
  }
];
