export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "fr";

// Const from .env.local
export const apiUrl = process.env.REACT_APP_API_URL;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const codeGa = process.env.REACT_APP_CODE_GA;

export const searchPath = "/app/layouts/search";
