import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";
import "./nav.scss";
import LogoMM from "../../assets/images/moi_maire_logo.svg";
import LogoMMBlack from "../../assets/images/moi_maire_logo_black.svg";
import Close from "../../assets/images/close.svg";
import ArrowLeft from "../../assets/images/arrow_left.svg";
import ArrowLeftBlack from "../../assets/images/arrow_left_black.svg";

class Nav extends Component {
  render() {
    return (
      <div className={this.props.whiteVersion ? "nav_white" : "nav"}>
        {this.props.arrow_left ? <img src={this.props.whiteVersion ? ArrowLeftBlack : ArrowLeft } className="arrow_left" onClick={()=>this.props.history.goBack()} alt="" /> : <div />}
        {this.props.arrow_left ? <img src={this.props.whiteVersion ? LogoMMBlack : LogoMM} alt="" /> : <div />}
        {this.props.close ? <Link  className="close" to={{pathname: '/'}}><img src={Close} alt="" /></Link> : <div />}
      </div>
    );
  }
}

export default withRouter(Nav)
