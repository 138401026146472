import React, { Component, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import InfiniteScroll from "../../components/infinitescroll";
import Button from "../../components/button";
import SubjectsPropositions from "../../components/subjectspropositions";
import "react-tabs/style/react-tabs.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux";
import {
  getUserAsync,
  getMyTownAsync,
  updateUserAsync
} from "../../redux/actions";
import AsyncPaginate from "react-select-async-paginate";
import { Helmet } from "react-helmet";
import { apiUrl } from "../../constants/defaultValues";

// Component for /home
class Ville extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: false,
      town: null,
      value: "",
      filter: true,
      disabled: true,
      paramsTown: {
        sort: {},
        filter: {
          towns: [0]
        }
      }
    };
  }
  componentDidMount() {
    //this.props.getUserAsync();
  }

  componentDidUpdate(newProps) {
    if (
      newProps.users !== this.props.users &&
      this.props.users.user &&
      !this.state.user
    ) {
      this.setState({ user: true });
      this.props.getMyTownAsync(
        this.props.users.user.town ? this.props.users.user.town.id : 1
      );
      if (this.props.users.user.town) {
        this.setState(prevState => ({
          paramsTown: {
            ...prevState.paramsTown,
            filter: { towns: [this.props.users.user.town.id] }
          }
        }));
      }
    }
    if (newProps.town !== this.props.town) {
      this.setState({ town: this.props.town }, () => {});
    }
  }

  setValue = value => {
    this.setState({ value }, () => {});
  };

  handleTown = () => {
    if (this.state.value && this.state.value.value) {
      this.props.getMyTownAsync(this.state.value.value);
      this.setState({ filter: true }, () => {
        this.setState(prevState => ({
          paramsTown: {
            ...prevState.paramsTown,
            filter: { towns: [this.state.value.value] }
          }
        }));
      });
      let userTopics = this.props.users.user ? this.props.users.user.topics.map(topic => {
        return topic.id;
      }) : [];
      let userUpdate = {
        username: this.props.users.user.username,
        profile: {
          first_name: this.props.users.user.profile.first_name,
          last_name: this.props.users.user.profile.last_name,
          biography: this.props.users.user.profile.biography,
          image:
            this.props.users.user.profile.image &&
            this.props.users.user.profile.image.id
        },
        town: this.state.value.value,
        topics: userTopics
      };
      this.props.updateUserAsync(userUpdate);
    }
  };

  async loadOptions(search, loadedOptions, { page }) {
    const response = await fetch(
      `${apiUrl}/towns/?query=${search}&page=${page}`
    );
    const responseJSON = await response.json();
    const data = [];
    responseJSON.data.map(choice => {
      data.push({ value: choice.id, label: choice.name });
      return choice;
    });
    return {
      options: data,
      hasMore: responseJSON.pagination.page < responseJSON.pagination.nb_pages,
      additional: {
        page: page + 1
      }
    };
  }

  render() {
    const customStyles = {
      indicatorSeparator: (provided, state) => {
        const opacity = 0;
        return { ...provided, opacity };
      }
    };

    let town = this.state.town && this.state.town.town;
    return (
      <div className="home topproposition ville">
        <Helmet>
          <title>
            {town
              ? "Actualité et propositions pour la ville de " + town.name
              : "Actualité et propositions pour la ville de "}
          </title>
          <meta
            name="description"
            content={
              town
                ? "Suivez les propositions de la communauté et les candidats pour la ville de " +
                  town.name +
                  " dans le cadre des Municipales 2020 avec Nice-Matin"
                : ""
            }
          />
          <meta
            property="og:title"
            content={
              town
                ? "Actualité et propositions pour la ville de " + town.name
                : "Actualité et propositions pour la ville de "
            }
          />
          <meta
            property="og:description"
            content={
              town
                ? "Suivez les propositions de la communauté et les candidats pour la ville de " +
                  town.name +
                  " dans le cadre des Municipales 2020 avec Nice-Matin"
                : ""
            }
          />
          <meta property="og:image" content="" />
          {/*<meta property="og:url" content="http://euro-travel-example.com/index.htm"></meta>*/}
        </Helmet>
        <div className="dark-section top-propositions-section ville-section">
          <h3 className="header-section text-center">
            Ma ville, {town ? town.name : ""}
          </h3>
          <div className="hide-on-desktop">
            <AsyncPaginate
              defaultValue={this.loadOptions[1]}
              value={this.state.value}
              loadOptions={this.loadOptions}
              onChange={this.setValue}
              placeholder="Choisissez une autre ville"
              additional={{
                page: 1
              }}
              className="my-select"
              styles={customStyles}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#00102B",
                  neutral0: "transparent",
                  primary25: "hotpink",
                  neutral80: "#00102B"
                }
              })}
            />
            <Link to={{}} onClick={this.handleTown}>
              <Button
                color="primary"
                background="white"
                text="Continuer"
                type="round"
              />
            </Link>
          </div>
          <div className="row text-center infos-ville">
            <div className="col-md-4">
              <p className="header-info">MAIRE ACTUEL</p>
              <h4 className="info">
                {town && town.mayor ? town.mayor.name : ""}
              </h4>
            </div>

            <div className="col-md-4">
              <p className="header-info">Nombre d’habitants</p>
              <h4 className="info">{town ? town.population : ""}</h4>
            </div>

            <div className="col-md-4">
              <p className="header-info">Revenu médian</p>
              <h4 className="info">
                {town ? (town.income ? town.income + " €" : "0 €") : ""}
              </h4>
            </div>
          </div>
          <div className="hide-on-mobile town-choice">
            <AsyncPaginate
              defaultValue={this.loadOptions[1]}
              value={this.state.value}
              loadOptions={this.loadOptions}
              onChange={this.setValue}
              placeholder="Choisissez une autre ville"
              additional={{
                page: 1
              }}
              className="my-select"
              styles={customStyles}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#00102B",
                  neutral0: "transparent",
                  primary25: "hotpink",
                  neutral80: "#00102B"
                }
              })}
            />
            <Link to={{}} onClick={this.handleTown}>
              <Button
                color="primary"
                background="white"
                text="Continuer"
                type="round"
              />
            </Link>
          </div>
        </div>
        <div className="white-section">
          <div className="shared-propositions-section">
            <p className="slider-title">candidats aux municipales</p>
            <div className="row scroller">
              {this.state.paramsTown &&
              this.state.paramsTown.filter &&
              this.state.paramsTown.filter.towns[0] !== 0 ? (
                <InfiniteScroll
                  comp={55}
                  filter={false}
                  getproposals={false}
                  getusers={false}
                  getcandidats={true}
                  callOnClick={true}
                  params={this.state.paramsTown}
                />
              ) : (
                <Fragment />
              )}
            </div>
            <Link
              to={{ pathname: "/propositions" }}
              className="hide-on-desktop"
            >
              <Button
                color="black"
                background="transparent"
                text={
                  town
                    ? "Découvrir les propositions à " + town.name
                    : "Découvrir les propositions"
                }
                type="round"
              />
            </Link>
          </div>
        </div>
        <SubjectsPropositions />
      </div>
    );
  }
}

const mapStateToProps = ({ town, users, candidats }) => {
  return { town, users, candidats };
};

export default withRouter(
  connect(mapStateToProps, {
    getUserAsync,
    getMyTownAsync,
    updateUserAsync
  })(Ville)
);
