import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Nav from "../../../components/nav";
import { connect } from "react-redux";
import TextInput from "../../../components/form/textInput";
import { loginUserBasic } from "../../../redux/actions";
import { animateScroll as scroll } from "react-scroll";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet";

const ComposantErreur = props => {
  return (
    <div className="error-message">
      <span>{props.children}</span>
    </div>
  );
};

// Component for /Geolocalisation
class Connect extends Component {
  constructor() {
    super();
    this.state = {
      form: null,
      isSubmitting: false
    };
  }

  componentDidMount() {
    scroll.scrollToTop({ duration: 250, delay: 0 });
  }

  handleFormSubmit = async (values, actions) => {
    this.setState({ form: actions });
    await this.props.loginUserBasic(values);
  };

  componentDidUpdate(newProps) {
    if (this.props.user !== newProps.user) {
      if (this.props.user && this.props.user.code === 401) {
        this.state.form.setFieldError("general", this.props.user.message);
      } else {
        this.props.history.push({
          pathname: "/"
        });
      }
    }
  }

  userSchema = Yup.object().shape({
    username: Yup.string()
      .email("Email Invalide")
      .required(" "),
    password: Yup.string().required(" ")
  });

  render() {
    return (
      <div className="connect-form">
        <Helmet>
          <title>
            {"Devenez maire de votre commune et améliorez la vie citoyenne"}
          </title>
          <meta
            name="description"
            content={
              "Inscrivez-vous pour améliorer la vie citoyenne de votre commune. Grâce à MOI, MAIRE, les Municipales 2020 deviennent participatives !"
            }
          />
          <meta
            property="og:title"
            content={
              "Devenez maire de votre commune et améliorez la vie citoyenne"
            }
          />
          <meta
            property="og:description"
            content={
              "Inscrivez-vous pour améliorer la vie citoyenne de votre commune. Grâce à MOI, MAIRE, les Municipales 2020 deviennent participatives !"
            }
          />
          <meta property="og:image" content="" />
          {/*<meta property="og:url" content="http://euro-travel-example.com/index.htm"></meta>*/}
        </Helmet>

        <Nav whiteVersion={true} arrow_left={true} logo={true} close={true} />
        <h1>Connectez-vous</h1>
        <div className="form">
          <Formik
            onSubmit={this.handleFormSubmit}
            initialValues={{ username: "", password: "" }}
            validationSchema={this.userSchema}
            validateOnChange={true}
          >
            {({ handleSubmit, isSubmitting, actions, errors }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  type="text"
                  placeholder="Votre adresse email *"
                  name="username"
                  className="round"
                  errorClass={errors.username ? "errors" : ""}
                  label="Adresse email"
                  component={TextInput}
                />
                <ErrorMessage name="username" component={ComposantErreur} />
                <Field
                  type="password"
                  placeholder="Mot de passe *"
                  name="password"
                  className="round"
                  errorClass={errors.password ? "errors" : ""}
                  label=""
                  component={TextInput}
                />
                <ErrorMessage name="password" component={ComposantErreur} />
                <div style={{ color: "red" }}>{errors.general}</div>
                <button
                  type="submit"
                  className="button round text-primary bg-green round btn btn-4 btn-4c icon-arrow-right"
                  disabled={isSubmitting || this.state.isSubmitting}
                >
                  Se connecter
                </button>
                <Link
                  to="/auth/forgot"
                  className="confidentialite confidentialite-btn-connect"
                >
                  Vous avez oublié votre mot de passe ?
                </Link>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    loginUserBasic
  })(Connect)
);
