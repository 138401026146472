import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";


import { composeWithDevTools } from "redux-devtools-extension";
const middlewares = [thunk];



export const loadState = () => {
	try {
		const serializedState = localStorage.getItem("state");
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return undefined;
	}
};

const saveState = state => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem("state", serializedState);
	} catch (err) {
		//
	}
};

const persistedState = loadState();

export function configureStore(initialState) {
	const store = createStore(
			reducers,
			persistedState,
			composeWithDevTools(applyMiddleware(...middlewares))
		);

	//save the store
	store.subscribe(() => {
		saveState({
			authUser: store.getState().authUser || "",
			town: store.getState().town || "",
			topic: store.getState().topic || ""
		});
	});
	//console.log(store.getState());

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept("./reducers", () => {
			const nextRootReducer = require("./reducers");
			store.replaceReducer(nextRootReducer);
		});
	}

	return store;
}
