//API
import { getUserSuccess, getUserError, listUserSuccess, listUserError, listUser, getUser } from "./actions";
import { loginUserSuccess } from '../auth/actions'
import { apiUrl } from "../../constants/defaultValues";
import qs from 'qs';
import axios from "axios";
import {authHeader} from '../../helpers/authHeader';

export const getUserAsync = payload => async dispatch => {
  dispatch(getUser({}));
  axios.get(`${apiUrl}/users/me`, {headers: authHeader()})
	  .then(function (response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
			dispatch(getUserSuccess({ ...response.data}));
      return response;
	  })
	  .catch(function (error) {
    	dispatch(getUserError({ ...error.response}));
	  });
};

export const getUserByIdAsync = id => async dispatch => {
  dispatch(getUser({}));
  axios.get(`${apiUrl}/users/${id}`)
	  .then(function (response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
			dispatch(getUserSuccess({ ...response.data}));
      return response;
	  })
	  .catch(function (error) {
    	dispatch(getUserError({ ...error.response}));
	  });
};

export const updateUserAsync = payload => async dispatch => {
  dispatch(getUser({}));
  axios.post(`${apiUrl}/users/me`, payload, {headers: authHeader()})
	  .then(function (response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
			dispatch(getUserSuccess({ ...response.data.user}));
      let newToken = {token : response.data.token}
      dispatch(loginUserSuccess({ ...newToken }));
      return response;
	  })
	  .catch(function (error) {
  		dispatch(getUserError({ ...error.response}));
	  });
};

export const getUsersAsync = (params, page, comp) => async dispatch => {
  dispatch(listUser(comp));
  let url = "/";
  params.page = page;
  if(params.sort && params.sort.score==="desc") {
    url = "/users/top/"+params.filter.date;
  } else if(params.sort && params.sort.votes==="desc") {
    url = "/users/top/"+params.filter.date;
  } else {
    url = "/users/";
  }
  axios.get(`${apiUrl}${url}`, {
     params: params,
     paramsSerializer: function(params) { return qs.stringify(params, { encode: false }); }
  })
	  .then(function (response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      let users = {
        users: response.data.data,
        hasMore: page < response.data.pagination.nb_pages,
        comp: comp
      }
  		dispatch(listUserSuccess({ ...users}));
      return users;
	  })
	  .catch(function (error) {
  		dispatch(listUserError({ ...error.response}));
	  });
};
