import {
	NEWS_ONE,
	NEWS_LIST,
	NEWS_LIST_SUCCESS,
	NEWS_LIST_ERROR
} from "../../constants/actionTypes";

const INIT_STATE = {
    news: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case NEWS_ONE:
            return { ...state, loading: true };
        case NEWS_LIST:
            return { ...state, loading: true };
        case NEWS_LIST_SUCCESS:
            //notify.success('Login Success');
            return { ...state, loading: false, news: action.payload };
        case NEWS_LIST_ERROR:
            //notify.success('Login Success');
            return { ...state, loading: false, news: action.payload };
        default: return { ...state };
    }
}
