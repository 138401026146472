import React, { Component } from "react";

import "./login.scss";
import logoMM from "../../assets/images/moi_maire_logo_white.png";

class Logo extends Component {
  render() {
    return (
      <div className="logo">
        <img src={logoMM} alt="" />
      </div>
    );
  }
}

export default Logo;
