import React, { Component } from "react";
import "./addpropositionmodal.scss";
import Modal from "react-modal";
import TextArea from "../../components/form/textarea";
import Close from "../../assets/images/close.svg";
import Plus from "../../assets/images/plus.svg";
import RUG, { DropArea } from "react-upload-gallery";
import { withRouter, Link } from "react-router-dom";
import "react-upload-gallery/dist/style.css"; // or scss
import Button from "../../components/button";
import Tag from "../../components/tag";
import PropositionModal from "../propositionmodal";
import * as store from "../../redux/store";
import Arrow from "../../assets/images/arrow_left_black.svg";
import { connect } from "react-redux";
import {
  getTopicsAsync,
  uploadMedia,
  addProposalAsync
} from "../../redux/actions";
import ArrowLeftBlack from "../../assets/images/arrow_left_black.svg";
import { Formik, Field, ErrorMessage } from "formik";
import isAuthenticated from "../../helpers/isAuthenticated";
import * as Yup from "yup";

const ComposantErreur = props => {
  return (
    <div className="error-message">
      <span>{props.children}</span>
    </div>
  );
};

class AddPropositionModal extends Component {
  constructor() {
    super();
    this.state = {
      topics: [],
      choices: new Array(17).fill(false),
      title: "",
      adress: "",
      description: "",
      chosenTopic: 0,
      modalIsOpenSubject: false,
      modalIsOpenMerci: false,
      modalIsOpenProp: false,
      isSubmitting: false,
      changed: null,
      modalIsOpened: true,
      images: [],
      matches: window.matchMedia("(min-width: 768px)").matches,
      marginTopPx: 0
    };
  }

  init(textBtn) {
    var observe;
    if (window.attachEvent) {
      observe = function(element, event, handler) {
        if (element) {
          element.attachEvent("on" + event, handler);
        }
      };
    } else {
      observe = function(element, event, handler) {
        if (element) {
          element.addEventListener(event, handler, false);
        }
      };
    }

    var text = document.getElementById(textBtn);

    if (text) {
      function resize() {
        text.style.height = "auto";
        var x = text.scrollHeight + 4;
        text.style.height = x + "px";
      }
      /* 0-timeout to get the already changed text */
      function delayedResize() {
        window.setTimeout(resize, 0);
      }
      observe(text, "change", resize);
      observe(text, "cut", delayedResize);
      observe(text, "paste", delayedResize);
      observe(text, "drop", delayedResize);
      observe(text, "keydown", delayedResize);

      resize();
    }
  }

  componentDidMount() {
    let isAuth =
      store.loadState() &&
      store.loadState().authUser &&
      store.loadState().authUser.user &&
      store.loadState().authUser.user.token;
    if (!isAuth && this.props.modalIsOpen) {
      this.props.history.push("/auth/login");
    }
  }
  componentDidUpdate(newProps) {
    let isAuth =
      store.loadState() &&
      store.loadState().authUser &&
      store.loadState().authUser.user &&
      store.loadState().authUser.user.token;
    if (!isAuth && this.props.modalIsOpen) {
      this.props.closeModal();
      this.props.history.push("/auth/login");
    }
    if (newProps.topic !== this.props.topic) {
      this.setState({ topics: Object.values(this.props.topic.topics) });
      this.setState({
        choices: new Array(Object.values(this.props.topic.topics).length).fill(
          false
        )
      });
    }
    if (
      newProps.addproposal !== this.props.addproposal &&
      this.props.addproposal.addedMedia
    ) {
      this.setState(prevState => ({
        images: [...prevState.images, this.props.addproposal.addedMedia.id]
      }));
    }
  }
  handleShow = e => {
    setTimeout(
      function() {
        this.init("new-proposal-title");
        this.init("new-proposal-description");
        this.init("new-proposal-lieu");
      }.bind(this),
      5
    );
    this.setState({ [e.currentTarget.id]: !this.state[e.currentTarget.id] });
  };
  handleChange = e => {
    setTimeout(
      function() {
        this.init("new-proposal-title");
        this.init("new-proposal-description");
        this.init("new-proposal-lieu");
      }.bind(this),
      5
    );
  };
  handleFormSubmit = (values, actions) => {
    this.setState({ title: values.title });
    this.setState({ description: values.description });
    this.setState({ adress: values.adress });
    this.setState({ modalIsOpenSubject: true });
  };

  openModalMerci = () => {
    if (this.state.chosenTopic) {
      let newProposal = {
        title: this.state.title,
        description: this.state.description,
        address: this.state.adress,
        topic: this.state.chosenTopic,
        images: this.state.images
      };
      this.props.addProposalAsync(newProposal);
      this.props.closeModal();
      this.setState({ modalIsOpenSubject: false });
      this.setState({ modalIsOpenMerci: true });
      this.setState({ chosenTopic: 0 });
      this.setState({ marginTopPx: 0 });
    }
  };

  openModalProp = () => {
    this.setState({ modalIsOpenProp: !this.state.modalIsOpenProp });
  };

  closeModalProp = () => {
    this.closeModal();
  };

  userSchema = Yup.object().shape({
    title: Yup.string().required("Champs obligatoire")
  });

  closeModal = e => {
    this.props.closeModal();
    this.setState({ modalIsOpenSubject: false });
    this.setState({ modalIsOpenMerci: false });
    this.setState({ chosenTopic: 0 });
    this.setState({ marginTopPx: 0 });
  };

  closeModalSubjects = e => {
    this.setState({ modalIsOpenSubject: false });
  };

  addActiveClass = index => {
    this.setState({ chosenTopic: index });
  };

  render() {
    let isAuth = isAuthenticated();
    const handler = e => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);

    return (
      <div className="modalNewProposition">
        <Modal
          isOpen={
            isAuth && this.state.modalIsOpened ? this.props.modalIsOpen : false
          }
          onRequestClose={this.props.closeModal}
          contentLabel=""
          className="modalNewProposition white-section"
        >
          <img
            src={ArrowLeftBlack}
            className="arrow_left back-modal"
            onClick={this.props.closeModal}
            alt=""
          />

          <img
            onClick={this.props.closeModal}
            src={Close}
            className="close-modal"
            alt=""
          />
          <div className="padded-multilines">
            <h2>
              Votre proposition pour la ville de{" "}
              {this.props.user &&
                this.props.user.town &&
                this.props.user.town.name}
            </h2>
          </div>
          <div className="form">
            <Formik
              onSubmit={this.handleFormSubmit}
              initialValues={{
                title: this.props.title,
                description: "",
                adress: ""
              }}
              validateOnChange={true}
              validationSchema={this.userSchema}
            >
              {({ handleSubmit, isSubmitting, errors, actions }) => (
                <form onSubmit={handleSubmit} onChange={this.handleChange}>
                  <Field
                    placeholder=""
                    rows="1"
                    id="new-proposal-title"
                    name="title"
                    type="text"
                    className="round"
                    label="Titre *"
                    hint="Choisissez un titre percutant : soyez concis, pensez au but de votre proposition."
                    component={TextArea}
                  />
                  <ErrorMessage name="title" component={ComposantErreur} />
                  <div
                    className={
                      this.state.description ? "show closing" : "hidden"
                    }
                  >
                    <Field
                      placeholder=""
                      name="description"
                      imagePlaceholder={Close}
                      closing={this.handleShow}
                      className="round"
                      type="text"
                      id="new-proposal-description"
                      label="Description"
                      hint="Quelques lignes de présentation. Cette description apparait sur les suggestions de la page “Aujourd’hui”"
                      component={TextArea}
                    />
                    <ErrorMessage
                      name="description"
                      component={ComposantErreur}
                    />
                  </div>
                  <div className={this.state.lieu ? "show closing" : "hidden"}>
                    <Field
                      placeholder="Adresse"
                      name="adress"
                      imagePlaceholder={Close}
                      closing={this.handleShow}
                      id="new-proposal-lieu"
                      className="round"
                      label="Adresse"
                      hint="Quelle est la situation actuelle ? "
                      component={TextArea}
                    />
                    <ErrorMessage name="adress" component={ComposantErreur} />
                  </div>

                  <div className="add-buttons">
                    <div
                      id="description"
                      className={
                        !this.state.description
                          ? "show add-button"
                          : "hidden add-button"
                      }
                      onClick={this.handleShow}
                    >
                      <img src={Plus} alt="" />
                      <p>Ajouter une description</p>
                    </div>
                    <div
                      id="lieu"
                      className={
                        !this.state.lieu
                          ? "show add-button"
                          : "hidden add-button"
                      }
                      onClick={this.handleShow}
                    >
                      <img src={Plus} alt="" />
                      <p>Ajouter un lieu </p>
                    </div>

                    <div>
                      <RUG
                        onChange={images => {
                          if (images[0]) {
                            this.setState({
                              marginTopPx: this.state.matches
                                ? Math.ceil(images.length / 3) * 200 +
                                  Math.ceil(images.length / 3) * 25
                                : images.length * 200 + 25
                            });
                            if (this.state.changed !== null) {
                              if (this.state.changed.uid !== images[0].uid) {
                                this.setState({ changed: images[0] }, () => {
                                  images &&
                                    images[0] &&
                                    this.props.uploadMedia(this.state.changed);
                                }); // save current component
                              }
                            } else {
                              this.setState({ changed: images[0] }, () => {
                                images &&
                                  images[0] &&
                                  this.props.uploadMedia(this.state.changed);
                              }); // save current component
                            }
                          }
                        }}
                        header={({ openDialogue }) => (
                          <DropArea>
                            {isDrag => (
                              <div style={{ background: "transparent" }}>
                                <div
                                  onClick={openDialogue}
                                  className="add-button"
                                >
                                  <img src={Plus} alt="" />
                                  <p>Ajouter une photo </p>
                                </div>
                              </div>
                            )}
                          </DropArea>
                        )}
                      ></RUG>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="button confirm-btn round text-primary bg-green round  btn btn-4 btn-4c icon-arrow-right"
                    style={{ marginTop: this.state.marginTopPx + "px" }}
                    disabled={isSubmitting || this.state.isSubmitting}
                  >
                    Continuer
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.modalIsOpenSubject}
          onRequestClose={this.closeModalSubjects}
          contentLabel=""
          className="modalNewProposition white-section"
        >
          <img
            onClick={this.closeModalSubjects}
            src={Arrow}
            className="back-modal"
            alt=""
          />
          <img
            onClick={this.closeModalProp}
            src={Close}
            className="close-modal"
            alt=""
          />
          <div className="padded-multilines">
            <h2>Dans quelle thématique classez-vous votre proposition ?</h2>
          </div>
          <div className="form">
            <div className="fav-subjects">
              {this.state.topics.map(topic => {
                return (
                  <Tag
                    key={topic.id}
                    index={topic.id}
                    color="primary"
                    background={
                      this.state.chosenTopic === topic.id
                        ? topic.color
                        : "#212529"
                    }
                    text={topic.name}
                    type="round"
                    className={
                      this.state.chosenTopic === topic.id
                        ? "tag checked"
                        : "tag"
                    }
                    checked={this.addActiveClass}
                  />
                );
              })}
            </div>
          </div>

          <Link
            to={{}}
            onClick={this.openModalMerci}
            className={this.state.chosenTopic === 0 ? "disabled-link" : ""}
          >
            {" "}
            <Button
              color="black"
              background="green"
              text="Valider"
              type="round"
              className=""
            />
          </Link>
        </Modal>

        <Modal
          isOpen={this.state.modalIsOpenMerci}
          onRequestClose={this.closeModalProp}
          contentLabel=""
          className="modalNewPropositionMerci white-section"
        >
          <img
            onClick={this.closeModalProp}
            src={Close}
            className="close-modal"
            alt=""
          />
          <div className="proposition-completed">
            <div>
              <h1>Merci</h1>
              <h5>
                Votre proposition a bien été déposée et sera prochainement
                soumise au vote des participants.
              </h5>
            </div>
            <div className="bg-green-fluo">
              <h3>
                Vous serez prévenu par mail quand votre proposition sera en
                ligne. Vous pourrez alors la partager à vos amis pour récolter
                le plus de votes possibles :)
              </h3>
            </div>
          </div>
          <Link
            to={{ pathname: "/propositions" }}
            onClick={this.closeModalProp}
          >
            <Button
              color="white"
              background="transparent"
              text="Voir les propositions"
              type="round"
            />
          </Link>
          <Link
            to={{ pathname: "/confidentialite" }}
            onClick={this.closeModalProp}
          >
            <p className="chart">Consulter la charte de modération</p>
          </Link>
        </Modal>

        <PropositionModal
          modalIsOpen={this.state.modalIsOpenProp}
          openModal={this.openModalProp}
          closeModal={this.closeModalProp}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ topic, addproposal, users }) => {
  const { user } = users;
  return { topic, addproposal, user };
};

export default withRouter(
  connect(mapStateToProps, {
    addProposalAsync,
    getTopicsAsync,
    uploadMedia
  })(AddPropositionModal)
);
