//API
import { listNewsSuccess, listNewsError } from "./actions";
import { apiUrl } from "../../constants/defaultValues";
import axios from "axios";

export const getNewsAsync = uri => async dispatch => {
  axios
    .get(`${apiUrl}/bridge/nicematin/${uri}?count=3`)
    .then(function(response) {
      dispatch(listNewsSuccess({ ...response.data }));
    })
    .catch(function(error) {
      dispatch(listNewsError({ ...error.response }));
    });
};
