//API
import { addMediaSuccess, addMediaError, addProposalSuccess, addProposalError } from "./actions";
import { apiUrl } from "../../constants/defaultValues";
import axios from "axios";
import {authHeader, authHeaderData} from '../../helpers/authHeader';

export const uploadMedia = payload => async dispatch => {
  var formData = new FormData();
  var imagefile = payload;
  formData.append("file", imagefile.file);

  axios.post(`${apiUrl}/media`, formData, {headers: authHeaderData()})
	  .then(function (response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
			dispatch(addMediaSuccess({ ...response.data}));
      return response.data;
	  })
	  .catch(function (error) {
			dispatch(addMediaError({ ...error.response}));
	  });
};

export const addProposalAsync = payload => async dispatch => {
  axios.post(`${apiUrl}/proposals`, payload, {headers: authHeader()})
	  .then(function (response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
			dispatch(addProposalSuccess({ ...response.data}));
      return response.data;
	  })
	  .catch(function (error) {
			dispatch(addProposalError({ ...error.response}));
	  });
};
