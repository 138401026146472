import React, { Component } from "react";

import "./button.scss";

class Button extends Component {
  render() {
    return (
      <button
        className={`button btn btn-4 btn-4c icon-arrow-right  ${this.props.type} text-${this.props.color} bg-${this.props.background} ${this.props.type} ${this.props.className}`}
        onClick={this.props.click}
      >
        {this.props.text}
      </button>
    );
  }
}

export default Button;
