import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MySlider from "../../components/slider";
import SubjectsPropositions from "../../components/subjectspropositions";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import { animateScroll as scroll } from "react-scroll";

// Component for /home
class TopPropositions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      tabIndex: 0,
      paramsMostVoted: {
        sort: {
          votes: "desc"
        },
        filter: {
          date: "all",
          topics: [],
          towns: []
        }
      },
      paramsMostVotedWeek: {
        sort: {
          votes: "desc"
        },
        filter: {
          date: "week",
          topics: [],
          towns: []
        }
      }
    };
  }

  componentDidMount() {
    scroll.scrollToTop({ duration: 250, delay: 0 });
  }
  render() {
    const paramsMostProgressed = {
      sort: {
        votes: "desc"
      },
      filter: {
        date: "today",
        topics: [],
        towns: []
      }
    };
    const paramsMostShared = {
      sort: {
        shares: "desc"
      },
      filter: {
        date: "",
        topics: [],
        towns: []
      }
    };
    var settingsTopPropositionSlider = {
      dots: false,
      arrows: false,
      infinite: false,
      className: "center",
      centerPadding: "5px",
      speed: 750,
      swipeToSlide: true,
      touchThreshold: 12,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            adaptiveHeight: true,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            adaptiveHeight: true,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            adaptiveHeight: true,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="home topproposition">
        <Helmet>
          <title>
            {"Les meilleurs propositions pour les municipales 2020"}
          </title>
          <meta
            name="description"
            content={
              "Consultez les meilleures propositions déposées et partagées sur la plateforme MOI, MAIRE dans le cadre des Municipales 2020 avec Nice-Matin"
            }
          />
          <meta
            property="og:title"
            content={"Les meilleurs propositions pour les municipales 2020"}
          />
          <meta
            property="og:description"
            content={
              "Consultez les meilleures propositions déposées et partagées sur la plateforme MOI, MAIRE dans le cadre des Municipales 2020 avec Nice-Matin"
            }
          />
          <meta property="og:image" content="" />
          {/*<meta property="og:url" content="http://euro-travel-example.com/index.htm"></meta>*/}
        </Helmet>
        <div className="dark-section top-propositions-section">
          <h3 className="header-section">Top propositions</h3>
          <Tabs
            selectedIndex={this.state.tabIndex}
            onSelect={tabIndex => this.setState({ tabIndex })}
          >
            <TabList>
              <Tab>CETTE SEMAINE</Tab>
              <Tab>DEPUIS LE DÉBUT</Tab>
            </TabList>
            <p className="slider-title">Classement général</p>
            <TabPanel>
              <MySlider
                comp={11}
                resetting={true}
                getusers={false}
                getproposals={true}
                callOnClick={false}
                settings={settingsTopPropositionSlider}
                params={this.state.paramsMostVotedWeek}
              />
            </TabPanel>
            <TabPanel>
              <MySlider
                comp={22}
                resetting={true}
                getusers={false}
                getproposals={true}
                callOnClick={false}
                settings={settingsTopPropositionSlider}
                params={this.state.paramsMostVoted}
              />
            </TabPanel>
          </Tabs>
        </div>
        <div className="white-section">
          <div className="best-propositions-section">
            <p className="slider-title">Meilleure progression</p>
            <MySlider
              comp={33}
              resetting={true}
              getusers={false}
              getproposals={true}
              callOnClick={false}
              settings={settingsTopPropositionSlider}
              params={paramsMostProgressed}
            />
          </div>
          <div className="shared-propositions-section">
            <p className="slider-title">Les plus partagées</p>
            <MySlider
              comp={44}
              resetting={true}
              getusers={false}
              getproposals={true}
              callOnClick={false}
              settings={settingsTopPropositionSlider}
              params={paramsMostShared}
            />
          </div>
        </div>
        <SubjectsPropositions />
      </div>
    );
  }
}

export default withRouter(TopPropositions);
