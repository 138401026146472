import React from "react";
import "./textarea.scss";

const TextArea = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <div className="text-input form-group">
      <label>{props.label}</label>
      {props.imagePlaceholder ? (
        <span
          id={field.name}
          className="imagePlaceholder"
          onClick={props.closing}
        >
          <img src={props.imagePlaceholder} alt="close" />
        </span>
      ) : (
        ""
      )}
      <textarea
        {...props}
        id={props.id}
        name={props.name}
        placeholder={[props.placeholder]}
        className="round"
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        onFocus={props.handleFocus}
        rows={props.rows ? props.rows : 5}
        {...field}
      >
        {props.value}
      </textarea>
      <p className="hint">{props.hint}</p>
    </div>
  );
};
export default TextArea;
