import React, { Component } from "react";
import Header from "../../components/header";
import MainNav from "../../components/mainnav";
import SubjectsPropositions from "../../components/subjectspropositions";

// Component for /error
class Error extends Component {
  render() {
    return (
      <div className="home topproposition">
        <Header />
        <div className="white-section confidentialite-page text-center">
          <h1>404 Not Found</h1>
          <h3>Le lien que vous recherchez n'existe pas</h3>
        </div>
        <SubjectsPropositions />
        <MainNav />
      </div>
    );
  }
}

export default Error;
