//API
import { listTopicsSuccess, listTopicsError } from "./actions";
import { apiUrl } from "../../constants/defaultValues";
import axios from "axios";

export const getTopicsAsync = payload => async dispatch => {
  return await axios.get(`${apiUrl}/topics`)
	  .then(function (response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
			dispatch(listTopicsSuccess({ ...response.data}));
      return response.data;
	  })
	  .catch(function (error) {
			dispatch(listTopicsError({ ...error.response}));
	  });
};
