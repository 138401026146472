import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./clickscroll.scss";
import Card from "../card";
import Candidat from "../candidat";
import Loader from "../loader";
import Button from "../button";
import {
  getProposalsAsync,
  getUsersAsync,
  listProposalReset,
  listUsersReset
} from "../../redux/actions";
import { connect } from "react-redux";

class ClickScroll extends Component {
  constructor() {
    super();
    this.state = {
      users: [],
      proposals: [],
      loading: true,
      comp: 0,
      page: 1,
      loadMore: true
    };
  }

  componentDidMount() {
    this.setState({ comp: this.props.comp }, () => {
      if (this.props.getproposals) {
        this.props.listProposalReset(this.props.comp);
      }
      if (this.props.getusers) {
        this.props.listUsersReset(this.props.comp);
      }
    });
  }

  componentDidUpdate(newProps) {
    if (this.props.getproposals) {
      if (this.props.proposals !== newProps.proposals) {
        if (newProps.proposals.loading !== this.props.proposals.loading) {
          if (
            this.state.comp === this.props.proposals.comp ||
            (this.state.comp === this.props.proposals.proposals &&
              this.props.proposals.proposals.comp)
          ) {
            this.setState({ loading: this.props.proposals.loading });
          }
          this.setState({ changes: 0 });
        } else {
          this.setState({ loading: this.props.proposals.loading });
        }
      }
    }
    if (this.props.getusers) {
      if (this.props.users !== newProps.users) {
        if (newProps.users.loading !== this.props.users.loading) {
          if (
            this.state.comp === this.props.users.comp ||
            (this.props.users.users && this.props.users.users.comp)
          ) {
            this.setState({ loading: this.props.users.loading });
          }
        }
      }
    }
    if (this.props.getproposals) {
      if (this.props.proposals !== newProps.proposals) {
        if (newProps.proposals.reset !== this.props.proposals.reset) {
          if (this.props.proposals.reset) {
            this.props.getProposalsAsync(
              this.props.params,
              this.state.page,
              this.state.comp,
              false
            );
          }
        }
      }
    }
    if (this.props.getusers) {
      if (this.props.users !== newProps.users) {
        if (newProps.users.reset !== this.props.users.reset) {
          if (this.props.users.reset) {
            this.props.getUsersAsync(
              this.props.params,
              this.state.page,
              this.state.comp
            );
          }
        }
      }
    }
    if (this.props.proposals !== newProps.proposals) {
      if (this.props.proposals.proposals !== newProps.proposals.proposals) {
        if (
          this.props.getproposals &&
          this.props.proposals &&
          this.props.proposals.proposals &&
          this.props.proposals.proposals.proposals
        ) {
          if (this.state.comp === this.props.proposals.proposals.comp) {
            let allProposals = [
              this.state.proposals,
              this.props.proposals &&
                this.props.proposals.proposals &&
                this.props.proposals.proposals.proposals
            ];
            let comb = allProposals.reduce((a, b) => [...a, ...b], []);
            this.setState({ proposals: comb }, () => {
              this.setState({
                loadMore:
                  this.props.proposals.proposals &&
                  this.props.proposals.proposals.hasMore
              });
            });
          }
        }
      }
    }
    if (this.props.users !== newProps.users) {
      if (this.props.users.users !== newProps.users.users) {
        if (
          this.props.getusers &&
          this.props.users &&
          this.props.users.users &&
          this.props.users.users.users
        ) {
          if (this.state.comp === this.props.users.users.comp) {
            if (
              JSON.stringify(this.state.users) !==
              JSON.stringify(this.props.users.users.users)
            ) {
              let allProposals = [
                this.state.users,
                this.props.users &&
                  this.props.users.users &&
                  this.props.users.users.users
              ];
              let comb = allProposals.reduce((a, b) => [...a, ...b], []);
              this.setState({ users: comb }, () => {
                this.setState({
                  loadMore:
                    this.props.users.users && this.props.users.users.hasMore
                });
              });
            }
          }
        }
      }
    }
  }

  addMoreProposals = () => {
    if (this.state.loadMore) {
      if (this.props.getproposals) {
        this.setState({ page: this.state.page + 1 }, () => {
          this.props.getProposalsAsync(
            this.props.params,
            this.state.page,
            this.state.comp
          );
        });
      }
      if (this.props.getusers) {
        this.setState({ page: this.state.page + 1 }, () => {
          this.props.getUsersAsync(
            this.props.params,
            this.state.page,
            this.state.comp
          );
        });
      }
    }
  };

  render() {
    return (
      <div>
        {this.state.proposals
          ? this.state.proposals.map((proposal, i) => {
              return (
                <Card
                  key={i}
                  type={this.props.bigCard ? "" : "small"}
                  proposal={proposal}
                />
              );
            })
          : ""}
        {this.state.users
          ? this.state.users.map((user, i) => {
              return <Candidat key={i} user={user} index={i + 1} />;
            })
          : ""}
        {this.state.loading ? <Loader /> : ""}
        {this.props.callOnClick ? (
          <Button
            color="white"
            background="transparent"
            text="Voir plus"
            type="round"
            click={this.addMoreProposals}
            className="hide-on-desktop"
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ proposals, users }) => {
  return { proposals, users };
};

export default withRouter(
  connect(mapStateToProps, {
    getProposalsAsync,
    listProposalReset,
    listUsersReset,
    getUsersAsync
  })(ClickScroll)
);
