import {
	USER_ONE,
	USER_ONE_SUCCESS,
	USER_ONE_ERROR,
	USER_LIST,
	USER_LIST_SUCCESS,
	USER_LIST_RESET,
	USER_LIST_ERROR
} from "../../constants/actionTypes";

const INIT_STATE = {
    user: null,
    users: null,
    loading: false,
		reset: false,
		comp: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case USER_ONE:
            return { ...state, loading: true };
        case USER_ONE_SUCCESS:
            //notify.success('Login Success');
            return { ...state, loading: false, user: action.payload, reset: false };
        case USER_ONE_ERROR:
            //notify.success('Login Success');
            return { ...state, loading: false, user: action.payload, reset: false };
        case USER_LIST:
            return { ...state, loading: true, reset: false, comp: action.payload };
        case USER_LIST_SUCCESS:
            //notify.success('Login Success');
            return { ...state, loading: false, users: action.payload, reset: false };
        case USER_LIST_ERROR:
            //notify.success('Login Success');
            return { ...state, loading: false, users: action.payload, reset: false };
				case USER_LIST_RESET:
				      return { ...state, users: null, reset: true, comp: action.payload };
        default: return { ...state };
    }
}
