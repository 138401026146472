import React, { Component, Fragment } from "react";
import Header from "../../components/header";
import MainNav from "../../components/mainnav";
import SubjectsPropositions from "../../components/subjectspropositions";
import { animateScroll as scroll } from "react-scroll";
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

// Component for /register
class Confidentialite extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    scroll.scrollToTop({ duration: 200, delay: 0 });
  }

  render() {
    return (
      <div className="home topproposition">
        
        <Helmet>
          <title>
            {"Conditions générales d'utilisation du dispositif MOI, MAIRE de Nice-Matin"}
          </title>
          <meta
            name="description"
            content={
              "Conditions générales d'utilisation • MOI, MAIRE la plateforme participative de Nice-Matin pour les municipales 2020. Faites vos propositions !"
            }
          />
          <meta
            property="og:title"
            content={"Conditions générales d'utilisation du dispositif MOI, MAIRE de Nice-Matin"
            }
          />
          <meta
            property="og:description"
            content={"Conditions générales d'utilisation • MOI, MAIRE la plateforme participative de Nice-Matin pour les municipales 2020. Faites vos propositions !"
            }
          />
          <meta property="og:image" content="" />
          {/*<meta property="og:url" content="http://euro-travel-example.com/index.htm"></meta>*/}
        </Helmet>

        {!this.props.noHeader ? <Header /> : <Fragment />}
        <div className="white-section confidentialite-page">
          <h1>CGU MOI MAIRE</h1>
          <p>&ldquo;Moi maire&rdquo; est une plateforme participative &eacute;dit&eacute;e par le groupe Nice-Matin. Elle est ind&eacute;pendante de tout parti ou organisation politique. C&rsquo;est un site gratuit.</p>
          <p>&nbsp;</p>
          <p>&ldquo;Moi maire&rdquo; permet &agrave; des utilisateurs de faire des propositions pour leur commune sur la plateforme. Les autres Utilisateurs qui le souhaitent peuvent voter pour ces Propositions afin de les soutenir, l&rsquo;objectif &eacute;tant de r&eacute;ussir &agrave; faire &eacute;merger dans la soci&eacute;t&eacute; des r&eacute;flexions sur des questions d&rsquo;int&eacute;r&ecirc;t g&eacute;n&eacute;ral, notamment en mati&egrave;re &eacute;conomiques, sociales, civiques et citoyennes.</p>
          <p>&nbsp;</p>
          <p>Ces r&eacute;flexions pourront alors &ecirc;tre reprises par les supports du groupe Nice-Matin qui aideront &agrave; les m&eacute;diatiser.</p>
          <p>&nbsp;</p>
          <p><strong>OBJET DU SERVICE</strong></p>
          <p>&nbsp;</p>
          <p>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales ont pour objet de d&eacute;finir les modalit&eacute;s et conditions d&rsquo;utilisation des services propos&eacute;s sur &ldquo;Moi maire&rdquo; (ci-apr&egrave;s : les &laquo; Services &raquo;), ainsi que de d&eacute;finir les droits et obligations des parties dans ce cadre.</p>
          <p>&nbsp;</p>
          <p>Elles peuvent &ecirc;tre compl&eacute;t&eacute;es, le cas &eacute;ch&eacute;ant, par des conditions d&rsquo;utilisation particuli&egrave;res &agrave; certains Services, ou par des conditions d&rsquo;utilisation particuli&egrave;res &agrave; certains utilisateurs sp&eacute;cifiques. En cas de contradiction, les conditions particuli&egrave;res pr&eacute;valent sur ces conditions g&eacute;n&eacute;rales.</p>
          <p>&nbsp;</p>
          <p><strong>EXPLOITANT DU SITE ET DES SERVICES</strong></p>
          <p>&nbsp;</p>
          <p>Le Site et les Services (ou collectivement &laquo; les Services &raquo;) sont exploit&eacute;s par le groupe Nice-Matin, Soci&eacute;t&eacute; par Actions Simplifi&eacute;es au capital de 4.848.001 euros, ayant son si&egrave;ge social au 214, boulevard du Mercantour, 06290, NICE, immatricul&eacute;e au RCS de NICE sous le num&eacute;ro 807 856 596.</p>
          <p>&nbsp;</p>
          <p><strong>ACC&Egrave;S AU SITE ET AUX SERVICES</strong></p>
          <p>&nbsp;</p>
          <p>L&rsquo;acc&egrave;s au Site et aux Services est gratuit. Il est ouvert, sous r&eacute;serve des restrictions pr&eacute;vues sur le site: &agrave; toute personne physique disposant de la pleine capacit&eacute; juridique pour s&rsquo;engager au titre des pr&eacute;sentes conditions g&eacute;n&eacute;rales. La personne physique qui ne dispose pas de la pleine capacit&eacute; juridique ne peut acc&eacute;der au Site et aux Services qu&rsquo;avec l&rsquo;accord de son repr&eacute;sentant l&eacute;gal, &agrave; tout mineur disposant de l&rsquo;autorisation de ses repr&eacute;sentants l&eacute;gaux ainsi que sous leur contr&ocirc;le, &agrave; toute personne morale agissant par l&rsquo;interm&eacute;diaire d&rsquo;une personne physique disposant de la capacit&eacute; juridique pour contracter au nom et pour le compte de la personne morale.</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>ACCEPTATION DES CONDITIONS G&Eacute;N&Eacute;RALES</strong></p>
          <p>&nbsp;</p>
          <p>Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation (ci-apr&egrave;s &laquo; CGUS &raquo;) ont pour objet de d&eacute;finir les modalit&eacute;s selon lesquelles l&rsquo;Utilisateur pourra acc&eacute;der et utiliser les Services. Elles constituent un contrat entre &ldquo;Moi Maire&rdquo; et les Utilisateurs du Service. Elles annulent et remplacent toutes les dispositions ant&eacute;rieures et constituent l&rsquo;int&eacute;gralit&eacute; des droits et obligations des parties. Les CGUS sont communiqu&eacute;es &agrave; chaque Utilisateur qui en prend connaissance.</p>
          <p>&nbsp;</p>
          <p>L'utilisation du Service implique l&rsquo;acceptation enti&egrave;re et sans r&eacute;serve des pr&eacute;sentes CGUS. La non-acceptation entra&icirc;ne ainsi la renonciation &agrave; l&rsquo;utilisation de celui-ci.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;utilisation du Service implique &eacute;galement l&rsquo;acceptation enti&egrave;re et sans r&eacute;serve de la Politique d'utilisation des donn&eacute;es de &ldquo;Moi maire&rdquo; qui fait partie int&eacute;grante des pr&eacute;sentes CGUS.</p>
          <p>&nbsp;</p>
          <p>Les pr&eacute;sentes CGUS sont modifiables &agrave; tout moment et sans pr&eacute;avis par &ldquo;Moi maire&rdquo;. Toute modification prendra effet imm&eacute;diatement &agrave; compter de la mise en ligne de la nouvelle version des CGUS sur le Site. L&rsquo;Utilisateur est donc invit&eacute; &agrave; consulter r&eacute;guli&egrave;rement la derni&egrave;re version des CGUS sur le Site. &Agrave; d&eacute;faut, il sera r&eacute;put&eacute; accepter sans r&eacute;serve la nouvelle version des Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation.</p>
          <p>&nbsp;</p>
          <p><strong>UTILISATION DU SITE</strong></p>
          <p>&nbsp;</p>
          <p>Acc&egrave;s au site</p>
          <p>Le site est ouvert au public et tous les Utilisateurs peuvent le visiter.</p>
          <p>&nbsp;</p>
          <p>Utilisateurs inscrits</p>
          <p>Seuls les Utilisateurs inscrits peuvent faire des Propositions citoyennes et voter pour les autres Propositions sur le site. &Agrave; cette fin, ceux qui le souhaitent peuvent s&rsquo;inscrire sur le site en remplissant le formulaire pr&eacute;vu &agrave; cet effet. Ils doivent alors fournir l&rsquo;ensemble des informations marqu&eacute;es comme obligatoires. Toute inscription incompl&egrave;te ne sera pas valid&eacute;e.</p>
          <p>&nbsp;</p>
          <p>Les Utilisateurs inscrits sur le site sont sp&eacute;cifiquement d&eacute;finis comme &ldquo;Les Utilisateurs Inscrits&rdquo;.</p>
          <p>&nbsp;</p>
          <p>Les Utilisateurs inscrits garantissent que toutes les informations donn&eacute;es dans le formulaire d&rsquo;inscription sont exactes, &agrave; jour et sinc&egrave;res et ne sont entach&eacute;es d&rsquo;aucun caract&egrave;re trompeur.</p>
          <p>&nbsp;</p>
          <p>Il s&rsquo;engage &agrave; mettre &agrave; jour ces informations dans son Espace Personnel en contactant &ldquo;Moi Maire&rdquo; par courriel &agrave; l&rsquo;adresse moimaire@nicematin.fr.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur Inscrit est inform&eacute; et accepte que les informations saisies aux fins de cr&eacute;ation ou de mise &agrave; jour de son Compte vaillent preuve de son identit&eacute;. Les informations saisies par l&rsquo;Utilisateur l&rsquo;engagent d&egrave;s leur validation.</p>
          <p>&nbsp;</p>
          <p>Compte et Espace personnel</p>
          <p>&nbsp;</p>
          <p>L&rsquo;inscription entra&icirc;ne automatiquement l&rsquo;ouverture d&rsquo;un compte (ci-apr&egrave;s : le &laquo; Compte &raquo;), donnant acc&egrave;s &agrave; un espace personnel (ci-apr&egrave;s : l&rsquo;&laquo; Espace Personnel &raquo;) qui permet de g&eacute;rer l&rsquo;utilisation des Services sous une forme et selon les moyens techniques que &ldquo;Moi maire&rdquo; juge les plus appropri&eacute;s et qui peuvent &eacute;voluer dans le temps.</p>
          <p>&nbsp;</p>
          <p>Les Utilisateurs inscrits peuvent acc&eacute;der &agrave; tout moment &agrave; leur Espace Personnel apr&egrave;s s&rsquo;&ecirc;tre identifi&eacute;s &agrave; l&rsquo;aide de leur identifiant de connexion ainsi que de leur mot de passe.</p>
          <p>&nbsp;</p>
          <p>Les Utilisateurs inscrits s&rsquo;engagent &agrave; avoir une utilisation uniquement personnelle des Services et &agrave; ne permettre &agrave; aucun tiers de les utiliser &agrave; leur place ou pour leur compte, sauf &agrave; en supporter l&rsquo;enti&egrave;re responsabilit&eacute;.</p>
          <p>&nbsp;</p>
          <p>Les Utilisateurs inscrits sont pareillement responsables du maintien de la confidentialit&eacute; de leur identifiant et de leur mot de passe. Ils doivent imm&eacute;diatement contacter &ldquo;Moi maire&rdquo; s&rsquo;ils remarquaient que leur Compte a &eacute;t&eacute; utilis&eacute; &agrave; leur insu. Ils reconnaissent &agrave; &ldquo;Moi maire&rdquo; le droit de prendre toutes mesures appropri&eacute;es en pareil cas.</p>
          <p>&nbsp;</p>
          <p><strong>DESCRIPTION DES SERVICES</strong></p>
          <p>&nbsp;</p>
          <p>Propositions Citoyennes</p>
          <p>La plateforme &ldquo;Moi maire&rdquo; propose aux Utilisateurs de soumettre et de voter sur des Propositions citoyennes qui ont &eacute;t&eacute; propos&eacute;es par d&rsquo;autres Utilisateurs.</p>
          <p>&nbsp;</p>
          <p>Les propositions citoyennes d&eacute;pos&eacute;es sur &ldquo;Moi maire&rdquo; ont toutes une &eacute;gale chance d&rsquo;&ecirc;tre vue par les Utilisateurs.</p>
          <p>&nbsp;</p>
          <p>Formuler une Proposition Citoyenne</p>
          <p>Les Utilisateurs Inscrits peuvent &eacute;mettre leurs propres Propositions citoyennes qui sont destin&eacute;es &agrave; &ecirc;tre publi&eacute;es sur &ldquo;Moi maire&rdquo; et qui seront soumises au vote des Utilisateurs. Elles pourront &ecirc;tre amen&eacute;es &agrave; analys&eacute;es et d&eacute;battues sur les autres supports du groupe Nice-Matin.</p>
          <p>&nbsp;</p>
          <p>Forme et contenu des Propositions Citoyennes</p>
          <p>Chaque Proposition citoyenne doit n&eacute;cessairement commencer par &ldquo;Moi maire&rdquo; et contenir un nombre maximum de 140 caract&egrave;res.</p>
          <p>&nbsp;</p>
          <p>La Proposition Citoyenne doit &ecirc;tre lisible et r&eacute;dig&eacute;e en langue fran&ccedil;aise, sous une forme permettant d&rsquo;&ecirc;tre comprise de tous, sans langage abr&eacute;g&eacute; et sans abus des lettres majuscules.</p>
          <p>&nbsp;</p>
          <p>La proposition ne doit pas contenir d&rsquo;&eacute;l&eacute;ments qui seraient contraires &agrave; la loi, aux bonnes m&oelig;urs ou dont les termes contreviendraient aux dispositions des pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation.</p>
          <p>&nbsp;</p>
          <p>Mod&eacute;ration et publication de la Proposition Citoyenne</p>
          <p>&nbsp;</p>
          <p>La publication de la Proposition Citoyenne de l&rsquo;Utilisateur est soumise &agrave; mod&eacute;ration dans les conditions d&eacute;finies aux pr&eacute;sentes CGUS. La demande de publication d&rsquo;une Proposition Citoyenne sera trait&eacute;e le plus rapidement qu&rsquo;il est possible aux &eacute;quipes de &ldquo;Moi maire&rdquo;, avec l&rsquo;objectif de r&eacute;pondre en moins de 48 heures.</p>
          <p>&nbsp;</p>
          <p>Le service de mod&eacute;ration de &ldquo;Moi maire&rdquo; examine chaque Proposition Citoyenne re&ccedil;ue avant de la mettre en ligne. En cons&eacute;quence, l&rsquo;Utilisateur doit veiller &agrave; ne pas r&eacute;it&eacute;rer inutilement l&rsquo;envoi d&rsquo;une proposition de Proposition citoyenne et &agrave; ne pas soumettre une m&ecirc;me proposition de Proposition citoyenne. Il ne devra pas non plus spammer les Services en proposant les m&ecirc;mes Solutions avec des adresses &eacute;lectroniques diff&eacute;rentes.</p>
          <p>&nbsp;</p>
          <p>D&egrave;s validation de la Proposition citoyenne de l&rsquo;Utilisateur, celle-ci fera l&rsquo;objet d&rsquo;une publication sur le Service et une notification de publication sera adress&eacute;e &agrave; son auteur.</p>
          <p>&nbsp;</p>
          <p>En cas de rejet de la Proposition citoyenne de l&rsquo;Utilisateur, un courrier &eacute;lectronique lui sera adress&eacute; par &ldquo;Moi maire&rdquo; lui notifiant ledit rejet. L&rsquo;Utilisateur pourra alors librement soumettre une nouvelle Proposition citoyenne.</p>
          <p>&nbsp;</p>
          <p>Une fois publi&eacute;e sur le Service, elle pourra &ecirc;tre mise en avant sur les Services, sans pour autant que &ldquo;Moi maire&rdquo; garantisse ni sa fr&eacute;quence d&rsquo;apparition, ni son audience.</p>
          <p>&nbsp;</p>
          <p>&ldquo;Moi maire&rdquo; s'engage &agrave; ne pas modifier arbitrairement les contenus licites et r&eacute;pondant &agrave; ses r&egrave;gles de mod&eacute;ration qui lui seraient propos&eacute;s, en dehors des &eacute;ventuelles corrections li&eacute;es &agrave; l'orthographe.</p>
          <p>&nbsp;</p>
          <p>Demande de suppression d'une Proposition Citoyenne par un Utilisateur inscrit</p>
          <p>Dans l&rsquo;hypoth&egrave;se o&ugrave; un Utilisateur inscrit souhaiterait que sa Proposition citoyenne publi&eacute;e fasse l&rsquo;objet d&rsquo;une suppression, il adressera sa demande par mail &agrave; &ldquo;Moi maire&rdquo; &agrave; l&rsquo;adresse suivante : moimaire@nicematin.fr.</p>
          <p>&nbsp;</p>
          <p>La demande de suppression de l&rsquo;Utilisateur sera trait&eacute;e dans un d&eacute;lai raisonnable par &ldquo;Moi maire&rdquo;.</p>
          <p>&nbsp;</p>
          <p>Voter sur une Proposition Citoyenne</p>
          <p>Les Utilisateurs peuvent tous se prononcer par le biais de votes sur les propositions citoyennes pr&eacute;sentes sur le Site, apr&egrave;s avoir cr&eacute;er un compte, en cliquant sur le &ldquo;pouce&rdquo;. Le bouton &ldquo;pouce&rdquo; signifie que l&rsquo;Utilisateur soutient la proposition.</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>Utilisation des Propositions Citoyennes</p>
          <p>Utilisation &agrave; des fins statistiques : &ldquo;Moi maire&rdquo; pourra utiliser les Propositions citoyennes, agr&eacute;g&eacute;es ou non et expurg&eacute;es de toutes donn&eacute;es personnelles, en ce compris des compilations, synth&egrave;ses &agrave; des fins statistiques, d&rsquo;&eacute;tudes ou &agrave; toutes autres fins.</p>
          <p>&nbsp;</p>
          <p>Utilisation pour des d&eacute;bats: Les Propositions citoyennes publi&eacute;es sur le Service pourront &ecirc;tre s&eacute;lectionn&eacute;es par &ldquo;Moi maire&rdquo;, afin d&rsquo;&ecirc;tre analys&eacute;es, comment&eacute;es et/ou d&eacute;battues &agrave; l&rsquo;occasion de d&eacute;bats publics organis&eacute;s par le groupe Nice-Matin.</p>
          <p>&nbsp;</p>
          <p>Utilisation &eacute;ditoriale : Les Propositions citoyennes, et les votes y aff&eacute;rents, pourront en outre &ecirc;tre repris, comment&eacute;s et analys&eacute;s par le groupe Nice-Matin et des journalistes partenaires afin de r&eacute;aliser des contenus &eacute;ditoriaux.</p>
          <p>&nbsp;</p>
          <p>Utilisation &agrave; des fins de communication: Les Propositions citoyennes pourront &eacute;galement &ecirc;tre utilis&eacute;es par &ldquo;Moi maire&rdquo; sur les supports du groupe Nice-Matin et faire l&rsquo;objet d&rsquo;affichage sur des espaces publicitaires exploit&eacute;s par les partenaires de &ldquo;Moi maire&rdquo;. Dans ce cas, l&rsquo;utilisation d&rsquo;une Proposition citoyenne sur lesdits espaces publicitaires donnera lieu &agrave; la publication de la Proposition de mani&egrave;re anonyme si elle a &eacute;t&eacute; publi&eacute;e anonymement ou sous-titr&eacute;e avec le pr&eacute;nom de l&rsquo;Utilisateur.</p>
          <p>&nbsp;</p>
          <p><strong>PREUVE </strong></p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur reconna&icirc;t et accepte express&eacute;ment: que les donn&eacute;es recueillies sur le site et sur les &eacute;quipements informatiques de &ldquo;Moi maire&rdquo; font foi de la r&eacute;alit&eacute; des op&eacute;rations intervenues dans le cadre des pr&eacute;sentes ; que ces donn&eacute;es constituent le seul mode de preuve admis entre les parties.</p>
          <p>&nbsp;</p>
          <p><strong>OBLIGATIONS DE L'UTILISATEUR</strong></p>
          <p>&nbsp;</p>
          <p>Sans pr&eacute;judice des autres obligations pr&eacute;vues aux pr&eacute;sentes, l&rsquo;Utilisateur s&rsquo;engage &agrave; respecter les obligations qui suivent :</p>
          <p>&nbsp;</p>
          <p>Respect des lois et r&egrave;glements</p>
          <p>L&rsquo;Utilisateur s&rsquo;engage, dans son usage des Services, &agrave; respecter les lois et r&egrave;glements en vigueur et &agrave; ne pas porter atteinte aux droits de tiers ou &agrave; l&rsquo;ordre public. Il est notamment seul responsable du bon accomplissement de toutes les formalit&eacute;s notamment administratives, fiscales et/ ou sociales et de tous les paiements de cotisations, taxes ou imp&ocirc;ts de toutes natures qui lui incombent, le cas &eacute;ch&eacute;ant, en relation avec son utilisation des Services. La responsabilit&eacute; de &ldquo;Moi maire&rdquo; ne pourra en aucun cas &ecirc;tre engag&eacute;e &agrave; ce titre.</p>
          <p>&nbsp;</p>
          <p>Usage du Site et des Services</p>
          <p>L&rsquo;Utilisateur reconna&icirc;t avoir pris connaissance sur le site des caract&eacute;ristiques et contraintes, notamment techniques, de l&rsquo;ensemble des Services. Il est seul responsable de son utilisation des Services.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur est inform&eacute; et accepte que la mise en &oelig;uvre des Services n&eacute;cessite qu&rsquo;il soit connect&eacute; &agrave; internet et que la qualit&eacute; des Services d&eacute;pend directement de cette connexion, dont il est seul responsable.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur s&rsquo;engage &agrave; ne pas publier des propositions &agrave; caract&egrave;re publicitaire ou faisant la promotion de services &agrave; but lucratif. L&rsquo;Utilisateur s&rsquo;engage &agrave; ne pas publier de propositions qui seraient d&eacute;pourvues de s&eacute;rieux ou hors sujet. L&rsquo;Utilisateur s&rsquo;engage &agrave; faire un usage strictement personnel des Services. Il s&rsquo;interdit en cons&eacute;quence de c&eacute;der, conc&eacute;der ou transf&eacute;rer tout ou partie de ses droits ou obligations au titre des pr&eacute;sentes &agrave; un tiers, de quelque mani&egrave;re que ce soit.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur s&rsquo;engage &agrave; ne pas publier des propositions faisant la promotion ou d&eacute;nigrant des partis, des organisations ou des personnalit&eacute;s publiques.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur est &eacute;galement seul responsable des relations qu&rsquo;il pourra nouer avec les autres Utilisateurs et des informations qu&rsquo;il leur communique dans le cadre des Services. Il lui appartient d&rsquo;exercer la prudence et le discernement appropri&eacute;s dans ces relations et communications. L&rsquo;Utilisateur s&rsquo;engage en outre, dans ses &eacute;changes avec les autres Utilisateurs, &agrave; respecter les r&egrave;gles usuelles de politesse et de courtoisie.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur s&rsquo;engage &agrave; ne pas tenir de propos ou faire de propositions contraires &agrave; la loi et aux bonnes m&oelig;urs et notamment, sans que cette liste ne soit exhaustive :</p>
          <p>des propos incitant &agrave; la haine raciale, les propos racistes, antis&eacute;mites, x&eacute;nophobes homophobes, etc&hellip; ;</p>
          <p>des propos &agrave; caract&egrave;re violent, pornographique, p&eacute;dophile, etc&hellip; ;</p>
          <p>des propos injurieux, d&eacute;nigrants, diffamatoires, ou portant atteinte aux droits de la personnalit&eacute; de tiers ;</p>
          <p>des propos n&eacute;gationnistes de crimes contre l&rsquo;humanit&eacute; et de g&eacute;nocides reconnus et l&rsquo;apologie de crimes ;</p>
          <p>des propos incitant &agrave; commettre des actes r&eacute;prim&eacute;s par la loi telle que l&rsquo;incitation &agrave; commettre des actes violents, terroristes, &agrave; la vente de produits stup&eacute;fiants, etc. ;</p>
          <p>des propos portant atteintes &agrave; la vie priv&eacute;e ou aux droits de la propri&eacute;t&eacute; intellectuelle de tiers,</p>
          <p>des propos portant atteinte &agrave; la pr&eacute;somption d&rsquo;innocence ou un secret de l&rsquo;instruction, etc. ;</p>
          <p>des propos portant atteinte &agrave; la dignit&eacute; humaine ;</p>
          <p>des propos pouvant &ecirc;tre consid&eacute;r&eacute;s comme relevant d&rsquo;un abus de libert&eacute; d&rsquo;expression.</p>
          <p>&nbsp;</p>
          <p>Relation &agrave; &ldquo;Moi maire&rdquo;</p>
          <p>L&rsquo;Utilisateur s&rsquo;engage &agrave; fournir &agrave; &ldquo;Moi maire&rdquo; toutes les informations n&eacute;cessaires &agrave; la bonne ex&eacute;cution des Services. Plus g&eacute;n&eacute;ralement, l&rsquo;Utilisateur s&rsquo;engage &agrave; coop&eacute;rer activement avec &ldquo;Moi maire&rdquo; en vue de la bonne ex&eacute;cution des pr&eacute;sentes.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur s&rsquo;engage &agrave; ce que ses propositions soient reproduites selon les r&egrave;gles d&rsquo;indexation, de mise en forme et de mise en valeur de &ldquo;Moi maire&rdquo;, en sa qualit&eacute; d&rsquo;&eacute;diteur du Service.</p>
          <p>&nbsp;</p>
          <p><strong>GARANTIES DE L'UTILISATEUR</strong></p>
          <p>&nbsp;</p>
          <p>Contenus</p>
          <p>L&rsquo;Utilisateur est seul responsable des contenus de toute nature (r&eacute;dactionnels, graphiques, audiovisuels ou autres, en ce compris la d&eacute;nomination et/ou l&rsquo;image &eacute;ventuellement choisies par l&rsquo;Utilisateur pour l&rsquo;identifier sur le site) qu&rsquo;il diffuse dans le cadre des Services (ci-apr&egrave;s d&eacute;sign&eacute;s : les &laquo; Contenus &raquo;).</p>
          <p>&nbsp;</p>
          <p>Il garantit &agrave; &ldquo;Moi maire&rdquo; qu&rsquo;il dispose de tous les droits et autorisations n&eacute;cessaires &agrave; la diffusion de ces Contenus.</p>
          <p>&nbsp;</p>
          <p>Il s&rsquo;engage &agrave; ce que lesdits Contenus soient licites, ne portent pas atteinte &agrave; l&rsquo;ordre public, aux bonnes m&oelig;urs ou aux droits de tiers, n&rsquo;enfreignent aucune disposition l&eacute;gislative ou r&egrave;glementaire et plus g&eacute;n&eacute;ralement, ne soient aucunement susceptibles de mettre en jeu la responsabilit&eacute; civile ou p&eacute;nale de &ldquo;Moi maire&rdquo;.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur s&rsquo;interdit ainsi de diffuser, notamment et sans que cette liste soit exhaustive :</p>
          <p>des Contenus pornographiques, obsc&egrave;nes, ind&eacute;cents, choquants ou inadapt&eacute;s &agrave; un public familial, diffamatoires, injurieux, violents, racistes, x&eacute;nophobes ou r&eacute;visionnistes,</p>
          <p>des Contenus contrefaisants,</p>
          <p>des Contenus attentatoires &agrave; l&rsquo;image d&rsquo;un tiers,</p>
          <p>des Contenus mensongers, trompeurs ou proposant ou promouvant des activit&eacute;s illicites, frauduleuses ou trompeuses,</p>
          <p>des Contenus nuisibles aux syst&egrave;mes informatiques de tiers (tels que virus, vers, chevaux de Troie, etc.),</p>
          <p>et plus g&eacute;n&eacute;ralement des Contenus susceptibles de porter atteinte aux droits de tiers ou d&rsquo;&ecirc;tre pr&eacute;judiciables &agrave; des tiers, de quelque mani&egrave;re et sous quelque forme que ce soit.</p>
          <p>&nbsp;</p>
          <p>Limites de l'usage des Services</p>
          <p>L&rsquo;Utilisateur reconna&icirc;t que les Services lui offrent une solution suppl&eacute;mentaire mais non alternative des moyens qu&rsquo;il utilise d&eacute;j&agrave; par ailleurs pour atteindre le m&ecirc;me objectif et que cette solution ne saurait se substituer &agrave; ces autres moyens.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur doit prendre les mesures n&eacute;cessaires pour sauvegarder par ses propres moyens les informations de son Espace Personnel qu&rsquo;il juge n&eacute;cessaires.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur est inform&eacute; et accepte que la mise en &oelig;uvre des Services n&eacute;cessite qu&rsquo;il soit connect&eacute; &agrave; internet et que la qualit&eacute; des Services d&eacute;pend directement de cette connexion, dont il est seul responsable.</p>
          <p>&nbsp;</p>
          <p>R&eacute;clamations et indemnit&eacute;s</p>
          <p>L&rsquo;Utilisateur garantit &ldquo;Moi maire&rdquo; contre toutes plaintes, r&eacute;clamations, actions et revendications quelconques que &ldquo;Moi maire&rdquo; pourrait subir du fait de la violation, par l&rsquo;Utilisateur de l&rsquo;une quelconque de ses obligations ou garanties aux termes des pr&eacute;sentes conditions g&eacute;n&eacute;rales.</p>
          <p>&nbsp;</p>
          <p>Il s&rsquo;engage &agrave; indemniser &ldquo;Moi maire&rdquo; de tout pr&eacute;judice qu&rsquo;elle subirait et &agrave; lui payer tous les frais, charges et/ou condamnations qu&rsquo;elle pourrait avoir &agrave; supporter de ce fait.</p>
          <p>&nbsp;</p>
          <p><strong>COMPORTEMENTS PROHIBES</strong></p>
          <p>&nbsp;</p>
          <p>Il est strictement interdit d&rsquo;utiliser les Services aux fins suivantes :</p>
          <p>l&rsquo;exercice d&rsquo;activit&eacute;s ill&eacute;gales, frauduleuses ou portant atteinte aux droits ou &agrave; la s&eacute;curit&eacute; des tiers,</p>
          <p>l&rsquo;atteinte &agrave; l&rsquo;ordre public ou la violation des lois et r&egrave;glements en vigueur,</p>
          <p>l&rsquo;intrusion dans le syst&egrave;me informatique d&rsquo;un tiers ou toute activit&eacute; de nature &agrave; nuire, contr&ocirc;ler, interf&eacute;rer, ou intercepter tout ou partie du syst&egrave;me informatique d&rsquo;un tiers, en violer l&rsquo;int&eacute;grit&eacute; ou la s&eacute;curit&eacute;,</p>
          <p>l&rsquo;envoi d&rsquo;emails non sollicit&eacute;s et/ou de prospection ou sollicitation commerciale,</p>
          <p>les manipulations destin&eacute;es &agrave; am&eacute;liorer le r&eacute;f&eacute;rencement d&rsquo;un site tiers,</p>
          <p>l&rsquo;aide ou l&rsquo;incitation, sous quelque forme et de quelque mani&egrave;re que ce soit, &agrave; un ou plusieurs des actes et activit&eacute;s d&eacute;crits ci-dessus,</p>
          <p>et plus g&eacute;n&eacute;ralement toute pratique d&eacute;tournant les Services &agrave; des fins autres que celles pour lesquelles ils ont &eacute;t&eacute; con&ccedil;us.</p>
          <p>&nbsp;</p>
          <p>Il est strictement interdit aux Utilisateurs de copier et/ou de d&eacute;tourner &agrave; leurs fins ou &agrave; celles de tiers le concept, les technologies ou tout autre &eacute;l&eacute;ment du site de &ldquo;Moi maire&rdquo;.</p>
          <p>&nbsp;</p>
          <p>Sont &eacute;galement strictement interdits : (i) tous comportements de nature &agrave; interrompre, suspendre, ralentir ou emp&ecirc;cher la continuit&eacute; des Services, (ii) toutes intrusions ou tentatives d&rsquo;intrusions dans les syst&egrave;mes de &ldquo;Moi maire&rdquo;, (iii) tous d&eacute;tournements des ressources syst&egrave;me du site, (iv) toutes actions de nature &agrave; imposer une charge disproportionn&eacute;e sur les infrastructures de ce dernier, (v) toutes atteintes aux mesures de s&eacute;curit&eacute; et d&rsquo;authentification, (vi) tous actes de nature &agrave; porter atteinte aux droits et int&eacute;r&ecirc;ts financiers, commerciaux ou moraux de &ldquo;Moi maire&rdquo; ou des usagers de son site, et enfin plus g&eacute;n&eacute;ralement (vii) tout manquement aux pr&eacute;sentes conditions g&eacute;n&eacute;rales.</p>
          <p>&nbsp;</p>
          <p>Il est strictement interdit de monnayer, vendre ou conc&eacute;der tout ou partie de l&rsquo;acc&egrave;s aux Services ou au site, ainsi qu&rsquo;aux informations qui y sont h&eacute;berg&eacute;es et/ou partag&eacute;es.</p>
          <p>&nbsp;</p>
          <p>LIMITATIONS DE GARANTIE DE MOI MAIRE</p>
          <p>&nbsp;</p>
          <p>Qualit&eacute; de service</p>
          <p>&ldquo;Moi maire&rdquo; ne peut garantir que le Service ne subira aucune interruption. &ldquo;Moi maire&rdquo; s&rsquo;engage &agrave; fournir le Service avec diligence et selon les r&egrave;gles de l&rsquo;art, &eacute;tant rappel&eacute; qu&rsquo;il ne s&rsquo;agit que d&rsquo;une obligation de moyens, ce que les Utilisateurs reconnaissent et acceptent express&eacute;ment.</p>
          <p>&nbsp;</p>
          <p>&ldquo;Moi maire&rdquo; s&rsquo;engage &agrave; proc&eacute;der r&eacute;guli&egrave;rement &agrave; des contr&ocirc;les afin de v&eacute;rifier le fonctionnement et l&rsquo;accessibilit&eacute; du site. A ce titre, &ldquo;Moi maire&rdquo; se r&eacute;serve la facult&eacute; d&rsquo;interrompre momentan&eacute;ment l&rsquo;acc&egrave;s au site pour des raisons de maintenance. De m&ecirc;me, &ldquo;Moi maire&rdquo; ne saurait &ecirc;tre tenue responsable des difficult&eacute;s ou impossibilit&eacute;s momentan&eacute;es d&rsquo;acc&egrave;s au site qui auraient pour origine des circonstances qui lui sont ext&eacute;rieures, la force majeure, ou encore qui seraient dues &agrave; des perturbations des r&eacute;seaux de t&eacute;l&eacute;communication.</p>
          <p>&nbsp;</p>
          <p>&ldquo;Moi maire&rdquo; ne garantit pas aux Utilisateurs (i) que les Services, soumis &agrave; une recherche constante pour en am&eacute;liorer notamment la performance et le progr&egrave;s, seront totalement exempts d&rsquo;erreurs, de vices ou d&eacute;fauts, (ii) que les Services, &eacute;tant standard et nullement propos&eacute;s &agrave; la seule intention d&rsquo;un Utilisateur donn&eacute; en fonction de ses propres contraintes personnelles, r&eacute;pondront sp&eacute;cifiquement &agrave; ses besoins et attentes.</p>
          <p>&nbsp;</p>
          <p>Contenus</p>
          <p>Bien que les Services fassent l&rsquo;objet d&rsquo;une mod&eacute;ration, &ldquo;Moi maire&rdquo; ne peut &ecirc;tre tenu pour responsable des Contenus, dont les auteurs sont des tiers, toute r&eacute;clamation &eacute;ventuelle devant &ecirc;tre dirig&eacute;e en premier lieu vers l&rsquo;auteur des Contenus en question.</p>
          <p>&nbsp;</p>
          <p>Les Contenus pr&eacute;judiciables &agrave; un tiers peuvent faire l&rsquo;objet d&rsquo;une notification &agrave; &ldquo;Moi maire&rdquo; selon les modalit&eacute;s pr&eacute;vues par l&rsquo;article 6 I 5 de la loi n&deg; 2004-575 du 21 juin 2004 pour la confiance dans l'&eacute;conomie num&eacute;rique, &ldquo;Moi maire&rdquo; se r&eacute;servant de prendre les mesures d&eacute;crites &agrave; l&rsquo;article 12.</p>
          <p>&nbsp;</p>
          <p>Perte d'informations</p>
          <p>Le service &eacute;tant fourni &agrave; titre gratuit, &ldquo;Moi maire&rdquo; d&eacute;cline toute responsabilit&eacute; en cas de perte &eacute;ventuelle des informations accessibles dans l&rsquo;Espace Personnel de l&rsquo;Utilisateur, celui-ci devant en sauvegarder une copie et ne pouvant pr&eacute;tendre &agrave; aucun d&eacute;dommagement &agrave; ce titre.</p>
          <p>&nbsp;</p>
          <p>Dommages</p>
          <p>En tout &eacute;tat de cause, la responsabilit&eacute; susceptible d&rsquo;&ecirc;tre encourue par &ldquo;Moi maire&rdquo; au titre des pr&eacute;sentes est express&eacute;ment limit&eacute;e aux seuls dommages directs av&eacute;r&eacute;s subis par l&rsquo;Utilisateur.</p>
          <p>&nbsp;</p>
          <p><strong>PROPRI&Eacute;T&Eacute; INTELLECTUELLE</strong></p>
          <p>&nbsp;</p>
          <p>Propri&eacute;t&eacute; de &ldquo;Moi maire&rdquo;</p>
          <p>&ldquo;Moi maire&rdquo; ne revendique aucune propri&eacute;t&eacute; sur les Donn&eacute;es et les Contenus fournis par les Utilisateurs.</p>
          <p>&nbsp;</p>
          <p>Les syst&egrave;mes, logiciels, structures, infrastructures, bases de donn&eacute;es et contenus de toute nature (textes, images, visuels, musiques, logos, marques, base de donn&eacute;es, etc &hellip;) exploit&eacute;s par &ldquo;Moi maire&rdquo; au sein du site sont prot&eacute;g&eacute;s par tous droits de propri&eacute;t&eacute; intellectuelle ou droits des producteurs de bases de donn&eacute;es en vigueur.</p>
          <p>&nbsp;</p>
          <p>Toute reproduction, repr&eacute;sentation, publication, transmission ou plus g&eacute;n&eacute;ralement toute exploitation non autoris&eacute;e de tout ou partie du Service et des informations qui y sont contenues, sans autorisation expresse de &ldquo;Moi maire&rdquo;, engagera la responsabilit&eacute; de l&rsquo;Utilisateur.</p>
          <p>&nbsp;</p>
          <p>Tous d&eacute;sassemblages, d&eacute;compilations, d&eacute;cryptages, extractions, r&eacute;utilisations, copies et plus g&eacute;n&eacute;ralement, tous actes de reproduction, repr&eacute;sentation, diffusion et utilisation de l&rsquo;un quelconque de ces &eacute;l&eacute;ments, en tout ou partie, sans l&rsquo;autorisation de &ldquo;Moi maire&rdquo; sont strictement interdits et pourront faire l&rsquo;objet de poursuites judiciaires.</p>
          <p>&nbsp;</p>
          <p>Propri&eacute;t&eacute; des Propositions Citoyennes</p>
          <p>Les Propositions citoyennes elles-m&ecirc;mes sont la propri&eacute;t&eacute; de leurs auteurs lesquels conc&egrave;dent &agrave; &ldquo;Moi maire&rdquo; une licence d&rsquo;exploitation non-exclusive, transf&eacute;rable et gratuite, pour la France et pour l&rsquo;ensemble du monde, pour tout usage en ligne et pour tout mode de diffusion, pendant la dur&eacute;e des pr&eacute;sentes CGUS et pour l&rsquo;ensemble des usages vis&eacute;s par celles-ci.</p>
          <p>&nbsp;</p>
          <p><strong>LIENS ET SITES TIERS</strong></p>
          <p>&nbsp;</p>
          <p>&ldquo;Moi maire&rdquo; ne pourra en aucun cas &ecirc;tre tenue pour responsable de la disponibilit&eacute; technique de sites internet ou d&rsquo;applications mobiles exploit&eacute;s par des tiers (y compris ses &eacute;ventuels partenaires) auxquels l&rsquo;Utilisateur acc&eacute;derait par l'interm&eacute;diaire du site.</p>
          <p>&nbsp;</p>
          <p>&ldquo;Moi maire&rdquo; n'endosse aucune responsabilit&eacute; au titre des contenus, publicit&eacute;s, produits et/ou services disponibles sur de tels sites et applications mobiles tiers dont il est rappel&eacute; qu&rsquo;ils sont r&eacute;gis par leurs propres conditions d&rsquo;utilisation.</p>
          <p>&nbsp;</p>
          <p>&ldquo;Moi maire&rdquo; n'est pas non plus responsable des transactions intervenues entre l&rsquo;Utilisateur et un quelconque annonceur, professionnel ou commer&ccedil;ant (y compris ses &eacute;ventuels partenaires) vers lequel l&rsquo;Utilisateur serait orient&eacute; par l'interm&eacute;diaire du site et ne saurait en aucun cas &ecirc;tre partie &agrave; quelques litiges &eacute;ventuels que ce soit avec ces tiers concernant notamment la livraison de produits et/ou services, les garanties, d&eacute;clarations et autres obligations quelconques auxquelles ces tiers sont tenus.</p>
          <p>&nbsp;</p>
          <p><strong>SANCTIONS ET RESILISATION</strong></p>
          <p>&nbsp;</p>
          <p>En cas de manquement &agrave; l&rsquo;une quelconque des dispositions des pr&eacute;sentes conditions g&eacute;n&eacute;rales ou plus g&eacute;n&eacute;ralement, d&rsquo;infraction aux lois et r&egrave;glements en vigueur par un Utilisateur, &ldquo;Moi maire&rdquo; se r&eacute;serve le droit de prendre toute mesure appropri&eacute;e et notamment de :</p>
          <p>suspendre ou r&eacute;silier l&rsquo;acc&egrave;s aux Services de l&rsquo;Utilisateur, auteur du manquement ou de l&rsquo;infraction, ou y ayant particip&eacute;, supprimer tout contenu mis en ligne sur le site, publier sur le site tout message d&rsquo;information que &ldquo;Moi maire&rdquo; jugera utile, avertir toute autorit&eacute; concern&eacute;e,</p>
          <p>engager toute action judiciaire.</p>
          <p>&nbsp;</p>
          <p>De fa&ccedil;on g&eacute;n&eacute;rale, en cas de manquement de l&rsquo;Utilisateur &agrave; ses obligations pr&eacute;vues aux pr&eacute;sentes et/ou en cas de violation de toute loi ou r&egrave;glement applicable, &ldquo;Moi maire&rdquo; pourra r&eacute;silier &agrave; son encontre les pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation de plein droit et sans pr&eacute;avis ni formalit&eacute; pr&eacute;alable.</p>
          <p>&nbsp;</p>
          <p>La r&eacute;siliation des pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation entra&icirc;nera la fin de l&rsquo;acc&egrave;s aux Services pour l&rsquo;Utilisateur concern&eacute;, ainsi que la suppression de ses donn&eacute;es et de ses contenus. L&rsquo;Utilisateur sera inform&eacute; de ladite r&eacute;siliation par courriel, &agrave; l&rsquo;adresse qu&rsquo;il a renseign&eacute;e lors de son inscription. La r&eacute;siliation interviendra sans pr&eacute;judice des dommages et int&eacute;r&ecirc;ts que pourrait r&eacute;clamer &ldquo;Moi maire&rdquo; en r&eacute;paration des pr&eacute;judices &eacute;ventuellement subis du fait des manquements reproch&eacute;s &agrave; l&rsquo;Utilisateur. &ldquo;Moi maire&rdquo; pourra refuser par la suite &agrave; l&rsquo;Utilisateur la cr&eacute;ation d&rsquo;un nouveau compte sur le Service.</p>
          <p>&nbsp;</p>
          <p>En raison de la gratuit&eacute; des Services, &ldquo;Moi maire&rdquo; pourra supprimer &agrave; tout moment et sans pr&eacute;avis, pour toute raison, temporairement ou d&eacute;finitivement, la publication d&rsquo;une Proposition Citoyenne.</p>
          <p>&nbsp;</p>
          <p>De m&ecirc;me, en raison de la gratuit&eacute; des services, &ldquo;Moi maire&rdquo; pourra &agrave; tout moment et sans pr&eacute;avis, pour toute raison, temporairement ou d&eacute;finitivement, proc&eacute;der &agrave; la modification ou &agrave; la suppression partielle ou totale des Services.</p>
          <p>&nbsp;</p>
          <p>De m&ecirc;me, en raison de la gratuit&eacute; des services, &ldquo;Moi maire&rdquo; pourra &agrave; tout moment et sans pr&eacute;avis, pour toute raison, temporairement ou d&eacute;finitivement, proc&eacute;der &agrave; la modification ou &agrave; la suppression partielle ou totale des Services.</p>
          <p>&nbsp;</p>
          <p><strong>DUR&Eacute;E DES SERVICES, D&Eacute;SINSCRIPTION</strong></p>
          <p>&nbsp;</p>
          <p>Les Services sont souscrits pour une dur&eacute;e ind&eacute;termin&eacute;e.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur Inscrit peut se d&eacute;sinscrire des Services &agrave; tout moment, en adressant une demande &agrave; cet effet &agrave; &ldquo;Moi maire&rdquo; par courriel &agrave; moimaire@nicematin.fr</p>
          <p>&nbsp;</p>
          <p>La d&eacute;sinscription est effective imm&eacute;diatement. Elle entra&icirc;ne la suppression automatique du Compte de l&rsquo;Utilisateur Inscrit ainsi que de ses Propositions.</p>
          <p>&nbsp;</p>
          <p><strong>MODIFICATIONS</strong></p>
          <p>&nbsp;</p>
          <p>&ldquo;Moi maire&rdquo; se r&eacute;serve la facult&eacute; de modifier &agrave; tout moment les pr&eacute;sentes conditions g&eacute;n&eacute;rales.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur sera inform&eacute; de ces modifications par tout moyen utile.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utilisateur qui n&rsquo;accepte pas les conditions g&eacute;n&eacute;rales modifi&eacute;es doit se d&eacute;sinscrire des Services selon les modalit&eacute;s pr&eacute;vues aux pr&eacute;sentes conditions g&eacute;n&eacute;rales d'utilisation du site.</p>
          <p>&nbsp;</p>
          <p>Tout Utilisateur qui a recours aux Services post&eacute;rieurement &agrave; l&rsquo;entr&eacute;e en vigueur des conditions g&eacute;n&eacute;rales modifi&eacute;es est r&eacute;put&eacute; avoir accept&eacute; ces modifications.</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong>INT&Eacute;GRIT&Eacute;</strong></p>
          <p>&nbsp;</p>
          <p>Si une ou plusieurs stipulations des pr&eacute;sentes sont tenues pour nulles par une loi ou un r&egrave;glement, ou d&eacute;clar&eacute;es telles par d&eacute;cision d&eacute;finitive d'une juridiction comp&eacute;tente, elles seront r&eacute;put&eacute;es non &eacute;crites. Les autres stipulations des pr&eacute;sentes resteront en vigueur en conservant toute leur force et leur port&eacute;e, pour autant qu&rsquo;il soit possible, les Parties s&rsquo;engageant, en tant que de besoin, &agrave; se rapprocher afin de remplacer la clause nulle par une clause valide, aussi proche que possible, dans son esprit, de celle qu&rsquo;elle a vocation &agrave; remplacer.</p>
          <p>&nbsp;</p>
          <p><strong>NON-RENONCIATION</strong></p>
          <p>&nbsp;</p>
          <p>Le fait, pour l&rsquo;une des Parties, de ne pas se pr&eacute;valoir &agrave; un moment donn&eacute; de l&rsquo;une des stipulations des pr&eacute;sentes ne pourra &ecirc;tre ni interpr&eacute;t&eacute; ni consid&eacute;r&eacute; comme une renonciation &agrave; ses droits en vertu des pr&eacute;sentes, n&rsquo;affectera en aucune mani&egrave;re la validit&eacute; de tout ou partie des pr&eacute;sentes et ne portera pas atteinte aux droits de la Partie concern&eacute;e d&rsquo;agir en cons&eacute;quence.</p>
          <p>&nbsp;</p>
          <p>Aucune Partie ne sera r&eacute;put&eacute;e avoir renonc&eacute; &agrave; un droit acquis aux termes du contrat, sauf renonciation &eacute;crite et sign&eacute;e.</p>
          <p>&nbsp;</p>
          <p><strong>FORCE MAJEURE</strong></p>
          <p>&nbsp;</p>
          <p>Tout &eacute;v&eacute;nement impr&eacute;visible, irr&eacute;sistible et ext&eacute;rieur aux Parties tels que (et de mani&egrave;re non limitative) les actes de guerre ou de terrorisme, les actes criminels, les &eacute;meutes, les catastrophes naturelles ou industrielles, les explosions, les r&eacute;quisitions l&eacute;gales et autres dispositions d&rsquo;ordre l&eacute;gislatif ou r&eacute;glementaire apportant des restrictions &agrave; l&rsquo;exercice de l&rsquo;activit&eacute; de &ldquo;Moi maire&rdquo;, les perturbations des r&eacute;seaux de communications &eacute;lectroniques ind&eacute;pendantes de la volont&eacute; de &ldquo;Moi maire&rdquo;, etc., doit &ecirc;tre consid&eacute;r&eacute; comme un cas de force majeure. En cas de force majeure, &ldquo;Moi maire&rdquo; peut &ecirc;tre amen&eacute; &agrave; suspendre le Service. Les effets du contrat sont alors suspendus et pourront reprendre apr&egrave;s l&rsquo;extinction du cas de force majeure pour le reste de la dur&eacute;e du contrat. Ils pourront &eacute;galement rester &eacute;teints.</p>
          <p>&nbsp;</p>
          <p><strong>LANGUE</strong></p>
          <p>&nbsp;</p>
          <p>Dans l&rsquo;hypoth&egrave;se d&rsquo;une traduction des pr&eacute;sentes conditions g&eacute;n&eacute;rales dans une ou plusieurs langues, la langue d&rsquo;interpr&eacute;tation sera la langue fran&ccedil;aise en cas de contradiction ou de contestation sur la signification d&rsquo;un terme ou d&rsquo;une disposition.</p>
          <p>&nbsp;</p>
          <p><strong>LOI APPLICABLE ET JURIDICTION</strong></p>
          <p>&nbsp;</p>
          <p>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales sont r&eacute;gies par la loi fran&ccedil;aise.</p>
          <p>&nbsp;</p>
          <p>En cas de contestation sur la validit&eacute;, l&rsquo;interpr&eacute;tation et/ou l&rsquo;ex&eacute;cution des pr&eacute;sentes conditions g&eacute;n&eacute;rales, les parties conviennent que les tribunaux de Paris seront exclusivement comp&eacute;tents pour en juger, sauf r&egrave;gles de proc&eacute;dure imp&eacute;ratives contraires.</p>
          <p>&nbsp;</p>
          <p><strong>ENTR&Eacute;E EN VIGUEUR</strong></p>
          <p>&nbsp;</p>
          <p>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales sont entr&eacute;es en vigueur le 1/12/2019.</p>
          <p>&nbsp;</p>
        </div>
        <SubjectsPropositions />
        <MainNav />
      </div>
    );
  }
}

export default withRouter(Confidentialite);
