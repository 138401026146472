//API
import {
  getProposalSuccess,
  getProposalError,
  listProposalSuccess,
  listProposalError,
  getVoteSuccess,
  getProposal,
  listProposal
} from "./actions";
import { apiUrl } from "../../constants/defaultValues";
import axios from "axios";
import qs from "qs";
import { authHeader } from "../../helpers/authHeader";

export const getProposalAsync = proposalID => async dispatch => {
  dispatch(getProposal({ proposalID }));
  axios
    .get(`${apiUrl}/proposals/${proposalID}`)
    .then(function(response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      dispatch(getProposalSuccess({ ...response.data }));
      return response;
    })
    .catch(function(error) {
      dispatch(getProposalError({ ...error.response }));
    });
};

export const voteProposalAsync = proposal => async dispatch => {
  axios
    .post(`${apiUrl}/proposals/${proposal}/vote`, {}, { headers: authHeader() })
    .then(function(response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      dispatch(getVoteSuccess({ ...response.data }));
    })
    .catch(function(error) {
      dispatch(getVoteSuccess({ ...error.response.data }));
    });
};

export const dislikeProposalAsync = proposal => async dispatch => {
  axios
    .post(`${apiUrl}/proposals/${proposal}/dislike`, {}, { headers: authHeader() })
    .then(function(response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      dispatch(getVoteSuccess({ ...response.data }));
    })
    .catch(function(error) {
      dispatch(getVoteSuccess({ ...error.response.data }));
    });
};

export const getProposalsAsync = (
  params,
  page,
  comp,
  infinitescroll
) => async dispatch => {
  if (!infinitescroll) {
    dispatch(listProposal(comp));
  }
  let url = "/";
  params.page = page;
  if (params.sort && params.sort.votes === "desc") {
    url = "/proposals/top/" + params.filter.date;
  } else {
    url = "/proposals/";
  }
  axios
    .get(`${apiUrl}${url}`, {
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { encode: true });
      }
    })
    .then(function(response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      let proposals = {
        proposals: response.data.data,
        hasMore: page < response.data.pagination.nb_pages,
        totalItems: response.data.pagination.count,
        comp: comp
      };
      dispatch(listProposalSuccess({ ...proposals }));
      return proposals;
    })
    .catch(function(error) {
      dispatch(listProposalError({ ...error.response }));
    });
};
