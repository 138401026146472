import {
  	ADD_PROPOSAL,
  	ADD_PROPOSAL_SUCCESS,
  	ADD_PROPOSAL_ERROR,
  	ADD_MEDIA,
  	ADD_MEDIA_SUCCESS,
  	ADD_MEDIA_ERROR,
} from "../../constants/actionTypes";

const INIT_STATE = {
    addedProposal: null,
    addedMedia: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_PROPOSAL:
            return { ...state, loading: true };
        case ADD_PROPOSAL_SUCCESS:
            //notify.success('Login Success');
            return { ...state, loading: true, addedProposal: action.payload };
        case ADD_PROPOSAL_ERROR:
            //notify.success('Login Success');
            return { ...state, loading: false, addedProposal: action.payload };
        case ADD_MEDIA:
            return { ...state, loading: true };
        case ADD_MEDIA_SUCCESS:
            //notify.success('Login Success');
            return { ...state, loading: true, addedMedia: action.payload };
        case ADD_MEDIA_ERROR:
            //notify.success('Login Success');
            return { ...state, loading: false, addedMedia: action.payload };
        default: return { ...state };
    }
}
