//API
import {
  listCandidatsSuccess,
  listCandidatsError,
  listCandidats
} from "./actions";
import { apiUrl } from "../../constants/defaultValues";
import axios from "axios";
export const getCandidatsAsync = (params, page, comp) => async dispatch => {
  dispatch(listCandidats(comp));
  params.page = page;
  let url = "/candidates/?town=" + params.filter.towns;
  axios
    .get(`${apiUrl}${url}`, {})
    .then(function(response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      let candidats = {
        candidats: response.data.data,
        hasMore: page < response.data.pagination.nb_pages,
        comp: comp
      };
      dispatch(listCandidatsSuccess({ ...candidats }));
      return candidats;
    })
    .catch(function(error) {
      dispatch(listCandidatsError({ ...error.response }));
    });
};
