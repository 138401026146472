import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Front from "./front";
import Proposition from "./proposition";
import TopPropositions from "./toppropositions";
import TopCandidats from "./topcandidats";
import Confidentialite from "../confidentialite";
import { PrivateRoute } from "../../routing/PrivateRoute";
import Ville from "./ville";
import Auth from "../auth";
import Profile from "../profile";
import User from "../user";
import PropositionPage from "./proposal";
import { withRouter } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { CSSTransition } from "react-transition-group";
import MainNav from "../../components/mainnav";
import Header from "../../components/header";

// Component for /register
class Home extends Component {
  constructor() {
    super();
    this.state = {
      show: true
    };
  }
  componentDidMount() {
    scroll.scrollToTop({ duration: 250, delay: 0 });
  }
  render() {
    return (
      <BrowserRouter>
        <Header
          whiteVersion={true}
          arrow_left={true}
          logo={true}
          close={true}
        />
        <CSSTransition
          in={this.state.show}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <div>
            <Switch>
              <Route path={`/proposals/:id`} component={PropositionPage} />
              <PrivateRoute exact path={`/ville`} component={Ville} />
              <Route
                exact
                path={`/confidentialite`}
                render={() => <Confidentialite noHeader={true} />}
              />
              <Route exact path={`/top-candidats`} component={TopCandidats} />
              <Route
                exact
                path={`/top-propositions`}
                component={TopPropositions}
              />
              <Route exact path={`/propositions`} component={Proposition} />
              <Route
                exact
                path={`/propositions/:action`}
                component={Proposition}
              />
              <PrivateRoute
                exact
                path={`/profile`}
                component={Profile}
                noHeader={true}
              />
              <Route
                exact
                path={`/candidat/:id`}
                render={() => <User noHeader={true} />}
              />
              <Route path={`/auth/:action`} render={() => <Auth />} />
              <Route exact path={`/`} component={Front} />
              <Route path={"*"} component={Front} />
            </Switch>
          </div>
        </CSSTransition>

        <MainNav />
      </BrowserRouter>
    );
  }
}

export default withRouter(Home);
