import React from "react";
import Login from "./login";
import Connect from "./connect";
import Register from "./register";
import Forgot from "./forgot";
import { withRouter } from "react-router-dom";

const Auth = props => {
  const authComponents = {
    login: Login,
    connect: Connect,
    register: Register,
    forgot: Forgot
  };

  const FinalComponent = authComponents[props.match.params.action]
    ? authComponents[props.match.params.action]
    : authComponents["login"];

  return <FinalComponent {...props} />;
};

export default withRouter(Auth);
