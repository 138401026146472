import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./profilecard.scss";
import Medal from "../../assets/images/Medal.png";
import Avatar from "../../assets/images/avatar.png";
import ProfileModal from "../profilemodal";
import Loader from "../loader";
import Comment from "../../assets/images/Comment.png";
import Progress from "../../assets/images/progress.png";
import Pen from "../../assets/images/edit.png";

class ProfileCard extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      modalIsOpen: false
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  componentDidUpdate(newProps) {
    if (this.props.user !== newProps.user) {
      this.setState({ user: this.props.user });
    }
  }

  render() {
    return !this.state.user ? (
      <Loader />
    ) : (
      <div className="profile-card">
        {this.props.notme ? (
          ""
        ) : (
          <ProfileModal
            user={this.state.user}
            modalIsOpen={this.state.modalIsOpen}
            closeModal={this.closeModal}
          />
        )}
        <div className="candidat-details">
          {!this.props.notme ? (
            <div className="avatar-image change" onClick={this.openModal}>
              <span>
                <img src={Pen} alt="edit" />
                <p>Modifier votre profil</p>
              </span>
              <img
                className="avatar"
                src={
                  this.state.user &&
                  this.state.user.profile &&
                  this.state.user.profile.image
                    ? this.state.user.profile.image.link
                    : Avatar
                }
                alt="Modifier votre profil"
              />
            </div>
          ) : (
            <div className="avatar-image">
              <img
                className="avatar"
                onClick={this.openModal}
                src={
                  this.state.user &&
                  this.state.user.profile &&
                  this.state.user.profile.image
                    ? this.state.user.profile.image.link
                    : Avatar
                }
                alt=""
              />
            </div>
          )}
          <div className="details">
            <h2 className="title">
              {this.state.user && this.state.user.profile
                ? this.state.user.profile.full_name
                : ""}
            </h2>
            <p className="description">
              <span className="number">
                {this.state.user && this.state.user.metrics
                  ? this.state.user.metrics.score
                  : "0"}
              </span>{" "}
              {this.state.user && this.state.user.metrics
                ? this.state.user.metrics.score > 1
                  ? "points civiques"
                  : "point civique"
                : "point civique"}
            </p>
          </div>
        </div>
        <div className="badges">
          <img src={Medal} alt="" />
          <img src={Progress} alt="" />
          <img src={Comment} alt="" />
        </div>
        <div className="bio">
          <p>
            {this.state.user
              ? this.state.user.profile
                ? this.state.user.profile.biography
                : ""
              : ""}
          </p>
        </div>
        <div className="user-numbers row">
          <div className="col-4 stat">
            <span className="number">
              {this.state.user && this.state.user.metrics
                ? this.state.user.metrics.score
                : "0"}
            </span>
            <span className="numberhint">
              {this.state.user && this.state.user.metrics
                ? this.state.user.metrics.score > 1
                  ? "points civiques"
                  : "point civique"
                : "point civique"}
            </span>
          </div>
          <div className="col-4 stat">
            <span className="number">
              {this.state.user &&
              this.state.user.metrics &&
              this.state.user.metrics.received_votes
                ? this.state.user.metrics.received_votes
                : "0"}
            </span>
            <span className="numberhint">
              {this.state.user && this.state.user.metrics
                ? this.state.user.metrics.received_votes > 1
                  ? "votes reçus"
                  : "vote reçu"
                : "vote reçu"}
            </span>
          </div>
          <div className="col-4 stat">
            <span className="number">
              {this.state.user && this.state.user.metrics
                ? this.state.user.metrics.votes
                : "0"}
            </span>
            <span className="numberhint">
              {this.state.user && this.state.user.metrics
                ? this.state.user.metrics.votes > 1
                  ? "votes"
                  : "vote"
                : "vote"}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProfileCard);
