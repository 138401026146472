import React, { Component } from "react";
import Button from "../../../components/button";
import { withRouter, Link } from "react-router-dom";
import Nav from "../../../components/nav";
import { connect } from "react-redux";
import { getTownsAsync } from "../../../redux/actions";
import Position from "../../../assets/images/position.svg";
import AsyncPaginate from "react-select-async-paginate";
import { apiUrl } from "../../../constants/defaultValues";
import { components } from "react-select";

// Component for /Geolocalisation

class CustomLocation extends Component {
  constructor() {
    super();
    this.state = {
      value: ""
    };
  }
  componentDidMount() {
    if (!(this.props.location.state && this.props.location.state.response)) {
      this.props.history.push("/auth/register", { response: false });
    }
  }
  setValue = value => {
    this.setState({ value });
  };

  async loadOptions(search, loadedOptions, { page }) {
    const response = await fetch(
      `${apiUrl}/towns/?query=${search}&page=${page}`
    );
    const responseJSON = await response.json();
    const data = [];
    responseJSON.data.map(choice => {
      data.push({ value: choice.id, label: choice.name });
      return choice;
    });
    return {
      options: data,
      hasMore: responseJSON.pagination.page < responseJSON.pagination.nb_pages,
      additional: {
        page: page + 1
      }
    };
  }

  render() {
    const CaretDownIcon = () => {
      return <img src={Position} alt="" />;
    };

    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <CaretDownIcon />
        </components.DropdownIndicator>
      );
    };
    const customStyles = {
      indicatorSeparator: (provided, state) => {
        const opacity = 0;

        return { ...provided, opacity };
      }
    };

    if (this.props.location.state && this.props.location.state.response) {
      return (
        <div className="geolocalisation geolocalisation-custom">
          <Nav arrow_left={true} logo={true} close={true} />
          <div className="geolocalisation-content">
            <div>
              <p>Dans quelle ville souhaitez-vous vous présenter ? </p>
              <AsyncPaginate
                defaultValue={this.loadOptions[1]}
                value={this.state.value}
                loadOptions={this.loadOptions}
                onChange={this.setValue}
                placeholder="Choisissez votre ville"
                components={{ DropdownIndicator }}
                additional={{
                  page: 1
                }}
                className="my-select"
                styles={customStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#00102B",
                    neutral0: "transparent",
                    primary25: "hotpink",
                    neutral80: "#00102B"
                  }
                })}
              />
              <Link
                className={
                  this.state.value === ""
                    ? "disabled-link hide-on-mobile"
                    : "hide-on-mobile"
                }
                to={{
                  pathname: "/auth/register/subjects",
                  state: { subjects: true }
                }}
                onClick={() => {
                  this.props.userTown(this.state.value.value);
                }}
              >
                <Button
                  color="primary"
                  background="white"
                  text="Continuer"
                  type="round"
                />
              </Link>
            </div>
            <div className="bottom-flex-section">
              <Link
                className={this.state.value === "" ? "disabled-link" : ""}
                to={{
                  pathname: "/auth/register/subjects",
                  state: { subjects: true }
                }}
                onClick={() => {
                  this.props.userTown(this.state.value.value);
                }}
              >
                <Button
                  color="primary"
                  background="white"
                  text="Continuer"
                  type="round"
                />
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = ({ town }) => {
  return { town };
};

export default withRouter(
  connect(mapStateToProps, {
    getTownsAsync
  })(CustomLocation)
);
