/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGIN_USER_FB = "LOGIN_USER_FB";
export const LOGIN_USER_SUCCESS_FB = "LOGIN_USER_SUCCESS_FB";
export const LOGIN_USER_ERROR_FB = "LOGIN_USER_ERROR_FB";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";

/* TOWN */
export const TOWN_ONE = "TOWN_ONE";
export const TOWN_ONE_SUCCESS = "TOWN_ONE_SUCCESS";
export const TOWN_ONE_ERROR = "TOWN_ONE_ERROR";
export const TOWN_LIST = "TOWN_LIST";
export const TOWN_LIST_SUCCESS = "TOWN_LIST_SUCCESS";
export const TOWN_LIST_ERROR = "TOWN_LIST_ERROR";

/* TOPICS */
export const TOPICS_LIST = "TOPICS_LIST";
export const TOPICS_LIST_SUCCESS = "TOPICS_LIST_SUCCESS";
export const TOPICS_LIST_ERROR = "TOPICS_LIST_ERROR";

/* ADD PROPOSAL */
export const ADD_PROPOSAL = "ADD_PROPOSAL";
export const ADD_PROPOSAL_SUCCESS = "ADD_PROPOSAL_SUCCESS";
export const ADD_PROPOSAL_ERROR = "ADD_PROPOSAL_ERROR";
export const ADD_MEDIA = "ADD_MEDIA";
export const ADD_MEDIA_SUCCESS = "ADD_MEDIA_SUCCESS";
export const ADD_MEDIA_ERROR = "ADD_MEDIA_ERROR";

/* SHOW PROPOSAL */
export const PROPOSAL_ONE = "PROPOSAL_ONE";
export const PROPOSAL_ONE_SUCCESS = "PROPOSAL_ONE_SUCCESS";
export const PROPOSAL_ONE_ERROR = "PROPOSAL_ONE_ERROR";
export const PROPOSAL_LIST = "PROPOSAL_LIST";
export const PROPOSAL_LIST_SUCCESS = "PROPOSAL_LIST_SUCCESS";
export const PROPOSAL_LIST_ERROR = "PROPOSAL_LIST_ERROR";
export const PROPOSAL_LIST_RESET = "PROPOSAL_LIST_RESET";
export const VOTE_PROPOSAL_SUCCESS = "VOTE_PROPOSAL_SUCCESS";

/* SHOW USERS */
export const USER_ONE = "USER_ONE";
export const USER_ONE_SUCCESS = "USER_ONE_SUCCESS";
export const USER_ONE_ERROR = "USER_ONE_ERROR";
export const USER_ME_SUCCESS = "USER_ME_SUCCESS";
export const USER_ME_ERROR = "USER_ME_ERROR";
export const USER_LIST = "USER_LIST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_ERROR = "USER_LIST_ERROR";
export const USER_LIST_RESET = "USER_LIST_RESET";

/* PROFILE */
export const PROFILE_ONE = "PROFILE_ONE";
export const PROFILE_ONE_SUCCESS = "PROFILE_ONE_SUCCESS";
export const PROFILE_ONE_ERROR = "PROFILE_ONE_ERROR";

/* NEWS */
export const NEWS_ONE = "NEWS_ONE";
export const NEWS_LIST = "NEWS_LIST";
export const NEWS_LIST_SUCCESS = "NEWS_LIST_SUCCESS";
export const NEWS_LIST_ERROR = "NEWS_LIST_ERROR";

/* CANDIDATES */
export const CANDIDATS_LIST = "CANDIDATS_LIST";
export const CANDIDATS_LIST_SUCCESS = "CANDIDATS_LIST_SUCCESS";
export const CANDIDATS_LIST_ERROR = "CANDIDATS_LIST_ERROR";
