//API
import { getTownSuccess, getTownError, listTownSuccess, listTownError } from "./actions";
import { apiUrl } from "../../constants/defaultValues";
import axios from "axios";

export const getMyTownAsync = townID => async dispatch => {
  axios.get(`${apiUrl}/towns/${townID}`)
	  .then(function (response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
			dispatch(getTownSuccess({ ...response.data}));
      return response;
	  })
	  .catch(function (error) {
    	dispatch(getTownError({ ...error.response}));
	  });
};

export const getTownAsync = town => async dispatch => {
  axios.get(`${apiUrl}/towns?query=${town}`)
	  .then(function (response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
			dispatch(getTownSuccess({ ...response.data.data}));
      return response;
	  })
	  .catch(function (error) {
  		dispatch(getTownError({ ...error.response}));
	  });
};


export const getTownsAsync = page => async dispatch => {
  axios.get(`${apiUrl}/towns?page=${page}`)
	  .then(function (response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      let towns = {
        options: response.data.data,
        hasMore: page < response.data.pagination.nb_pages
      }
  		dispatch(listTownSuccess({ ...towns}));
      return towns;
	  })
	  .catch(function (error) {
  		dispatch(listTownError({ ...error.response.data}));
	  });
};
