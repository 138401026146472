import React, { Component, Fragment } from "react";
import ProfileCard from "../../components/profilecard";
import Header from "../../components/header";
import Tag from "../../components/tag";
import MainNav from "../../components/mainnav";
import MySlider from "../../components/slider";
import InfiniteScroll from "../../components/infinitescroll";
import SubjectsPropositions from "../../components/subjectspropositions";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import { animateScroll as scroll } from "react-scroll";
import "slick-carousel/slick/slick-theme.css";
import hashGenerator from "../../helpers/hashGenerate";
import { getUserAsync, getTopicsAsync } from "../../redux/actions";
import { Helmet } from "react-helmet";

// Component for /register
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      disabled: true,
      totalItems: -1,
      selectedUser: false,
      paramsVotedProp: {},
      paramsCreatedProp: {}
    };
  }
  componentDidMount() {
    //this.props.getUserAsync();
    scroll.scrollToTop({ duration: 250, delay: 0 });
  }

  componentDidUpdate(newProps) {
    if (newProps.user !== this.props.user) {
      this.setState({ user: this.props.user }, () => {
        this.setState({
          paramsCreatedProp: {
            sort: {
              created_at: "desc"
            },
            filter: {
              creator: this.state.user ? this.state.user.id : ""
            }
          }
        });
        this.setState({
          paramsVotedProp: {
            sort: {
              created_at: "desc"
            },
            filter: {
              voter: this.state.user ? this.state.user.id : "",
              voted: 1
            }
          }
        });
        this.setState({ selectedUser: true });
      });
    }
  }

  handleTotal = totalItems => {
    this.setState({ totalItems }, () => {});
  };

  render() {
    var settingsTopPropositionSlider = {
      dots: false,
      arrows: false,
      infinite: false,
      slidesToScroll: 1,
      className: "center",
      centerPadding: "5px",
      speed: 750,
      swipeToSlide: true,
      touchThreshold: 12,
      centerMode: true,
      slidesToShow: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            centerPadding: "0px",
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            centerPadding: "0px",
            centerMode: true,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerPadding: "0px",
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="home topproposition user-profile">
        <Helmet>
          <title>
            {this.state.user && this.state.user.profile && this.state.user.town
              ? this.state.user.profile &&
                this.state.user.profile.full_name +
                  " candidat pour la ville de " +
                  this.state.user.town.name
              : ""}
          </title>
          <meta
            name="description"
            content={
              this.state.user && this.state.user.profile && this.state.user.town
                ? "Découvrez son profil et consultez les propositions déposées par ce candidat dans le ville de " +
                  this.state.user.town.name
                : ""
            }
          />
          <meta
            property="og:title"
            content={
              this.state.user && this.state.user.profile && this.state.user.town
                ? this.state.user.profile.full_name +
                  " candidat pour la ville de " +
                  this.state.user.town.name
                : ""
            }
          />
          <meta
            property="og:description"
            content={
              this.state.user && this.state.user.town
                ? "Découvrez son profil et consultez les propositions déposées par ce candidat dans le ville de " +
                  this.state.user.town.name
                : ""
            }
          />
          <meta property="og:image" content="" />
          {/*<meta property="og:url" content="http://euro-travel-example.com/index.htm"></meta>*/}
        </Helmet>
        {!this.props.noHeader ? <Header /> : <Fragment />}
        <div className="dark-section">
          <ProfileCard user={this.state.user} />
        </div>

        <div className="white-section">
          <div className="best-propositions-section">
            <p className="slider-title">sujets favoris</p>

            <div className="fav-subjects">
              {this.state.user && this.state.user.topics
                ? this.state.user.topics.map((topic, index) => {
                    return (
                      <Link to={{ pathname: "/propositions/" + topic.id }}>
                        <Tag
                          index={index}
                          color="primary"
                          background="#212529"
                          text={topic.name}
                          type="round"
                          className={"tag checked"}
                          checked={() => {}}
                        />
                      </Link>
                    );
                  })
                : ""}
            </div>
          </div>
          <div className="best-propositions-section">
            <p className="slider-title">
              {this.state.totalItems === -1 ? 0 : this.state.totalItems}{" "}
              {this.state.totalItems > 1 ? "propositions" : "proposition"}
            </p>
            <div className="row scroller">
              {this.state.selectedUser ? (
                <InfiniteScroll
                  filter={false}
                  comp={hashGenerator()}
                  totalItems={this.handleTotal}
                  getproposals={true}
                  getusers={false}
                  settings={settingsTopPropositionSlider}
                  params={this.state.paramsCreatedProp}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="best-propositions-section">
            <p className="slider-title">
              {this.state.user &&
              this.state.user.metrics &&
              this.state.user.metrics.votes
                ? this.state.user.metrics.votes
                : 0}{" "}
              {this.state.user &&
              this.state.user.metrics &&
              this.state.user.metrics.votes > 1
                ? "propositions votées"
                : "proposition votée"}
            </p>
            {this.state.selectedUser ? (
              <MySlider
                comp={hashGenerator()}
                getproposals={true}
                getusers={false}
                settings={settingsTopPropositionSlider}
                params={this.state.paramsVotedProp}
              />
            ) : (
              ""
            )}
          </div>
        </div>

        <SubjectsPropositions />
        <MainNav />
      </div>
    );
  }
}

const mapStateToProps = ({ users }) => {
  const { user, loading } = users;
  return { user, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    getUserAsync,
    getTopicsAsync
  })(Profile)
);
