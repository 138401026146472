/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component, Fragment } from "react";
import "./propositionmodal.scss";
import Modal from "react-modal";
import Close from "../../assets/images/close.svg";
import ArrowNav from "../../assets/images/arrow_nav.svg";
import Button from "../../components/button";
import MySlider from "../../components/slider";
import Slider from "react-slick";
import ThumbDown from "../../assets/images/thumbdown.svg";
import Thumb from "../../assets/images/thumb.svg";
import { connect } from "react-redux";
import Share from "../../assets/images/share.svg";
import { getProposalAsync, voteProposalAsync, dislikeProposalAsync } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import isAuthenticated from "../../helpers/isAuthenticated";
import Loader from "../loader";
import { toast } from "react-toastify";

class PropositionModal extends Component {
  constructor() {
    super();
    this.state = {
      proposalPage: null,
      proposalID: null,
      vote: 0,
      dislike: 0,
      loading: true
    };
  }

  toastId = null;
  customToastId = "vote";

  notify = (message, type) => {
    if (!toast.isActive(this.toastId)) {
      this.toastId = 0;
    }
    if (type === "success") {
      toast.success(message, {
        toastId: this.customToastId
      });
    }
    if (type === "error") {
      toast.error(message, {
        toastId: this.customToastId
      });
    }
  };
  componentDidMount() {
    this.setState({ loading: true });
  }
  componentDidUpdate(newProps) {
    if (
      newProps.modalIsOpen !== this.props.modalIsOpen &&
      this.props.modalIsOpen
    ) {
      this.props.getProposalAsync(this.props.proposalID);
    }
    if (
      newProps.proposals !== this.props.proposals &&
      this.props.proposals.proposal &&
      !this.props.proposals.vote
    ) {
      this.setState({ proposalPage: this.props.proposals.proposal }, () => {
        if (this.props.proposalID === this.state.proposalPage.id) {
          this.setState({ loading: false });
        }
      });
      this.setState({
        vote:
          this.props.proposals &&
          this.props.proposals.proposal &&
          this.props.proposals.proposal.metrics
            ? this.props.proposals.proposal.metrics.votes
            : 0
      });
      this.setState({
        dislike:
          this.props.proposals &&
          this.props.proposals.proposal &&
          this.props.proposals.proposal.metrics
            ? this.props.proposals.proposal.metrics.dislikes
            : 0
      });
    }
    if (
      newProps.proposals !== this.props.proposals &&
      this.props.proposals.vote
    ) {
      if (
        this.props.proposals &&
        this.props.proposals.vote &&
        Number(this.props.proposalID) === Number(this.props.proposals.vote.id)
      ) {
        this.notify("Merci, votre vote a bien été pris en compte !", "success");
        this.setState({ vote: this.props.proposals.vote.metrics.votes });
        this.setState({ dislike: this.props.proposals.vote.metrics.dislikes });
      } else {
        if (this.props.proposalID && this.props.proposals.vote.code === 400) {
          this.notify("Vous avez déjà voté pour cette proposition", "error");
        }
      }
    }
  }

  handleVote(e, proposalID) {
    e.stopPropagation();
    if (isAuthenticated()) {
      this.props.voteProposalAsync(proposalID);
    } else {
      this.props.history.push({
        pathname: "/auth/login"
      });
    }
  }

  handleDislike(e, proposalID) {
    e.stopPropagation();
    if (isAuthenticated()) {
      this.setState({ modalIsOpen: false });
      this.props.dislikeProposalAsync(proposalID);
      if (this.props.swipe) {
        this.props.swipe();
      }
      if (this.props.next) {
        this.props.next();
      }
    } else {
      this.props.history.push({
        pathname: "/auth/login"
      });
    }
  }
  handleClick(e) {
    e.stopPropagation();

    if (
      this.state.clientXonMouseDown !== e.clientX ||
      this.state.clientYonMouseDown !== e.clientY
    ) {
      // prevent link click if the element was dragged
      e.preventDefault();
    }
  }

  handleClose = () => {
    this.setState({ proposalPage: null });
    this.props.closeModal();
  };

  render() {
    var settingsTopPropositionSlider = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "center",
      centerPadding: "5px",
      speed: 750,
      swipeToSlide: true,
      touchThreshold: 10,
      centerMode: true,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1600,
          slidesToShow: 1,
          settings: {
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            adaptiveHeight: true
          }
        }
      ]
    };

    let cardShow = this.state.proposalPage;
    const paramsSame = {
      sort: {
        created_at: "desc"
      },
      filter: {
        date: "",
        topics: cardShow && cardShow.topic ? [cardShow.topic.id] : [],
        towns: []
      }
    };
    const customStyles = {
      content: {}
    };
    let shareURL = cardShow
      ? window.location.protocol +
        "//" +
        window.location.hostname +
        "/proposals/" +
        cardShow.id
      : "";

    return (
      <div className="modalProposition">
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.props.closeModal}
          contentLabel=""
          style={customStyles}
          className="modalProposition white-section"
        >
          <img
            onClick={this.props.closeModal}
            src={Close}
            className="close-modal"
            alt=""
          />
          <div
            onClick={e => {
              this.handleClick(e);
            }}
          >
            {this.state.loading ? (
              <Loader black={true} />
            ) : (
              <Fragment>
                <div className="card-proposition-header">
                  <p
                    className="card-topic"
                    style={{
                      color:
                        cardShow && cardShow.topic && cardShow.topic.color
                          ? cardShow.topic.color
                          : "#F55E01"
                    }}
                  >
                    <span
                      style={{
                        background:
                          cardShow && cardShow.topic && cardShow.topic.color
                            ? cardShow.topic.color
                            : "#F55E01"
                      }}
                    ></span>
                    {cardShow && cardShow.topic ? cardShow.topic.name : ""}
                  </p>
                </div>
                <div className="vote">
                  <div
                    onClick={e => {
                      this.handleVote(e, cardShow.id);
                    }}
                  >
                    <span
                      className="span-bg"
                      style={{
                        background:
                          cardShow.topic && cardShow.topic.color
                            ? cardShow.topic.color
                            : "#FAA600"
                      }}
                    ></span>
                    <img src={Thumb} alt="" />{" "}
                  </div>
                  <span
                    onClick={e => {
                      this.handleVote(e, cardShow.id);
                    }}
                  >
                    {cardShow && cardShow.metrics ? this.state.vote : 0}
                  </span>
                  <div
                    className="space-thumb"
                    onClick={e => {
                      this.handleDislike(e, cardShow.id);
                    }}
                  >
                    <span
                      className="span-bg"
                      style={{
                        background:
                          cardShow.topic && cardShow.topic.color
                            ? cardShow.topic.color
                            : "#FAA600"
                      }}
                    ></span>
                    <img src={ThumbDown} alt="" />{" "}
                  </div>
                  <span
                    onClick={e => {
                      this.handleDislike(e, cardShow.id);
                    }}
                  >
                    {cardShow.metrics ? this.state.dislike : 0}
                  </span>
                </div>
                <div className="padded-multilines">
                  <h2
                    className="title"
                    style={{
                      background:
                        cardShow && cardShow.topic && cardShow.topic.color
                          ? cardShow.topic.color
                          : "#F55E01"
                    }}
                  >
                    {cardShow ? cardShow.title : ""}
                  </h2>
                </div>
                <div className="propositionHeader small">
                  Suggéré pour vous aujourd’hui
                </div>
                <p className="propositionDesc">
                  {cardShow ? cardShow.description : ""}
                </p>
                {cardShow ? (
                  cardShow.address ? (
                    <Fragment>
                      <div className="propositionHeader">Où ça ?</div>
                      <div className="place">
                        <img src={ArrowNav} alt="" />{" "}
                        <span>{cardShow ? cardShow.address : ""}</span>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <p className="author">
                  proposition de{" "}
                  <span className="name">
                    {cardShow && cardShow.user.profile
                      ? cardShow.user.profile.full_name
                      : ""}
                  </span>
                  {cardShow && cardShow.town ? " pour la ville de " : ""}
                  <span className="name">
                    {" "}
                    {cardShow && cardShow.town ? cardShow.town.name : ""}
                  </span>
                </p>
                {cardShow && cardShow.images ? (
                  cardShow.images.length ? (
                    <div className="image-slider">
                      <div className="propositionHeader">
                        {cardShow && cardShow.images.length > 1
                          ? "Photos déposées par le candidat"
                          : "Photo déposée par le candidat"}
                      </div>
                      <Slider {...settingsTopPropositionSlider}>
                        {cardShow && cardShow.images
                          ? cardShow.images.map((image, i) => {
                              return (
                                <div key={i}>
                                  <img src={image.link} alt="" />
                                </div>
                              );
                            })
                          : ""}
                      </Slider>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <div className="actions">
                  <div className="link-share">
                    <Button
                      color="black"
                      background="grey"
                      text={[<img src={Share} alt="" />, "Partager"]}
                      type="round"
                      className="share no-hover share-btn"
                      click={() => {
                        let width = 626;
                        let height = 305;
                        var leftPosition, topPosition;
                        leftPosition =
                          window.screen.width / 2 - (width / 2 + 10);
                        topPosition =
                          window.screen.height / 2 - (height / 2 + 50);
                        var windowFeatures =
                          "status=no,height=" +
                          height +
                          ",width=" +
                          width +
                          ",resizable=no,left=" +
                          leftPosition +
                          ",top=" +
                          topPosition +
                          ",screenX=" +
                          leftPosition +
                          ",screenY=" +
                          topPosition +
                          ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
                        window.open(
                          "http://www.facebook.com/sharer.php?u=" +
                            encodeURIComponent(shareURL),
                          "sharer",
                          windowFeatures
                        );
                        return false;
                      }}
                    />
                  </div>
                  <Button
                    click={e => {
                      this.handleVote(e, cardShow.id);
                    }}
                    color="black"
                    background="green"
                    text={[
                      <img src={Thumb} alt="" />,
                      <span className="number">
                        {cardShow && cardShow.metrics ? this.state.vote : 0}
                      </span>
                    ]}
                    type="round"
                    className="voting no-hover"
                  />
                  <Button
                    click={e => {
                      this.handleDislike(e, cardShow.id);
                    }}
                    color="black"
                    background="mmred"
                    text={[
                      <img src={ThumbDown} alt="" />,
                      <span className="number">
                        {cardShow && cardShow.metrics ? this.state.dislike : 0}
                      </span>
                    ]}
                    type="round"
                    className="voting no-hover"
                  />
                </div>
                <div className="suggested-propositions">
                  <p className="slider-title">Voir les autres propositions</p>
                  <MySlider
                    modal={true}
                    click={this.props.closeModal}
                    resetting={true}
                    comp={
                      Math.random()
                        .toString(36)
                        .substring(2, 15) +
                      Math.random()
                        .toString(36)
                        .substring(2, 15)
                    }
                    getproposals={true}
                    getusers={false}
                    settings={settingsTopPropositionSlider}
                    params={paramsSame}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ proposals }) => {
  return { proposals };
};

export default withRouter(
  connect(mapStateToProps, {
    getProposalAsync,
    voteProposalAsync,
    dislikeProposalAsync
  })(PropositionModal)
);
