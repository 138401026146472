import React from 'react';
import './checkboxInput.scss';

const CheckboxInput = (props) => {
  return (
    <div className="checkbox-input form-group">
      <input type="checkbox" className="form-control" name={props.name} checked={props.value} onChange={props.handleChange}/>
      <label htmlFor={props.name}>{props.label}</label>
    </div>
  );
}

export default CheckboxInput;
