import {
	ADD_PROPOSAL,
	ADD_PROPOSAL_SUCCESS,
	ADD_PROPOSAL_ERROR,
	ADD_MEDIA,
	ADD_MEDIA_SUCCESS,
	ADD_MEDIA_ERROR
} from "../../constants/actionTypes";

export const addProposal = (proposal, history) => ({
	type: ADD_PROPOSAL,
	payload: { proposal, history }
});
export const addProposalSuccess = proposal => ({
	type: ADD_PROPOSAL_SUCCESS,
	payload: proposal
});
export const addProposalError = proposal => ({
	type: ADD_PROPOSAL_ERROR,
	payload: proposal
});
export const addMedia = (media, history) => ({
	type: ADD_MEDIA,
	payload: { media, history }
});
export const addMediaSuccess = media => ({
	type: ADD_MEDIA_SUCCESS,
	payload: media
});
export const addMediaError = media => ({
	type: ADD_MEDIA_ERROR,
	payload: media
});
