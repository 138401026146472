import React, { Component } from "react";
import Button from "../../../components/button";
import Tag from "../../../components/tag";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getTopicsAsync } from "../../../redux/actions";
import Nav from "../../../components/nav";
// Component for /Geolocalisation
class SubjectsChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: [],
      choices: new Array(0).fill(false),
      disabled: true
    };
    this.addActiveClass = this.addActiveClass.bind(this);
  }

  addActiveClass(index) {
    index--;
    let activeClasses = this.state.choices;
    activeClasses[index] = !activeClasses[index];
    var counts = {};

    for (var i = 0; i < activeClasses.length; i++) {
      var num = activeClasses[i];
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    if (counts[true] > 2) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
    this.setState({ activeClasses });
  }

  componentDidMount() {
    if (!(this.props.location.state && this.props.location.state.subjects)) {
      this.props.history.push("/auth/register", { response: false });
    } else {
      this.props.getTopicsAsync();
    }
  }

  componentDidUpdate(newProps) {
    if (newProps.topic !== this.props.topic) {
      this.setState({ topics: Object.values(this.props.topic.topics) });
      this.setState({
        choices: new Array(Object.values(this.props.topic.topics).length).fill(
          false
        )
      });
    }
  }
  register = topics => {
    if (this.props.state.type === "facebook") {
      this.props.userTopic(topics);
    } else {
      this.props.userTopic(topics);
      this.props.history.push("/auth/register/form", { inscription: true });
    }
  };
  render() {
    if (this.props.location.state && this.props.location.state.subjects) {
      const activeClasses = this.state.choices.slice();
      return (
        <div className="subjects-choice">
          <Nav whiteVersion={true} arrow_left={true} logo={true} close={true} />
          <div>
            <h2>Quelles sont les priorités pour vous et votre ville ? </h2>
            <p>Choisissez au moins 3 thématiques qui vous tiennent à coeur</p>
            <div className="subjects">
              {this.state.topics.map(topic => {
                return (
                  <Tag
                    key={topic.id}
                    index={topic.id}
                    color="primary"
                    background={
                      activeClasses[topic.id - 1] ? topic.color : "#212529"
                    }
                    text={topic.name}
                    type="round"
                    className={
                      activeClasses[topic.id - 1] ? "tag checked" : "tag"
                    }
                    checked={this.addActiveClass}
                  />
                );
              })}
            </div>
            <div className="validate">
              <Link
                onClick={() => {
                  this.register([]);
                }}
              >
                <p>Plus tard</p>
              </Link>
              <Link
                onClick={() => {
                  this.register(activeClasses);
                }}
                className={this.state.disabled ? "disabled-link" : ""}
              >
                <Button
                  color="white"
                  background="green"
                  text="Je valide"
                  type="round"
                />
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = ({ topic }) => {
  return { topic };
};

export default withRouter(
  connect(mapStateToProps, {
    getTopicsAsync
  })(SubjectsChoice)
);
