import React, { Component, Fragment } from "react";
import navArrow from "../../../assets/images/arrow_nav.svg";
import Button from "../../../components/button";
import { Link, withRouter } from 'react-router-dom';
import { geolocated } from "react-geolocated";
import { connect } from "react-redux";
import { compose } from 'redux'
import {getTownAsync} from "../../../redux/actions";
import Geocode from "react-geocode";
import Nav from "../../../components/nav";
// Component for /Geolocalisation
class Geolocalisation extends Component {
  constructor() {
    super();
    this.state = {
        user_city: "",
        show_next: false,
    }
    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey("AIzaSyCkcQr_RYcgB9PkMzwkwqJNCnGuGymCRP0");

    // set response language. Defaults to english.
    Geocode.setLanguage("fr");
  }

  componentDidUpdate(props) {
    // Get address from latidude & longitude.
    if(this.props.coords !== props.coords) {
    Geocode.fromLatLng(this.props.coords.latitude, this.props.coords.longitude).then(
      response => {
        var itemLocality='';
        for (var i = 0; i < response.results.length; i++) {
          if (response.results[i].types[0] === "locality"){
              itemLocality = response.results[i].address_components[0].long_name;
          }
        }
        var user_city = itemLocality;
        this.setState({user_city});
        this.props.getTownAsync(user_city);
      },
      error => {
        console.error(error);
      }
    )}
    if(this.props.isGeolocationEnabled===false && props.isGeolocationEnabled===this.props.isGeolocationEnabled) {
      this.props.history.push({
        pathname: '/auth/register/location',
        state: { response: true }
      });
    }
    if(props.town !== this.props.town) {
      if(this.props.town.town==="" || !this.props.town.town[0]) {
        this.setState({show_next: false});
      } else {
        this.setState({show_next: true});
        this.setState({user_city: this.props.town.town[0].name});
        this.props.userTown(this.props.town.town[0].id);
      }
    }

  }

  render() {
    return ( <div className="geolocalisation">
      <Nav arrow_left={true} logo={true} close={true}/>
      <div className="geolocalisation-content">
        {!this.props.isGeolocationAvailable ? (
                    <div>Your browser does not support Geolocation</div>
                ) : !this.props.isGeolocationEnabled ? (
                  <div>
                    <img src={navArrow} alt="" className="nav-icon-localisation" />
                    <p>Nous utilisons votre localisation pour vous inscrire dans votre ville de résidence.</p>

                    <p>Vous souhaitez choisir une autre commune?</p>
                    <p> Pas de problème, vous pouvez sélectionner la ville de votre choix lors de l'étape suivante</p>
                    <Link to={{pathname:"/auth/register/location", state: {response: true}}}><Button color="white" background="transparent" text="Choisir ma ville" type="round" className="mt-20"/></Link>

                  </div>
                ) : this.props.coords ? (
                  <Fragment>
                    <div className="top-flex-section">
                      <p>Je souhaite me présenter pour la ville de </p>
                      <Button color="white" background="transparent no-hover" text={[<img src={navArrow} alt="" />, " ", this.state.user_city]} type="round" className="mt-20 my_city"/>
                      <div className="hide-on-mobile">
                        {this.state.show_next ?
                          <Fragment>
                            <Link to={{pathname:"/auth/register/subjects", state: {town: this.state.user_city, subjects: true}}}><Button color="primary" background="green" text="C'est parti!" type="round" className="mt-20"/></Link>
                            <Link to={{pathname:"/auth/register/location", state: {response: true}}}><Button color="white" background="transparent" text="Je préfère une autre ville" type="round" className="mt-20"/></Link>
                          </Fragment>
                         :
                         <Fragment>
                           <div>Votre commune n'est pas éligibile au dispositif MOI, MAIRE <br />Veuillez sélectionner une commune des Alpes Maritimes ou du Var</div>
                           <Link to={{pathname:"/auth/register/location", state: {response: true}}}><Button color="white" background="transparent" text="Je choisis une autre ville" type="round" className="mt-20"/></Link>
                         </Fragment>
                       }
                      </div>
                    </div>

                    <div className="hide-on-desktop full-width">
                      {this.state.show_next ?
                        <Fragment>
                          <Link to={{pathname:"/auth/register/subjects", state: {town: this.state.user_city, subjects: true}}}><Button color="primary" background="green" text="C'est parti!" type="round" className="mt-20"/></Link>
                          <Link to={{pathname:"/auth/register/location", state: {response: true}}}><Button color="white" background="transparent" text="Je préfère une autre ville" type="round" className="mt-20"/></Link>
                        </Fragment>
                       :
                       <Fragment>
                          <p>Votre commune n'est pas éligibile au dispositif MOI, MAIRE <br />Veuillez sélectionner une commune des Alpes Maritimes ou du Var</p>
                          <Link to={{pathname:"/auth/register/location", state: {response: true}}}><Button color="white" background="transparent" text="Je choisis une autre ville" type="round" className="mt-20"/></Link>
                       </Fragment>
                     }
                    </div>
                  </Fragment>
                ) : (
                    <div>Getting the location data </div>
                )}
      </div>
    </div>
    )
  }
}

const mapStateToProps = ({ town }) => {
	return { town };
};

export default withRouter(compose(geolocated({
positionOptions: {
    enableHighAccuracy: true,
},
userDecisionTimeout: 500,
}), connect(
	mapStateToProps,
  {
		getTownAsync
	}
))(Geolocalisation));
